import React, { useContext } from "react";
import { Section } from "./style";
import {
  scientificArticlesEng,
  scientificArticlesRus,
  scientificArticlesUz,
} from "../../context/scientificArticles";
import { Button } from "antd";
import { LanguageContext } from "../../context/languageContext";

const ScientificArticlesComp = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Section>
        <h1>Ilmiy maqolalar</h1>
        {scientificArticlesUz.map((value) => {
          return (
            <div className="card" key={value.id}>
              <div className="left">
                <img src={value.img} alt="" />
              </div>
              <div className="right">
                <p className="text">{value?.text}</p>
                <a href={value.link} target="_blank" rel="noreferrer">
                  <Button type="primary">Maqolani o'qish</Button>
                </a>
              </div>
            </div>
          );
        })}
      </Section>
    );
  } else if (lang === "rus") {
    return (
      <Section>
        <h1>Научные статьи</h1>
        {scientificArticlesRus.map((value) => {
          return (
            <div className="card" key={value.id}>
              <div className="left">
                <img src={value.img} alt="" />
              </div>
              <div className="right">
                <p className="text">{value?.text}</p>
                <a href={value.link} target="_blank" rel="noreferrer">
                  <Button type="primary">Прочитать статью</Button>
                </a>
              </div>
            </div>
          );
        })}
      </Section>
    );
  } else {
    return (
      <Section>
        <h1>Scientific articles</h1>
        {scientificArticlesEng.map((value) => {
          return (
            <div className="card" key={value.id}>
              <div className="left">
                <img src={value.img} alt="" />
              </div>
              <div className="right">
                <p className="text">{value?.text}</p>
                <a href={value.link} target="_blank" rel="noreferrer">
                  <Button type="primary">Read the article</Button>
                </a>
              </div>
            </div>
          );
        })}
      </Section>
    );
  }
};

export default ScientificArticlesComp;

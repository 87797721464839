import React from "react";
import { Container, Section } from "./style";
import logo from "../../assets/images/cropped-favicon.png";
import { LanguageContext } from "../../context/languageContext";
import news from "../../assets/icons/news.png";
import vaksina from "../../assets/icons/vaksina.png";
import konferensiya from "../../assets/icons/konferensiya.png";
import akselerator from "../../assets/icons/akselerator.png";
import laboratory from "../../assets/icons/laboratory.png";
import doctorate from "../../assets/icons/doctorate.png";
import maqola from "../../assets/icons/maqola.png";
import startup from "../../assets/icons/startup.png";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const [language] = React.useContext(LanguageContext);
  if (language === "uzb") {
    return (
      <Container>
        <Section>
          <div className="row1">
            <div className="box" onClick={() => navigate("news")}>
              <img src={news} alt="" /> Yangiliklar
            </div>
            <a
              href="https://cat-conference.uz/"
              target={"_blank"}
              className="box" rel="noreferrer"
            >
              <img src={konferensiya} alt="" /> Konferensiyalar
            </a>
          </div>

          <div className="row2">
            <div className="left">
              <div className="box" onClick={() => navigate("doctoranture")}>
                <img src={doctorate} alt="" /> Doktorantura
              </div>
              <div
                className="box"
                onClick={() => navigate("scientific-articles")}
              >
                <img src={maqola} alt="" /> Ilmiy maqolalar
              </div>
            </div>
            <div className="logo-wrapper">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="right">
              <a className="box" href="https://cat-sa.uz/" target={"_blank"} rel="noreferrer">
                <img src={startup} alt="" /> Akselerator
              </a>
              <div className="box" onClick={() => navigate("announcements")}>
                <img src={akselerator} alt="" /> Tenderlar
              </div>
            </div>
          </div>

          <div className="row3">
            <div className="box" onClick={() => navigate("vaccine")}>
              <img src={vaksina} alt="" /> Vaksina
            </div>
            <div className="box" onClick={() => navigate("laboratories")}>
              <img src={laboratory} alt="" /> Laboratoriyalar
            </div>
          </div>
        </Section>
      </Container>
    );
  } else if (language === "rus") {
    return (
      <Container>
        <Section>
          <div className="row1">
            <div className="box" onClick={() => navigate("news")}>
              <img src={news} alt="" /> Новости
            </div>
            <a
              href="https://cat-conference.uz/"
              target={"_blank"}
              className="box" rel="noreferrer"
            >
              <img src={konferensiya} alt="" /> Конференции
            </a>
          </div>

          <div className="row2">
            <div className="left">
              <div className="box" onClick={() => navigate("doctoranture")}>
                <img src={doctorate} alt="" /> Докторантура
              </div>
              <div
                className="box"
                onClick={() => navigate("scientific-articles")}
              >
                <img src={maqola} alt="" /> Научные статьи
              </div>
            </div>
            <div className="logo-wrapper">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="right">
              <a className="box" href="https://cat-sa.uz/" target={"_blank"} rel="noreferrer">
                <img src={startup} alt="" /> Акселератор
              </a>
              <div className="box" onClick={() => navigate("announcements")}>
                <img src={akselerator} alt="" /> Тендеры
              </div>
            </div>
          </div>

          <div className="row3">
            <div className="box" onClick={() => navigate("vaccine")}>
              <img src={vaksina} alt="" /> Вакцина
            </div>
            <div className="box" onClick={() => navigate("laboratories")}>
              <img src={laboratory} alt="" /> Лаборатории
            </div>
          </div>
        </Section>
      </Container>
    );
  } else if (language === "eng") {
    return (
      <Container>
        <Section>
          <div className="row1">
            <div className="box" onClick={() => navigate("news")}>
              <img src={news} alt="" /> News
            </div>
            <a
              href="https://cat-conference.uz/"
              target={"_blank"}
              className="box" rel="noreferrer"
            >
              <img src={konferensiya} alt="" /> Conferences
            </a>
          </div>

          <div className="row2">
            <div className="left">
              <div className="box" onClick={() => navigate("doctoranture")}>
                <img src={doctorate} alt="" /> Doctorate
              </div>
              <div
                className="box"
                onClick={() => navigate("scientific-articles")}
              >
                <img src={maqola} alt="" />
                Scientific articles
              </div>
            </div>
            <div className="logo-wrapper">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="right">
              <a className="box" href="https://cat-sa.uz/" target={"_blank"} rel="noreferrer">
                <img src={startup} alt="" /> Accelerator
              </a>
              <div className="box" onClick={() => navigate("announcements")}>
                <img src={akselerator} alt="" /> Tenders
              </div>
            </div>
          </div>

          <div className="row3">
            <div className="box" onClick={() => navigate("vaccine")}>
              <img src={vaksina} alt="" /> Vaccine
            </div>
            <div className="box" onClick={() => navigate("laboratories")}>
              <img src={laboratory} alt="" /> Laboratories
            </div>
          </div>
        </Section>
      </Container>
    );
  }
};

export default Hero;

import React from "react";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../../context/languageContext";
import {
  announcmentsEng,
  announcmentsRu,
  announcmentsUZ,
} from "../../data/announcments";
import { Wrapper } from "./style";

const AnnouncementComp = () => {
  const params = useParams();
  const infoUz = announcmentsUZ[params?.param];
  const infoRus = announcmentsRu[params?.param];
  const infoEng = announcmentsEng[params?.param];
  const [lang] = React.useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <div className="img-wrapper">
          <img src={infoUz.img} alt="" />
        </div>
        <p>{infoUz?.text1}</p>
        <p>{infoUz?.text2}</p>
        <p>{infoUz?.text3}</p>
        <p>{infoUz?.text4}</p>
        <p>{infoUz?.text5}</p>
        <p>{infoUz?.text6}</p>
        <p>{infoUz?.text7}</p>
        <p>{infoUz?.text8}</p>
        <p>{infoUz?.text9}</p>
        <p>{infoUz?.text10}</p>
        <p>{infoUz?.text12}</p>
        <p>{infoUz?.text13}</p>
        <p>{infoUz?.text14}</p>
        <p>{infoUz?.text15}</p>
        <p>{infoUz?.text16}</p>
        <p>{infoUz?.text17}</p>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <div className="img-wrapper">
          <img src={infoRus.img} alt="" />
        </div>
        <p>{infoRus?.text1}</p>
        <p>{infoRus?.text2}</p>
        <p>{infoRus?.text3}</p>
        <p>{infoRus?.text4}</p>
        <p>{infoRus?.text5}</p>
        <p>{infoRus?.text6}</p>
        <p>{infoRus?.text7}</p>
        <p>{infoRus?.text8}</p>
        <p>{infoRus?.text9}</p>
        <p>{infoRus?.text10}</p>
        <p>{infoRus?.text12}</p>
        <p>{infoRus?.text13}</p>
        <p>{infoRus?.text14}</p>
        <p>{infoRus?.text15}</p>
        <p>{infoRus?.text16}</p>
        <p>{infoRus?.text17}</p>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <div className="img-wrapper">
          <img src={infoEng.img} alt="" />
        </div>
        <p>{infoEng?.text1}</p>
        <p>{infoEng?.text2}</p>
        <p>{infoEng?.text3}</p>
        <p>{infoEng?.text4}</p>
        <p>{infoEng?.text5}</p>
        <p>{infoEng?.text6}</p>
        <p>{infoEng?.text7}</p>
        <p>{infoEng?.text8}</p>
        <p>{infoEng?.text9}</p>
        <p>{infoEng?.text10}</p>
        <p>{infoEng?.text12}</p>
        <p>{infoEng?.text13}</p>
        <p>{infoEng?.text14}</p>
        <p>{infoEng?.text15}</p>
        <p>{infoEng?.text16}</p>
        <p>{infoEng?.text17}</p>
      </Wrapper>
    );
  }
};

export default AnnouncementComp;

import styled from "styled-components";

export const Box = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  .antmenu {
    background-color: transparent !important;
    border: none !important;
    height: 40px !important;
    border-color: none !important ;
    padding: 0px !important;
    color: white !important;
  }
  p {
    line-height: 2.5;
    color: white !important;
  }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: var(--cardColor) !important;
    color: white !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-menu-sub.ant-menu-inline a {
    color: white !important;
  }
  .ant-menu-light .ant-menu-item-selected{
  }
  
`;

import img1 from "../assets/images/photogallery/1.jpeg";
import img2 from "../assets/images/photogallery/2.jpeg";
import img3 from "../assets/images/photogallery/3.jpeg";
import img4 from "../assets/images/photogallery/4.jpg";
import img5 from "../assets/images/photogallery/5.jpg";
import img6 from "../assets/images/photogallery/6.jpg";
import img8 from "../assets/images/photogallery/8.jpg";
import img9 from "../assets/images/photogallery/9.jpg";
import img10 from "../assets/images/photogallery/10.jpg";
import img11 from "../assets/images/photogallery/11.jpg";
import img12 from "../assets/images/photogallery/12.jpg";
import img13 from "../assets/images/photogallery/13.jpg";
import img14 from "../assets/images/photogallery/14.jpg";
import img1111 from "../assets/images/photogallery/1111.jpg"
export const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img8,
  img9,
  img10,
  img11,
  img12,
  img1111,
  img13,
  img14,
];

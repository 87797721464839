import styled from "styled-components";
import { SlSocialFacebook } from "react-icons/sl";
import { TbBrandTelegram } from "react-icons/tb";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";

export const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ home }) => (home ? "transparent" : `var(--bgColor)`)};
  border-top: 0.5px solid var(--hoverFontColor);
`;

export const Container = styled.div`
  width: 100%;
  max-width: var(--container);
  padding: ${({ home }) => (home ? "0" : "var(--padding)")};
  @media (max-width: 900px) {
    padding: 0 40px;
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  .icons-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      transition: all ease 0.2s;
      :hover {
        transform: scale(1.2);
      }
    }
  }
  .busses {
    font-size: 13px;
    margin-top: 0;
  }
  .iframe-wrapper {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    iframe {
      width: 100%;
      max-width: 1000px;
      height: 300px;
    }
  }
  .working-days-wrapper {
  }
  .working-days {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    .lunch {
      white-space: nowrap;
    }
  }
  p,
  a {
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 800px) {
    flex-direction: column;

    gap: 0;
    margin-bottom: 10px;
    P {
      margin-bottom: 5px;
    }
    h1 {
      font-size: 16px;
    }
    p,
    a {
      font-size: 14px;
    }
    .working-days-wrapper {
      margin-top: 20px;
    }
    .working-days {
      .lunch {
        white-space: normal;
      }
    }
    iframe {
      height: 150px !important;
    }
  }
`;

export const Section = styled.div`
  width: 100%;
  max-width: 700px;
  h1 {
    margin-top: 20px;
  }
  @media (max-width: 750px) {
    h1 {
      font-size: 16px;
    }
    p,
    a {
      font-size: 14px;
    }
  }
`;

export const Icon = styled.div``;

Icon.Facebook = styled(SlSocialFacebook)``;
Icon.Instagram = styled(AiOutlineInstagram)``;
Icon.Telegram = styled(TbBrandTelegram)``;
Icon.Youtube = styled(AiOutlineYoutube)``;

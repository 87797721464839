import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error404 from "../components/Error404/Error404";
import LanguageContextProvider from "../context/languageContext";
import Layout from "../layout/layout";
import { Home } from "../pages";
import { routes } from "../utils/routes";

const Root = () => {
  return (
    <LanguageContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<Layout />}>
            {routes.map((value) => (
              <Route path={value.path} element={value.element} key={value.id} />
            ))}
            <Route />
          </Route>
          <Route path="/*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </LanguageContextProvider>
  );
};

export default Root;

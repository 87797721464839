import React, { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";
import {
  raqamlashtirishEng,
  raqamlashtirishRus,
  raqamlashtirishUzb,
} from "../../data/raqamlashtirish";
import CardForLab from "../LaboratoriesComp/CardForLab/CardForLab";
import { Wrapper } from "./style";

const Raqamlashtirish = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <h1>Interaktiv xizmatlar</h1>
        <div className="cards-wrapper">
          {raqamlashtirishUzb?.map((value) => {
            return (
              <CardForLab
                title={value?.title}
                path={value?.path}
                key={value?.id}
                img={value?.img}
                type="raqamlashtirish"
              />
            );
          })}
        </div>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <h1>Интерактивные услуги</h1>
        <div className="cards-wrapper">
          {raqamlashtirishRus?.map((value) => {
            return (
              <CardForLab
                title={value?.title}
                path={value?.path}
                img={value?.img}
                type="raqamlashtirish"
                key={value?.id}
              />
            );
          })}
        </div>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <h1>Interactive services</h1>
        <div className="cards-wrapper">
          {raqamlashtirishEng?.map((value) => {
            return (
              <CardForLab
                title={value?.title}
                path={value?.path}
                type="raqamlashtirish"
                img={value?.img}
                key={value?.id}
              />
            );
          })}
        </div>
      </Wrapper>
    );
  }
};

export default Raqamlashtirish;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Wrapper } from "./style";

const AnnouncementCard = (props) => {
  const { title, img } = props.value;
  const [width] = React.useState(window.innerWidth);
  const { path, index } = props;
  const navigate = useNavigate();
  let titleText =
    width < 801
      ? title.split(" ").slice(0, 6).join(" ")
      : title.split(" ").slice(0, 12).join(" ");
  const handleClick = () => {
    window.scrollTo(0, 0);
    navigate(`/${path}/${index}`);
  };

  return (
    <Wrapper>
      <Card onClick={handleClick}>
        <img src={img} alt="" />
        <div className="blur"></div>
        <div className="content">
          <p>{titleText}...</p>
        </div>
      </Card>
    </Wrapper>
  );
};

export default AnnouncementCard;

import React, { useContext } from "react";
import Employee from "../components/Employee/Employee";
import { LanguageContext } from "../context/languageContext";
import { departmentsEng, departmentsRus, departmentsUzb } from "../data/departments";

const Departments = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb")
    return <Employee data={departmentsUzb} title="Bo'limlar" />;
  else if (lang === "rus")
    return <Employee data={departmentsRus} title="Отделы" />;
  else if (lang === "eng")
    return <Employee data={departmentsEng} title="Departments" />;
};

export default Departments;

import img1 from "../assets/images/scientificdevelopments/1.png";
import img2 from "../assets/images/scientificdevelopments/2.png";
import img3 from "../assets/images/scientificdevelopments/3.png";
import img4 from "../assets/images/scientificdevelopments/4.png";
import img5 from "../assets/images/scientificdevelopments/5.png";
import img6 from "../assets/images/scientificdevelopments/6.png";
import img7 from "../assets/images/scientificdevelopments/7.png";
import img8 from "../assets/images/scientificdevelopments/8.png";
import img9 from "../assets/images/scientificdevelopments/9.png";
import img10 from "../assets/images/scientificdevelopments/10.png";
import img11 from "../assets/images/scientificdevelopments/11.png";
import img12 from "../assets/images/scientificdevelopments/12.png";
import img13 from "../assets/images/scientificdevelopments/13.png";
import img14 from "../assets/images/scientificdevelopments/14.png";
import img15 from "../assets/images/scientificdevelopments/15.png";
import img16 from "../assets/images/scientificdevelopments/16.png";
import img17 from "../assets/images/scientificdevelopments/17.png";
import img20 from "../assets/images/scientificdevelopments/18.png";
import img21 from "../assets/images/scientificdevelopments/19.png";
import img22 from "../assets/images/scientificdevelopments/20.png";
import img23 from "../assets/images/scientificdevelopments/21.png";
import img24 from "../assets/images/scientificdevelopments/22.png";
import img25 from "../assets/images/scientificdevelopments/23.png";
import img18 from "../assets/images/scientificdevelopments/1.jpg";
import img19 from "../assets/images/scientificdevelopments/2.jpg";

export const scientificDevelopments = [
  {
    id: 1,
    title: "Beat ECG",
    text1:
      "Нами предложена разработка компактного аппарата электрокардиографии (ЭКГ) для диагностических и научных целей. Суть разработки заключается в доступной конструкции, простого дизайна и понятного интерфейса миниатюрного аппарата ЭКГ.",
    text2: `Доступность и компактность аппарата позволит проводить широкий скрининг нарушений сердечного ритма среди населения. Аппарат также может быть использован для наблюдения за функциональным состоянием сердца у профессиональных спортсменов во время физических нагрузок. Аппарат будет включать в себя алгоритм для считывания, визуализации и предварительного анализа ЭКГ, что не будет требовать специального обучения медицинских работников средней квалификации и непосредственно самих пациентов. Возможность цифровой записи и автоматизированной обработки данных позволит безотлагательно обмениваться результатами анализа с лечащим врачом.  Кроме того, внедрение аппарата в научную практику позволит осуществлять исследования изменений биопотенциалов сердца у лабораторных животных при разработке различных моделей патологий и методов лечения.`,
    images: [img1, img2],
  },
  {
    id: 2,
    title: "NovaPepDesign",
    text1:
      "Разработана программа, представляющая генеративную нейронную сеть, способную создавать последовательность и структуру новых высоко-аффинных пептидов к интересующим клеточным белковым рецепторам. В качестве первичного теста, при помощи данной программы были созданы модуляторы глутаматных рецепторов головного мозга. Эффективность созданных пептидов была доказана при помощи методов in silico, а также методов in vitro – на изолированных клетках головного мозга крыс.",
    text2: "",
    images: [img3, img4],
  },
  {
    id: 3,
    title: "NovaDerm",
    text1:
      "Тканеинженерная конструкция на основе жизнеспособных дермальных фибробластов в биосовместимом носителе. Эффективность ранозаживления NovaDerm протестирована на экспериментальных моделях глубоких ран in vivo. ",
    text2:
      "Экспериментальное клиническое исследование эффективности применения NovaDerm, содержащей в составе аутологичные (собственные) фибробласты кожи пациента в комплексной терапии некроза верхнего неба подтвердило высокую стимулирующую активность регенеративного потенциала собственных тканей пациента при аппликации разработанной тканеинженерной конструкции. ",
    images: [img5, img6, img7],
  },
  {
    id: 4,
    title: "Банк клеток",
    text1:
      "Научно-технологическая платформа, предоставляет услуги по выделению, наращиванию и криогенному хранению первичных культур паспортизированных соматических и стволовых клеток человека и животных для биомедицинских и научных исследований, а также специализированные услуги по персонифицированной заготовке стволовых клеток пуповинной крови и пупочного канатика.",
    text2: "",
    images: [img8, img9],
  },
  {
    id: 5,
    title:
      "Система установки активных элементов (катализаторов, сорбентов, ингибиторов, а также наноструктурных материалов), взаимодействующих с электромагнитными полями",
    text1:
      "Принцип работы: Макет концептуального дизайна используется в управлении каталитического процесса, протекающий при воздействии электромагнитного излучения. Легированный нанокатализатор наносится на нитевую подложку и закрепляется на держатель, установленный в металлическую трубку. Источник, генерируемое электромагнитное поле мобильно передвигается по поверхности трубы и направленно воздействует на отдельные участки. Поток жидкости управляется направляющими кассетами.",
    text2:
      "Область применение: нефтегазовая, химическая, металлургическая и пищевая промышленности и медицине.  ",
    images: [img10, img11],
  },
  {
    id: 6,
    title: `Recombinant protein vaccine against COVID-19 infection RENOVAC:Protein
`,
    text1: `Renovac:Protein vaccine is developed by The Center for Advanced Technologies under the Ministry of Innovative Development of Uzbekistan.
     Renovac:Protein targets receptor-binding domain(RBD) of S protein in SARS-CoV-2 virus
and induces neutralizing antibody production against this antigen when administrated to an organism. Consequently, the binding of SARS-CoV-2 to host receptor ACE2 is blocked leading to the prevention of the infection.  
`,
    text2: `Increased protection–the vaccine contains dimer RBD which is way more superior than the monomer form to induce immune response.  
    High safety profile – the vaccine was developed based on the contemporary biotechnology techniques, thus avoiding involvement of the pathogen or the virus. 
Effective immune response – since the vaccine contains highly immunogenic dimer RBD and specialized adjuvant it can effectively stimulate both types of immune system namely hormonal and cellular immune responses.   

    `,
    images: [img12, img13, img14],
  },
  {
    id: 7,
    title:
      "Модель установки фотокаталитического разложения воды при воздействии солнечного излучения",
    text1:
      "Принцип работы: Макет концептуального дизайна системы объединенных  панелей, состоящая из фотокаталитических элементов на основе наноструктур легированных оксидов металлов. Особенность данной системы состоит в том про процесс фотокаталитического расщепления воды на кислород и водород происходим при падающем солнечном излучении без применения электрического потенциала. ",
    text2:
      "Область применение: водородная энергетика, экология, преобразование энергии солнечного излучения,  очистка технической воды.",
    images: [img15, img16, img17],
  },
  {
    id: 8,
    title:
      "Наборы реагентов для определения SNP полиморфизмов сердечно-сосудистого риска ",
    text1: `AGT 521С>T
AGT ген находится в регионе 1q42.2 хромосомы 1 у человека. Ген AGT кодирует белок ангиотензиноген, который является частью системы, регулирующей артериальное давление и баланс жидкости в организме. Ген AGT экспрессируется в основном в печени, но также и в других тканях, таких как жировая ткань, почки, мозг, сердце и плацента. Ген AGT регулируется различными факторами, такими как гормоны, цитокины, потребление соли с пищей и окислительный стресс. AGT 521 C>T (Thr174Met) rs4762 находятся в промоторной области данного гена в неравновесном тесном сцеплении и влияют на работу всего гена. Во многих исследованиях сообщалось, что данные маркеры связаны с эссенциальной гипертензией
; -ИНСТРУКЦИЯ
по применению
Набор реагентов для определения полиморфизма AGT 521 С>Т гена AGT (rs4762)
Набор рассчитан на проведение 100 реакций объемом 25 мкл (20 мкл+5 мкл исследуемого образца)
; -Набор реагентов рассчитан на проведение 100 исследований, включая положительные и отрицательные контрольные образцы.
Хранение при температуре -200С.; -Список необходимого оборудования и расходных материалов
Прибор для ПЦР-РВ.
Микроцентрифуга-встряхиватель.
Микропипетки переменного объема на 10 и 100 мкл.
Пробирки на 1,5 мл.
Стрипы или плашки для ПЦР на 0,2 мл.
Наконечники с фильтром к микропипеткам.
;-Метод:
Подготовка к проведению реакции
Реактивы для амплификации ДНК поставляются в виде компонентов
(2,5х реакционная смесь 1, 2,5х реакционная смесь 2, ТаqДНК-полимераза), которые необходимо смешать в нужном объеме непосредственно перед
проведением исследования.
;-1. Рассчитать необходимый объем компонентов, исходя из количества исследуемых образцов плюс 4 (три положительных контрольных и один отрицательный контрольный образец).;-2. Разморозить компоненты, перемешать и центрифугировать.
3. Приготовить смесь компонентов, добавляя их в порядке, указанном
в таблице, перемешать и центрифугировать.
4. Внести в ПЦР стрип/плашку по 20 мкл смеси компонентов.
5. Внести последовательно по 5 мкл: отрицательный контрольный образец, положительные контрольные образцы и исследуемые образцы в соответствии с протоколом исследования, плотно закрыть крышку.
6. Кратковременно центрифугировать.
7. Поместить стрип/плашку в прибор в соответствии с протоколом исследования.
`,
    text2: `
Анализ результатов исследования
Открыть обрабатываемый файл. Для этого выбрать File-›Ореn, выбрать
нужный файл с расширением .eds
Анализ по пороговому циклу:
Выбрать вкладку Amplification Plot. Выбрать Plot Type: Рог Туре: ΔRn vs Cycle, Graph Type: Liner. Включить канал ЕАМ, выделить положительные
контроли и скорректировать положение пороговой линии, как показано на рисунке, повторить те же действия для канала JОЕ.
Затем выделить оба канала сразу и выделить все образцы. Результаты расчета пороговых циклов для каждого образца отражены в таблице — Well Table.

Результат реакции для ПКО1 должен быть положительным по каналу FАМ и
отрицательным по каналу JОЕ.
Результат реакции для ПКО2 должен быть положительным по каналу FАМ и
положительным по каналу JОЕ.
Результат реакции для ПКО3 должен быть положительным по каналу JОЕ и
отрицательным по каналу FАМ.
Результат реакции для ОКО должен быть отрицательным по всем каналам.
В случае получения положительных результатов для ОКО по любому из каналов результаты исследования — считаются недействительными. Требуется предпринять меры по выявлению и ликвидации источника контаминации и провести повторный анализ.
`,
    images: [],
  },
  {
    id: 9,
    title: "КОМПЛЕКСНАЯ ДОБАВКА К ЦЕМЕНТУ",
    text1: `Ускоряет измельчённость цементного клинкера;
u Повышает активность цемента на 2-3 порядка, увеличивает прочностные характеристики цемента на 25-30%;
u Увеличивает сохраняемость свойств цемента до 1 года; 
u Уменьшает водопотребность цементной композиции на 35%;
uУскоряется набор прочности бетона на основе цемента, модифицированного данной добавкой, достигается уровень прочности на 2-3 класса выше прочности бетона на основе цемента нормальной активности;
u Физико-химические свойства  модифицированного цемента полностью удовлетворяет требованиям ГОСТ 31108-2020.
`,
    text2: `Способ использования:
Комплексная добавка в количестве 10% от общего состава цемента измельчается вместе с клинкером и гипсом. 
При этом улучшение качественных показателей цемента объясняется образованием на цементных зёрнах органо-минеральных нано-оболочек с одновременной аморфизацией поверхностей зёрен цемента. 
Кроме этого комплексная добавка способствует размолоспособности твёрдых минеральных компонентов, входящих в состав цемента.
Также может способствовать повышению активности портландцемента при дополнительном измельчении его вместе с комплексной добавкой в шаровой мельнице.
`,
    images: [img18, img19],
  },
  {
    id: 10,
    title: "Taq and Vent DNA polymerases for PCR",
    text1:
      "Taq DNA Polymerase is a highly thermostable DNA polymerase from the thermophilic bacterium Thermus aquaticus. The enzyme catalyzes 5'→3' synthesis of DNA, has no detectable proofreading activity and possesses low 5'→3' exonuclease activity.; -Amplification of apple genomic DNA with Taq DNA Polymerase. M – 50 bp Marker, 1 – SD09 (174 bp); 2 – SS11 (210 and 174 bp); 3 – negative control.; -Ideal – for routine PCR amplification and RT PCR; -Thermostable—half life is >40 min at 95°C.; -Vent DNA Polymerase is a high-fidelity thermophilic DNA polymerase. The fidelity of Vent DNA Polymerase is 5-15-fold higher than that observed for Taq DNA Polymerase. Greater than 90% of the polymerase activity remains following a 1 hour incubation at 95°C; -Amplification of apple genomic DNA with Vent DNA Polymerase. M – 100 bp Marker, 1&2 – commercial Taq polymerase; 3 – negative control; 4&5 – Vent polymerase.; -Proofreading – 5X higher fidelity than Taq; -Ideal – for  GC-rich or looped sequences.",
    text2: "",
    images: [img20, img21],
  },
  {
    id: 11,
    title: "Tebranyzer – 1.0",
    text1:
      "Аппаратно-программный комплекс на основе одноплатного компьютера Raspberry PI 4B, регистрирующий сейсмическую волну с помощью акселерометра или геофонов для  сейсмического мониторинга и оценки дефицита сейсмостойкости зданий и сооружений (разрабатывается).",
    text2: "",
    images: [img22],
  },
  {
    id: 12,
    title: "PulseLab",
    text1:
      "PulseLab это платформа, представляющая набор специальных обучающих программ для изучения основ геофизики (сейсморазведка, электроразведка, гравиразведка, магниторазведка и сейсмология). В образовательных программах имеется возможность визуально исследовать происходящие физические процессы в Земле. ",
    text2: "",
    images: [img23],
  },
  {
    id: 13,
    title: "Sweet-Pro: Zero calorie sweet protein",
    text1:
      "Brazzein protein is an extremely sweet protein (2K times than sucrose) extracted from the African plant P.brazzeana. Due to the rarity of the plant and its small amount of brazzein, it is not possible to extract brazzein from the plant on a large scale.",
    text2: `Ideal – for diabetic people and who cares on their diet;
Heathy—alternative to table sugar;
Excellent – taste and no effect on glucose homeostasis;
No –  tooth decay and ultra low energy. 
`,
    images: [img24, img25],
  },
  {
    id: 14,
    title:
      "Наборы реагентов для определения SNP полиморфизмов 1236C> Т и 3435С> T гена MDR1  ",
    text1: `Полиморфизм 1236C> Т гена MDR1 - это SNP в последовательности ДНК, которая влияет на функцию транспортного белка P-гликопротеина, кодируемого этим геном. P-гликопротеин участвует в выведении многих лекарственных средств из клеток и тканей, в том числе из мозга, печени, почек и кишечника. Полиморфизм С1236Т гена MDR1 может изменять активность P-гликопротеина и тем самым влиять на фармакокинетику и фармакодинамику различных препаратов, таких как циклоспорин А, такролимус, дигоксин, колхицин, доксорубицин и другие. Полиморфизм С1236Т гена MDR1 также может ассоциироваться с развитием нежелательных лекарственных реакций, лекарственной устойчивости и эффективностью терапии при различных заболеваниях. 
ИНСТРУКЦИЯ: по применению
Набор реагентов для определения полиморфизма 1236C>T (rs1128503) гена MDR1;
Набор рассчитан на проведение 100 реакций объемом 15 мкл (10 мкл+5 мкл исследуемого образца);
Набор реагентов рассчитан на проведение 100 исследований, включая положительные и отрицательные контрольные образцы.
Хранение при температуре -200С.;
Список необходимого оборудования и расходных материалов
Прибор для ПЦР.
Микроцентрифуга-встряхиватель.
Микропипетки переменного объема на 10 и 100 мкл.
Пробирки на 1,5 мл.
Стрипы или плашки для ПЦР на 0,2 мл.
Наконечники с фильтром к микропипеткам.;
Метод:
Подготовка к проведению реакции
Реактивы для амплификации ДНК поставляются в виде компонентов (Реакционная смесь, ТаqДНК-полимераза), которые необходимо смешать в нужном объеме непосредственно перед проведением исследования.;
1. Рассчитать необходимый объем компонентов, исходя из количества исследуемых образцов плюс 4 (три положительных контрольных и один отрицательный контрольный образец).;
2. Разморозить компоненты, перемешать и центрифугировать.
3. Приготовить смесь компонентов, добавляя их в порядке, указанном в таблице, перемешать и центрифугировать.
4. Внести в ПЦР стрип/плашку по 10 мкл смеси компонентов.
5. Внести последовательно по 5 мкл: отрицательный контрольный образец, положительные контрольные образцы и исследуемые образцы в соответствии с протоколом исследования, плотно закрыть крышку.
6. Кратковременно центрифугировать.
7. Поместить стрип/плашку в прибор в соответствии с протоколом исследования.;
Рекомендуемая программа амплификации:
Рабочий объем реакции 15 мкл.
ПЦР-продукты положительных контрольных образцов далее используются для ПДРФ (полиморфизм длин рестрикционных фрагментов).
Анализ результатов исследования
Детекция продуктов амплификации проводиться с помощью 2% агарозного гель-электрофореза. Для определения длин фрагментов сравнивать их со стандартным маркером молекулярного веса, имеющим размерный интервал 100 п.н. 
Хранение ПЦР-продуктов при температуре +4 оС.

Результат реакции для всех трех ПКО должен быть положительным и иметь фрагмент длиной 548 н.п. 
Результат реакции для ОКО должен быть отрицательным.
ПЦР-продукты положительных контрольных образцов далее используются для ПДРФ (полиморфизм длин рестрикционных фрагментов) с помощью фермента BsuRI.;
Интерпретация результатов ПКО после ПДРФ:
В случае получения положительных результатов для ОКО результаты исследования — считаются недействительными. Требуется предпринять меры по выявлению и ликвидации источника контаминации и провести повторный анализ.;
Полиморфизм 3435C> Т гена MDR1
Полиморфизм С3435Т гена MDR1 - это SNP в последовательности ДНК, которая влияет на функцию транспортного белка P-гликопротеина, кодируемого этим геном. P-гликопротеин участвует в выведении многих лекарственных средств из клеток и тканей, в том числе из мозга, печени, почек и кишечника. Полиморфизм С3435Т гена MDR1 может изменять активность P-гликопротеина и тем самым влиять на фармакокинетику и фармакодинамику различных препаратов. Генотипы MDR1 3435 С/T и С/С - ассоциированы с высоким и средним уровнем экспрессии P-gp на апикальной мембране энтероцитов кишечника. Генотип MDR1 3435 T/T связан с низким уровнем экспрессии P-gp, что приводит к более высокому уровню абсорбции и медленному выведению лекарственного вещества в системную циркуляцию по сравнению с генотипами С/T и С/С.;
ИНСТРУКЦИЯ
по применению
Набор реагентов для определения полиморфизма 3435C> T (rs1045642) гена MDR1

Набор рассчитан на проведение 100 реакций объемом 15 мкл (10 мкл+5 мкл исследуемого образца)

Набор реагентов рассчитан на проведение 100 исследований, включая положительные и отрицательные контрольные образцы.
Хранение при температуре -200С.

Список необходимого оборудования и расходных материалов
Прибор для ПЦР.
Микроцентрифуга-встряхиватель.
Микропипетки переменного объема на 10 и 100 мкл.
Пробирки на 1,5 мл.
Стрипы или плашки для ПЦР на 0,2 мл.
Наконечники с фильтром к микропипеткам.

Метод:
Подготовка к проведению реакции
Реактивы для амплификации ДНК поставляются в виде компонентов (Реакционная смесь, ТаqДНК-полимераза), которые необходимо смешать в нужном объеме непосредственно перед проведением исследования.

1. Рассчитать необходимый объем компонентов, исходя из количества исследуемых образцов плюс 4 (три положительных контрольных и один отрицательный контрольный образец).
2. Разморозить компоненты, перемешать и центрифугировать.
3. Приготовить смесь компонентов, добавляя их в порядке, указанном в таблице, перемешать и центрифугировать.
4. Внести в ПЦР стрип/плашку по 10 мкл смеси компонентов.
5. Внести последовательно по 5 мкл: отрицательный контрольный образец, положительные контрольные образцы и исследуемые образцы в соответствии с протоколом исследования, плотно закрыть крышку.
6. Кратковременно центрифугировать.
7. Поместить стрип/плашку в прибор в соответствии с протоколом исследования.

Рекомендуемая программа амплификации:

Рабочий объем реакции 15 мкл.
ПЦР-продукты положительных контрольных образцов далее используются для ПДРФ (полиморфизм длин рестрикционных фрагментов).
Анализ результатов исследования
Детекция продуктов амплификации проводиться с помощью 2% агарозного гель-электрофореза. Для определения длин фрагментов сравнивать их со стандартным маркером молекулярного веса, имеющим размерный интервал 100 п.н. 
Хранение ПЦР-продуктов при температуре +4 оС.

Результат реакции для всех трех ПКО должен быть положительным и иметь фрагмент длиной 558 н.п. 
Результат реакции для ОКО должен быть отрицательным.
ПЦР-продукты положительных контрольных образцов далее используются для ПДРФ (полиморфизм длин рестрикционных фрагментов) с помощью фермента BstMBI..
Интерпретация результатов ПКО после ПДРФ:
В случае получения положительных результатов для ОКО результаты исследования — считаются недействительными. Требуется предпринять меры по выявлению и ликвидации источника контаминации и провести повторный анализ.
Учитывая высокую частоту встречаемости полиморфизмов C1236T, C3435T гена MDR1, можно рекомендовать их в качестве молекулярно-генетических маркеров в фармакогенетическом тестировании населения Узбекистана.
Для исследований, не для диагностических целей
`,
    text2: "",
    images: [],
  },
];

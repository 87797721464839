import React from "react";
import { LanguageContext } from "../../context/languageContext";
import { Hero, Wrapper } from "./style";
import ms from "../../assets/images/makerspace.jpg";
const SamePages = ({
  data: {
    img,
    titleUzb,
    textUzb,
    mainUzb,
    titleRus,
    textRus,
    mainRus,
    titleEng,
    textEng,
    mainEng,
  },
}) => {
  const [lang] = React.useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <div className="header">
          <h1>{titleUzb}</h1>
        </div>
        <Hero>
          <div className="img-wrapper">
            {img ? (
              <img src={img} alt="center for advanced technologies" />
            ) : (
              <img src={ms} alt="center for advanced technologies" />
            )}
          </div>
          <p>{textUzb}</p>
          <p>{mainUzb}</p>
        </Hero>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <div className="header">
          <h1>{titleRus}</h1>
        </div>
        <Hero>
          <div className="img-wrapper">
            {img ? (
              <img src={img} alt="center for advanced technologies" />
            ) : (
              <img src={ms} alt="center for advanced technologies" />
            )}
          </div>
          <p>{textRus}</p>
          <p>{mainRus}</p>
        </Hero>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <div className="header">
          <h1>{titleEng}</h1>
        </div>
        <Hero>
          <div className="img-wrapper">
            {img ? (
              <img src={img} alt="center for advanced technologies" />
            ) : (
              <img src={ms} alt="center for advanced technologies" />
            )}
          </div>
          <p>{textEng}</p>
          <p>{mainEng}</p>
        </Hero>
      </Wrapper>
    );
  }
};

export default SamePages;

import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled.div`
  width: fit-content;
  margin-left: auto;
`;

export const DrawerAnt = styled(Drawer)`
  svg,
  .ant-drawer-title {
    color: white !important;
  }
  .ant-drawer-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 0;
  }
  .ant-drawer-title,
  .header {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
`;

import React from "react";
import { LanguageContext } from "../../context/languageContext";
import {
  announcmentsEng,
  announcmentsRu,
  announcmentsUZ,
} from "../../data/announcments";
import AnnouncementCard from "./AnnouncementCard/AnnouncementCard";
import { Wrapper } from "./style";

const AnnouncementsComp = () => {
  const [lang] = React.useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <h1 className="heading">E'lonlar</h1>
        <div className="content">
          {announcmentsUZ.map((value, index) => {
            return (
              <AnnouncementCard
                key={value?.id}
                value={value}
                index={index}
                path="announcements"
              />
            );
          })}
        </div>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <h1 className="heading">Объявления</h1>
        <div className="content">
          {announcmentsRu.map((value, index) => {
            return (
              <AnnouncementCard
                key={value?.id}
                value={value}
                index={index}
                path="announcements"
              />
            );
          })}
        </div>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <h1 className="heading">Announcements</h1>
        <div className="content">
          {announcmentsEng.map((value, index) => {
            return (
              <AnnouncementCard
                key={value?.id}
                value={value}
                index={index}
                path="announcements"
              />
            );
          })}
        </div>
      </Wrapper>
    );
  }
};

export default AnnouncementsComp;

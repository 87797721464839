import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: var(--cardColor);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  gap: 20px;
  img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
  }
  .content {
  .ant-modal-body p {
      color: red !important;
    }
    width: 100%;
    max-width: 600px;
    padding-top: 10px;
    .name {
      color: var(--hoverFontColor);
    }
    .position {
      margin-top: 10px;
    }
    p {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  @media (max-width: 600px) {
    img {
      width: 100%;
    }
    flex-direction: column;
  }
  @media (max-width: 750px) {
    p {
      font-size: 14px;
    }
    h1,
    h2,
    h3 {
      font-size: 16px;
    }
  }
`;

import { Button, Empty } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/languageContext";

const EmtpyPage = () => {
  const navigate = useNavigate();
  const [lang] = React.useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          height: "86vh",
        }}
      >
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>Sahifa hozircha bo'sh</span>}
        />
        <Button
          style={{ color: "red !important" }}
          type="primary"
          onClick={() => navigate("/")}
        >
          Bosh sahifa
        </Button>
      </div>
    );
  } else if (lang === "rus") {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          height: "86vh",
        }}
      >
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>В настоящее время страница пуста</span>}
        />
        <Button
          style={{ color: "red !important" }}
          type="primary"
          onClick={() => navigate("/")}
        >
          Домашняя страница
        </Button>
      </div>
    );
  } else if (lang === "eng") {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          height: "86vh",
        }}
      >
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>The page is currently empty</span>}
        />
        <Button
          style={{ color: "red !important" }}
          type="primary"
          onClick={() => navigate("/")}
        >
          Home page
        </Button>
      </div>
    );
  }
};

export default EmtpyPage;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  table {
    width: 100%;
    background-color: var(--cardColor);
    border-collapse: collapse;
    border-color: var(--hoverFontColor);
    td,
    th {
      padding: 10px;
    }
  }
  @media (max-width: 750px) {
    margin: 20px 0;
    td {
      font-size: 14px;
    }
    th,
    h1 {
      font-size: 16px;
    }
  }
`;

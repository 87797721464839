import biophysics from "../assets/images/laboratories/forPage/biophysics.jfif";
import phsicochemical from "../assets/images/laboratories/forPage/phsicochemical.jpg";
import fudomics from "../assets/images/laboratories/forPage/fudomics.jfif";
import cell from "../assets/images/laboratories/forPage/cell.jpg";
import geophysics from "../assets/images/laboratories/forPage/geophysics.jfif";
import biotechnology from "../assets/images/laboratories/forPage/biotechnology.jfif";
import bigdata from "../assets/images/laboratories/bigDAta/1.jpg";
export const LaboratoriesData = [
  {
    id: 30,
    title: "Laboratory of Cell Technology",
    img: cell,
    path: "Laboratory-of-the-Cell-Technology",
  },
  {
    id: 31,
    title: "Laboratoryof Geophysics and Nanomineralogy",
    img: geophysics,
    path: "Laboratory-of-Geopyhsics-and-Nanomineralogy",
  },
  {
    id: 32,
    title: "Laboratory of Experimental Biophysics",
    img: biophysics,
    path: "Laboratory-of-Experimental-Biophysics",
  },
  {
    id: 33,
    title: "Laboratory of Biotechnology",
    img: biotechnology,
    path: "Laboratory-of-Biotechnology",
  },
  {
    id: 34,
    title: "Laboratory of Physicochemical",
    img: phsicochemical,
    path: "Laboratory-of-Physicochemical",
  },
  {
    id: 35,
    title: "Laboratory of Fudomics",
    img: fudomics,
    path: "Laboratory-of-the-Fudomics",
  },
  {
    id: 36,
    title: "Laboratory of Big data",
    img: bigdata,
    path: "Laboratory-of-the-Big-data",
  },
];
export const LaboratoriesData2 = [
  {
    id: 30,
    title: "Xujayra texnologiyalari laboratoriyasi",
    path: "Laboratory-of-the-Cell-Technology",
    img: cell,
  },
  {
    id: 31,
    title: "Geofizika va nanomineralogiya laboratoriyasi",
    path: "Laboratory-of-Geopyhsics-and-Nanomineralogy",
    img: geophysics,
  },
  {
    id: 32,
    title: "Eksperimental biofizika laboratoriyasi",
    path: "Laboratory-of-Experimental-Biophysics",
    img: biophysics,
  },
  {
    id: 33,
    title: "Biotexnologiya laboratoriyasi",
    path: "Laboratory-of-Biotechnology",
    img: biotechnology,
  },
  {
    id: 34,
    title: "Fizik-kimyoviy tadqiqot laboratoriyasi",
    path: "Laboratory-of-Physicochemical",
    img: phsicochemical,
  },
  {
    id: 35,
    title: "Fudomika laboratoriyasi",
    path: "Laboratory-of-the-Fudomics",
    img: fudomics,
  },
  {
    id: 36,
    title: "Big data laboratoriyasi",
    img: bigdata,
    path: "Laboratory-of-the-Big-data",
  },
];
export const LaboratoriesData3 = [
  {
    id: 30,
    title: "Лаборатория клеточных технологий",
    path: "Laboratory-of-the-Cell-Technology",
    img: cell,
  },
  {
    id: 31,
    title: "Лаборатория геофизики и наноминералогии",
    path: "Laboratory-of-Geopyhsics-and-Nanomineralogy",
    img: geophysics,
  },
  {
    id: 32,
    title: "Лаборатория экпериментальной биофизики",
    path: "Laboratory-of-Experimental-Biophysics",
    img: biophysics,
  },
  {
    id: 33,
    title: "Лаборатория биотехнологии",
    path: "Laboratory-of-Biotechnology",
    img: biotechnology,
  },
  {
    id: 34,
    title: "Лаборатория физико-химических методов исследования",
    path: "Laboratory-of-Physicochemical",
    img: phsicochemical,
  },
  {
    id: 35,
    title: "Лаборатория фудомики",
    path: "Laboratory-of-the-Fudomics",
    img: fudomics,
  },
  {
    id: 36,
    title: "Лаборатория 'Big data'",
    img: bigdata,
    path: "Laboratory-of-the-Big-data",
  },
];

import React from "react";
import SamePages from "../components/SamePages/SamePages";

const GenderEquality = () => {
  return <SamePages data={data}/>;
};

const data = {
  img: "https://probonoaustralia.com.au/wp-content/uploads/2017/06/gender.jpg",
  titleUzb: "Gender tengligi",
  textUzb: `“Davlat xotin-qizlar va erkaklarga shaxsiy, siyosiy, iqtisodiy, ijtimoiy va madaniy huquqlarni amalga oshirish chog‘ida teng huquqlilikni kafolatlaydi.
Davlat xotin-qizlar va erkaklarga jamiyat hamda davlat ishlarini boshqarishda, saylov jarayonida teng ishtirok etishni, sog‘liqni saqlash, ta’lim, fan, madaniyat, mehnat va ijtimoiy himoya sohalarida, shuningdek davlat va jamiyat hayotining boshqa sohalarida teng huquq hamda imkoniyatlar ta’minlanishini kafolatlaydi.Xotin-qizlar va erkaklar o‘rtasida haqiqiy tenglikka erishish, jamiyat hayotining barcha sohalarida ularning ishtirokini kengaytirish, jins bo‘yicha bevosita va bilvosita kamsitishni bartaraf etish hamda ularning oldini olish maqsadida davlat tomonidan gender siyosati amalga oshirilishini ta’minlashga doir vaqtinchalik maxsus choralar ko‘riladi.
2019-yil 2-sentabrda qabul qilingan  «Xotin-qizlar va erkaklar uchun teng huquq hamda imkoniyatlar kafolatlari to’g’risida» O’zbekiston Respublikasining qonuni xotin-qizlarning jamiyatdagi o’rniga qaratilgan huquqiy himoya, huquqiy kafolat sifatida ma’qullandi.

Qonunga muvofiq davlat organlarida xotin-qizlar va erkaklar uchun teng huquq hamda imkoniyatlarni ta’minlash masalalari bo’yicha Maslahat kengashlarini tuzish belgilangan.

Xotin-qizlarning jamiyatdagi rolini oshirish, gender tenglik va oila masalalari bo’yicha Respublika komissiyasining yig’ilish bayonida belgilangan vazifalar ijrosini ta’minlash maqsadida davlat organlarida tashkil etilgan Maslahat kengashlari faoliyatini o’rganib chiqish hamda ularning faoliyat samaradorligini oshirish bo’yicha zarur choralarni belgilash maqsadida Ishchi guruhlar shakllantirilmoqda.
`,
  mainUzb: `Oʻzbekiston gender tengligi koʻrsatkichi roʻyxatida 2019-yildan boshlab qatnashishni boshladi. 2019-yil holati boʻyicha Oʻzbekistonning gender tengligi koʻrsatkichi roʻyxatdagi 189 mamlakat ichida 62-oʻrinni egalladi. Birlashgan Millatlar Tashkilotining Aholishunoslik jamgʻarmasi (UNFPA) ekspertlarining fikriga koʻra, Oʻzbekistondagi har 100 000 dan 29 ayol gender tengsizlik tufayli vafot etishi va 15-19 yoshdagi har ming oʻspirin qiz uchun tugʻish koʻrsatkichi 23,8 ni tashkil qiladi.

Gender tengligi koʻrsatkichi roʻyxati boʻyicha 62-oʻrinda Oʻzbekiston bilan bir qatorda Kosta-Rika va Urugvay ham 0,288 koʻrsatkichi bilan qayd etilgan. Oʻrta Osiyo davlatlari oʻrtasida Qozogʻiston 44-chi, Qirgʻiziston 82-chi, Tojikiston 70-chi oʻrinlarni egallashgan, Turkmanistonda esa bu roʻyxatda hech qanday maʼlumot koʻrsatilmagan.

Oʻzbekistonda 2019-yil 2-sentabrda 562-sonli Oʻzbekiston Respublikasi „Xotin-qizlar va erkaklar uchun teng huquq hamda imkoniyatlar kafolatlari toʻgʻrisida“gi Qonuni qabul qilingan. Oʻzbekistonda Gender tenglik boʻyicha Komissiya 2019-yildan boshlab ish yuritib keladi. Oliy Majlis Senati Raisi Tanzila Narbaeva Oʻzbekiston Respublikasi Gender tenglikni taʼminlash masalalari boʻyicha komissiyasining raisi hisoblanadi.`,
  titleRus: "Гендерное равенство",
  textRus: `Государство гарантирует женщинам и мужчинам равные права в осуществлении личных, политических, экономических, социальных и культурных прав.
Государство обеспечивает женщинам и мужчинам равные права и возможности в управлении обществом и государственными делами, равное участие в избирательном процессе, равные права и возможности в сферах здравоохранения, образования, науки, культуры, труда и социальной защиты, а также а также в других сферах государственной и общественной жизни Временный специальный указ об обеспечении реализации государством гендерной политики в целях достижения реального равноправия женщин и мужчин, расширения их участия во всех сферах жизни общества, ликвидации прямой и косвенной дискриминации по признаку пола и предотвращать их, будут приняты меры.
В качестве правовой защиты и правовой гарантии, направленной на место женщин в обществе, утвержден Закон Республики Узбекистан «О гарантиях равных прав и возможностей женщин и мужчин», принятый 2 сентября 2019 года.
В соответствии с законом установлено создание Консультативных советов по вопросам равных прав и возможностей женщин и мужчин в государственных органах.
В целях повышения роли женщин и девушек в обществе обеспечить выполнение задач, указанных в протоколе заседания Республиканской комиссии по вопросам гендерного равенства и семьи, изучить деятельность Консультативных советов, созданных в государственных органах. и для определения необходимых мер по повышению их эффективности формируются рабочие группы.
Профсоюзный комитет Центра участвует в собраниях коллегиях, курултаях и др.мероприятиях проводимые объединённым профсоюзным комитетом Академии наук Республики Узбекистан.
`,
  mainRus: `Узбекистан начал участвовать в списке индикаторов гендерного равенства с 2019 года. По состоянию на 2019 год по показателю гендерного равенства Узбекистан занял 62 место среди 189 стран списка. По данным экспертов Фонда ООН в области народонаселения (ЮНФПА), 29 женщин из каждых 100 тысяч в Узбекистане умирают из-за гендерного неравенства, а коэффициент рождаемости на каждую тысячу девочек-подростков в возрасте 15-19 лет составляет 23,8.

Наряду с Узбекистаном Коста-Рика и Уругвай также находятся на 62-м месте в списке показателей гендерного равенства с показателем 0,288. Среди стран Центральной Азии Казахстан занимает 44-е место, Кыргызстан – 82-е, Таджикистан – 70-е, а Туркменистан в этот список не входит.

2 сентября 2019 года в Узбекистане принят Закон Республики Узбекистан № 562 «О гарантиях равных прав и возможностей женщин и мужчин». Комиссия по гендерному равенству действует в Узбекистане с 2019 года. Председатель Сената Олий Мажлиса Танзила Нарбаева является председателем Комиссии по обеспечению гендерного равенства Республики Узбекистан.`,
  titleEng: "Gender equality",
  textEng: `The state guarantees women and men equal rights in the exercise of personal, political, economic, social and cultural rights.
The state provides women and men with equal rights and opportunities in the management of society and state affairs, equal participation in the election process, equal rights and opportunities in the spheres of health, education, science, culture, labor and social protection, as well as in other spheres of state and community life. Temporary special decree on ensuring the implementation of gender policy by the state in order to achieve real equality between women and men, expand their participation in all spheres of society, eliminate direct and indirect discrimination based on gender and prevent them. measures will be taken.
The Law of the Republic of Uzbekistan “On guarantees of equal rights and opportunities for women and men” adopted on September 2, 2019 was approved as a legal protection and legal guarantee aimed at the place of women in society.
In accordance with the law, it is established to establish Advisory Councils on issues of equal rights and opportunities for women and men in state bodies.
In order to increase the role of women and girls in society, to ensure the performance of the tasks specified in the minutes of the meeting of the Republican Commission on Gender Equality and Family Issues, to study the activities of the Advisory Councils established in state bodies and to determine the necessary measures to increase their effectiveness Working groups are being formed.
`,
  mainEng: `Uzbekistan began participating in the list of gender equality indicators from 2019. According to the status of 2019, the gender equality indicator of Uzbekistan took the 62nd place among 189 countries on the list. According to experts of the United Nations Population Fund (UNFPA), 29 women out of every 100,000 in Uzbekistan die due to gender inequality, and the birth rate for every thousand adolescent girls aged 15-19 is 23.8.

Along with Uzbekistan, Costa Rica and Uruguay are also on the 62nd place on the list of gender equality indicators with an indicator of 0.288. Kazakhstan ranks 44th, Kyrgyzstan 82nd, and Tajikistan 70th among the Central Asian countries, and Turkmenistan is not included in this list.

On September 2, 2019, the Law of the Republic of Uzbekistan No. 562 “On guarantees of equal rights and opportunities for women and men” was adopted in Uzbekistan. The Gender Equality Commission has been operating in Uzbekistan since 2019. The Chairperson of the Senate of the Oliy Majlis Tanzila Narbaeva is the Chairperson of the Commission on Ensuring Gender Equality of the Republic of Uzbekistan.`,
};

export default GenderEquality;

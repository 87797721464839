import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: ${({ home }) => (home ? "#041c54" : "#1b3263")};
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const Container = styled.div`
  width: 100%;
  max-width: var(--container);
  padding: var(--padding);
  height: 14vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 900px) {
    padding: 0 40px;
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

export const Section = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .menu {
    width: 100%;
  }
  .drawer {
    width: 100%;
    display: none;
    margin-left: 20px;
  }
  img {
    width: 50px;
    height: 50px;
    transition: all ease 0.25s;
    :hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  @media (max-width: 800px) {
    .menu {
      display: none;
    }
    .drawer {
      display: block;
    }
    .logo {
      flex-direction: column;
      align-items: flex-start;
      img {
        display: none;
      }
    }
    .select {
      display: none;
    }
  }
`;

import React from "react";
import Navbar from "../Navbar/navbar";
import { Main, Container, Blur, Desktop, Mobile } from "./style";
import video from "../../assets/videos/6.mp4";
import Hero from "../Hero";
import Footer from "../Footer/footer";
import LaboratoriesComp from "../LaboratoriesComp/LaboratoriesComp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Mousewheel, Pagination } from "swiper";
import Raqamlashtirish from "../Raqamlashtirish/Raqamlashtirish";
import { News } from "../../pages";
import "swiper/css";
import "swiper/css/pagination";
import UsefulLinks from "../UsefulLinks/UsefulLinks";

const HomeComp = () => {
  return (
    <>
      <Desktop>
        <Navbar home />
        <Main>
          <div className="video">
            <video src={video} autoPlay loop muted></video>
          </div>
          <Blur />
          <Container>
            <Swiper
              autoHeight={true}
              direction={"vertical"}
              pagination={{
                clickable: true,
              }}
              speed={1000}
              modules={[Pagination, Mousewheel, Keyboard]}
              className="mySwiper"
              mousewheel={true}
              keyboard={{ enabled: true }}
            >
              <SwiperSlide>
                <Hero />
              </SwiperSlide>
              <SwiperSlide>
                <Container>
                  <LaboratoriesComp home />
                </Container>
              </SwiperSlide>
              <SwiperSlide className="news">
                <Container>
                  <News home />
                </Container>
              </SwiperSlide>
              <SwiperSlide>
                <Container>
                  <Raqamlashtirish />
                </Container>
              </SwiperSlide>
              <SwiperSlide>
                <Container>
                  <UsefulLinks />
                </Container>
              </SwiperSlide>
              <SwiperSlide>
                <Footer home />
              </SwiperSlide>
            </Swiper>
          </Container>
        </Main>
      </Desktop>
      <Mobile>
        <Navbar home />
        <Main>
          <div className="video">
            <video src={video} autoPlay loop muted></video>
          </div>
          <Blur />
          <Container>
            <Hero />
          </Container>
          <Container>
            <LaboratoriesComp />
          </Container>
          <Container>
            <News home />
          </Container>
          <Container>
            <Raqamlashtirish />
          </Container>
          <Container>
            <UsefulLinks />
          </Container>
          <Container>
            <Footer home />
          </Container>
        </Main>
      </Mobile>
    </>
  );
};

export default HomeComp;

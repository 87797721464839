import styled from "styled-components";

export const Section = styled.div`
  margin: 20px 0;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  p {
    color: blue;
  }
  div{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .card {
    width: 95%;
    max-width: 500px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 180px;
    background: var(--cardColor);
    border-radius: 10px;
    margin: 8px;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.1);
  }
  figure {
    width: 120px;
    height: 120px;
    background-color: var(--cardColor);
    border-radius: 50%;
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  figure::before {
    content: "";
    border-radius: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    border: 2px solid var(--hoverFontColor);
    box-shadow: 0 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  figure img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    transition: all ease 0.2s;
  }

  .circle {
    width: 50%;
    height: 50%;
    border-radius: inherit;
    object-fit: cover;
    transition: all ease 0.2s;
  }

  p {
    text-align: center;
    margin: 1.2rem;
    line-height: 1.5;
    color: white;
  }

  :hover {
    figure img {
      animation-name: anim;
      animation-duration: 0.5s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    p {
      color: var(--hoverFontColor);
    }
    cursor: pointer;
    .card {
      background-color: rgba(49, 75, 132, 0.6);
    }
  }
  @keyframes anim {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }
  @media (max-width: 750px) {
    .card {
      min-height: 120px;
    }
    figure {
      width: 60px;
      height: 60px;
      margin-top: -30px;
    }
    P {
      font-size: 14px;
      margin: 0;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  ul {
    margin-left: 20px;
    li {
      margin-bottom: 10px;
    }
    span {
      cursor: pointer;
      :hover {
        color: var(--hoverFontColor);
      }
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    p, span {
      font-size: 14px;
    }
    margin: 20px 0;
  }
`;

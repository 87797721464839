import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../context/languageContext";
import { Box } from "./style";

const MenuForNavbar = ({ mobile }) => {
  const [language] = React.useContext(LanguageContext);
  if (language === "uzb") {
    return (
      <Box>
        <Menu
          items={itemsUz}
          triggerSubMenuAction={mobile ? "click" : "hover"}
          className="antmenu"
          mode={mobile ? "inline" : "horizontal"}
          style={{ background: "transparent", width: "100%" }}
        />
      </Box>
    );
  } else if (language === "rus") {
    return (
      <Box>
        <Menu
          items={itemsRus}
          className="antmenu"
          triggerSubMenuAction={mobile ? "click" : "hover"}
          mode={mobile ? "inline" : "horizontal"}
          style={{ background: "transparent", width: "100%" }}
        />
      </Box>
    );
  } else {
    return (
      <Box>
        <Menu
          items={itemsEng}
          className="antmenu"
          triggerSubMenuAction={mobile ? "click" : "hover"}
          mode={mobile ? "inline" : "horizontal"}
          style={{
            background: "transparent",
            width: "100%",
          }}
        />
      </Box>
    );
  }
};

//navbar-menu items
const itemsUz = [
  {
    label: <p>Markaz</p>,
    key: "op1",
    children: [
      {
        label: (
          <Link to={"about"} style={{ color: "var(--bgColor)" }}>
            Markaz haqida
          </Link>
        ),
        key: "submenu1",
      },
      {
        label: (
          <Link to={"adminstration"} style={{ color: "var(--bgColor)" }}>
            Rahbariyat
          </Link>
        ),
        key: "submenu2",
      },
      {
        label: (
          <Link to={"scientific-council"} style={{ color: "var(--bgColor)" }}>
            Ilmiy kengash
          </Link>
        ),
        key: "submenu93",
      },
      {
        label: (
          <Link to={"scientific-secretary"} style={{ color: "var(--bgColor)" }}>
            Ilmiy kotib
          </Link>
        ),
        key: "submenu3",
      },
      {
        label: (
          <Link to={"departments"} style={{ color: "var(--bgColor)" }}>
            Bo'limlar
          </Link>
        ),
        key: "submenu4",
      },
      {
        label: (
          <Link to={"laboratories"} style={{ color: "var(--bgColor)" }}>
            Laboratoriyalar
          </Link>
        ),
        key: "submenu5",
      },
      {
        label: (
          <Link to={"maker-space"} style={{ color: "var(--bgColor)" }}>
            Markerspace ilmiy ustaxonasi
          </Link>
        ),
        key: "submenu7",
      },
      {
        label: (
          <Link to={"union-committee"} style={{ color: "var(--bgColor)" }}>
            Kasaba uyushmasi
          </Link>
        ),
        key: "submenu6",
      },
      {
        label: (
          <Link to={"center-structure"} style={{ color: "var(--bgColor)" }}>
            Markaz tuzilmasi
          </Link>
        ),
        key: "submenu8",
      },
      {
        label: (
          <Link to={"gender-equality"} style={{ color: "var(--bgColor)" }}>
            Gender tengligi
          </Link>
        ),
        key: "submenu1222",
      },
      {
        label: (
          <a
            href="https://mininnovation.uz/static/bosh-ish-orinlari"
            target="_blank"
            style={{ color: "var(--bgColor)" }} rel="noreferrer"
          >
            Bo'sh ish o'rinlari
          </a>
        ),
        key: "submenu9",
      },
    ],
  },
  {
    label: <p>Faoliyat</p>,
    key: "opt2",
    children: [
      {
        label: (
          <Link to={"state-programms"} style={{ color: "var(--bgColor)" }}>
            Davlat dasturlari
          </Link>
        ),
        key: "submenu123",
      },
      {
        label: (
          <Link
            to={"scientific-developments"}
            style={{ color: "var(--bgColor)" }}
          >
            Ilmiy ishlanmalar
          </Link>
        ),
        key: "submenu1233",
      },

      {
        label: (
          <Link to={"#"} style={{ color: "var(--bgColor)" }}>
            Xalqaro munosabat
          </Link>
        ),
        key: "submenu13",
        children: [
          {
            label: (
              <Link to={"cooperation"} style={{ color: "var(--bgColor)" }}>
                Hamkorlik
              </Link>
            ),
            key: "submenu113",
          },
        ],
      },
      {
        label: (
          <Link
            to={"fight-against-corruption"}
            style={{ color: "var(--bgColor)" }}
          >
            Korrupsiyaga qarshi kurashish
          </Link>
        ),
        key: "submenu14",
      },
    ],
  },
  {
    label: <p>Matbuot markazi</p>,
    key: "opt3",
    children: [
      {
        label: (
          <Link to={"news"} style={{ color: "var(--bgColor)" }}>
            Yangiliklar
          </Link>
        ),
        key: "submenu31",
      },
      {
        label: (
          <Link to={"photogallery"} style={{ color: "var(--bgColor)" }}>
            Fotogalereya
          </Link>
        ),
        key: "submenu32",
      },
      {
        label: (
          <Link to={"videogallery"} style={{ color: "var(--bgColor)" }}>
            Videogalereya
          </Link>
        ),
        key: "submenu33",
      },
      {
        label: (
          <Link to={"mass-media-about-us"} style={{ color: "var(--bgColor)" }}>
            OAV biz haqimizda
          </Link>
        ),
        key: "submenu34",
      },
    ],
  },
  {
    label: <Link to={"scientific-activities"}>Ilmiy faoliyat</Link>,
    key: "opt4",
  },
  {
    label: <Link to={"documents"}>Hujjatlar</Link>,
    key: "opt5",
  },
  {
    label: <Link to={"announcements"}>E'lonlar</Link>,
    key: "opt6",
  },
  {
    label: <Link to={"contact"}>Aloqa</Link>,
    key: "opt7",
  },
];

//////////////
const itemsRus = [
  {
    label: <p>Центр</p>,
    key: "op1",
    children: [
      {
        label: (
          <Link to={"about"} style={{ color: "var(--bgColor)" }}>
            О Центре
          </Link>
        ),
        key: "submenu1",
      },
      {
        label: (
          <Link to={"adminstration"} style={{ color: "var(--bgColor)" }}>
            Руководство
          </Link>
        ),
        key: "submenu2",
      },
      {
        label: (
          <Link to={"scientific-council"} style={{ color: "var(--bgColor)" }}>
            Ученый совет
          </Link>
        ),
        key: "submenu93",
      },
      {
        label: (
          <Link to={"scientific-secretary"} style={{ color: "var(--bgColor)" }}>
            Учёный секретарь
          </Link>
        ),
        key: "submenu3",
      },
      {
        label: (
          <Link to={"departments"} style={{ color: "var(--bgColor)" }}>
            Отделы
          </Link>
        ),
        key: "submenu4",
      },
      {
        label: (
          <Link to={"laboratories"} style={{ color: "var(--bgColor)" }}>
            Лаборатории
          </Link>
        ),
        key: "submenu5",
      },
      {
        label: (
          <Link to={"maker-space"} style={{ color: "var(--bgColor)" }}>
            Научная мастерская Мейкерспейс
          </Link>
        ),
        key: "submenu7",
      },
      {
        label: (
          <Link to={"union-committee"} style={{ color: "var(--bgColor)" }}>
            Профсоюзный комитет
          </Link>
        ),
        key: "submenu6",
      },
      {
        label: (
          <Link to={"center-structure"} style={{ color: "var(--bgColor)" }}>
            Структура центра
          </Link>
        ),
        key: "submenu8",
      },
      {
        label: (
          <Link to={"gender-equality"} style={{ color: "var(--bgColor)" }}>
            Гендерное равенство
          </Link>
        ),
        key: "submenu12121",
      },
      {
        label: (
          <a
            target="_blank"
            href="https://mininnovation.uz/ru/static/bosh-ish-orinlari"
            style={{ color: "var(--bgColor)" }} rel="noreferrer"
          >
            Вакансии
          </a>
        ),
        key: "submenu9",
      },
    ],
  },
  {
    label: <p>Деятельность</p>,
    key: "opt2",
    children: [
      {
        label: (
          <Link to={"state-programms"} style={{ color: "var(--bgColor)" }}>
            Государственные программы
          </Link>
        ),
        key: "submenu123",
      },
      {
        label: (
          <Link
            to={"scientific-developments"}
            style={{ color: "var(--bgColor)" }}
          >
            Научные разработки
          </Link>
        ),
        key: "submenu1233",
      },

      {
        label: (
          <Link to={"#"} style={{ color: "var(--bgColor)" }}>
            Международные отношения
          </Link>
        ),
        key: "submenu13",
        children: [
          {
            label: (
              <Link to={"cooperation"} style={{ color: "var(--bgColor)" }}>
                Сотрудничество
              </Link>
            ),
            key: "submenu113",
          },
        ],
      },
      {
        label: (
          <Link
            to={"fight-against-corruption"}
            style={{ color: "var(--bgColor)" }}
          >
            Борьба с коррупцией
          </Link>
        ),
        key: "submenu14",
      },
    ],
  },
  {
    label: <p>Пресс служба</p>,
    key: "opt3",
    children: [
      {
        label: (
          <Link to={"news"} style={{ color: "var(--bgColor)" }}>
            Новости
          </Link>
        ),
        key: "submenu31",
      },
      {
        label: (
          <Link to={"photogallery"} style={{ color: "var(--bgColor)" }}>
            Фотогалерея
          </Link>
        ),
        key: "submenu32",
      },
      {
        label: (
          <Link to={"videogallery"} style={{ color: "var(--bgColor)" }}>
            Видеогалерея
          </Link>
        ),
        key: "submenu33",
      },
      {
        label: (
          <Link to={"mass-media-about-us"} style={{ color: "var(--bgColor)" }}>
            СМИ о нас
          </Link>
        ),
        key: "submenu34",
      },
    ],
  },
  {
    label: <Link to={"scientific-activities"}>Научная деятельность</Link>,
    key: "opt4",
  },
  {
    label: <Link to={"documents"}>Документы</Link>,
    key: "opt5",
  },
  {
    label: <Link to={"announcements"}>Объявления</Link>,
    key: "opt6",
  },
  {
    label: <Link to={"contact"}>Контакты</Link>,
    key: "opt7",
  },
];

//////////////////////////////////
const itemsEng = [
  {
    label: <p>Center</p>,
    key: "op1",
    children: [
      {
        label: (
          <Link to={"about"} style={{ color: "var(--bgColor)" }}>
            About the center
          </Link>
        ),
        key: "submenu1",
      },
      {
        label: (
          <Link to={"adminstration"} style={{ color: "var(--bgColor)" }}>
            Administration
          </Link>
        ),
        key: "submenu2",
      },
      {
        label: (
          <Link to={"scientific-council"} style={{ color: "var(--bgColor)" }}>
            Scientific council
          </Link>
        ),
        key: "submenu93",
      },
      {
        label: (
          <Link to={"scientific-secretary"} style={{ color: "var(--bgColor)" }}>
            Scientific secretary
          </Link>
        ),
        key: "submenu3",
      },
      {
        label: (
          <Link to={"departments"} style={{ color: "var(--bgColor)" }}>
            Departments
          </Link>
        ),
        key: "submenu4",
      },
      {
        label: (
          <Link to={"laboratories"} style={{ color: "var(--bgColor)" }}>
            Laboratories
          </Link>
        ),
        key: "submenu5",
      },
      {
        label: (
          <Link to={"maker-space"} style={{ color: "var(--bgColor)" }}>
            Markerspace Scientific Workshop
          </Link>
        ),
        key: "submenu7",
      },
      {
        label: (
          <Link to={"union-committee"} style={{ color: "var(--bgColor)" }}>
            Union committee
          </Link>
        ),
        key: "submenu6",
      },
      {
        label: (
          <Link to={"center-structure"} style={{ color: "var(--bgColor)" }}>
            Center structure
          </Link>
        ),
        key: "submenu8",
      },
      {
        label: (
          <Link to={"gender-equality"} style={{ color: "var(--bgColor)" }}>
            Gender equality
          </Link>
        ),
        key: "submenu1122",
      },
      {
        label: (
          <a
            href="https://mininnovation.uz/en/static/bosh-ish-orinlari"
            target="_blank"
            style={{ color: "var(--bgColor)" }} rel="noreferrer"
          >
            Vacancies
          </a>
        ),
        key: "submenu9",
      },
    ],
  },
  {
    label: <p>Activity</p>,
    key: "opt2",
    children: [
      {
        label: (
          <Link to={"state-programms"} style={{ color: "var(--bgColor)" }}>
            State programms
          </Link>
        ),
        key: "submenu123",
      },
      {
        label: (
          <Link
            to={"scientific-developments"}
            style={{ color: "var(--bgColor)" }}
          >
            Scientific developments
          </Link>
        ),
        key: "submenu1233",
      },
      {
        label: (
          <Link to={"#"} style={{ color: "var(--bgColor)" }}>
            International relationships
          </Link>
        ),
        key: "submenu13",
        children: [
          {
            label: (
              <Link to={"cooperation"} style={{ color: "var(--bgColor)" }}>
                Cooperation
              </Link>
            ),
            key: "submenu113",
          },
        ],
      },
      {
        label: (
          <Link
            to={"fight-against-corruption"}
            style={{ color: "var(--bgColor)" }}
          >
            Fight against corruption
          </Link>
        ),
        key: "submenu14",
      },
    ],
  },
  {
    label: <p>Press center</p>,
    key: "opt3",
    children: [
      {
        label: (
          <Link to={"news"} style={{ color: "var(--bgColor)" }}>
            News
          </Link>
        ),
        key: "submenu31",
      },
      {
        label: (
          <Link to={"photogallery"} style={{ color: "var(--bgColor)" }}>
            Photogallery
          </Link>
        ),
        key: "submenu32",
      },
      {
        label: (
          <Link to={"videogallery"} style={{ color: "var(--bgColor)" }}>
            Videogallery
          </Link>
        ),
        key: "submenu33",
      },
      {
        label: (
          <Link to={"mass-media-about-us"} style={{ color: "var(--bgColor)" }}>
            Mass media about us
          </Link>
        ),
        key: "submenu34",
      },
    ],
  },
  {
    label: <Link to={"scientific-activities"}>Scientific activities</Link>,
    key: "opt4",
  },
  {
    label: <Link to={"documents"}>Documents</Link>,
    key: "opt5",
  },
  {
    label: <Link to={"announcements"}>Announcements</Link>,
    key: "opt6",
  },
  {
    label: <Link to={"contact"}>Contact</Link>,
    key: "opt7",
  },
];

export default MenuForNavbar;

import React from 'react'
import img from "../assets/images/vaccine.jpg"
import SamePages from '../components/SamePages/SamePages';
const Vaccine = () => {
  return (
    <SamePages data={data}/>
  )
}

const data = {
  img: img,
  titleUzb: "Vaksina",
  textUzb: `www.vaccination.uz rasmiy saytida ZF-UZ-VAC2001 vaksinasi haqidagi ma'lumotlar jamlangan:
  ▪️ Aholini vaksinasiya qilish statistikasi;
  ▪️ Yangiliklar;
  ▪️ Maqolalar;
  ▪️ Video;
  ▪️ Ishlab chiqarish jarayoni;
  ▪️ Eng ko‘p beriladigan savollarga javoblar.
  O‘zbekistonning Xitoy Xalq Respublikasi bilan ilm-fan va innovatsiya sohasidagi hamkorligi 2017 yildan boshlangan. Prezident Shavkat Mirziyoevning Xitoyga davlat tashrifi doirasida O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi bilan Xitoy fan va texnologiyalar vazirligi o‘rtasida ilmiy-texnikaviy hamkorlik bo‘yicha qo‘mita tuzish to‘g‘risida kelishuvga erishildi. 
  Mazkur hamkorliklar doirasida mamlakatimizda o‘zbek-xitoy vaksinasining uchinchi klinik tadqiqotlari o‘tkazildi.
  Ilg‘or texnologiyalar markazi hamda Virusologiya instituti olimlarining ilmiy-tadqiqot va tashkiliy ishlari xitoylik mutaxassislar, shuningdek mustaqil ekspertlar tomonidan yuqori baholandi. Xitoy O‘zbekistonni hammuallif sifatida qabul qilganini e'lon qildi va unga ZF-UZ-VAC2001 nomini berish to‘g‘risida qo‘shma qaror qabul qilindi. Bu yutuq mamlakatimizni o‘z vaksinasiga ega davlatlar qatoriga kirish imkonini berdi.
  Eslatib o‘tamiz, III bosqich klinik sinovlar 5 ta davlatda (Xitoy, O‘zbekiston, Indoneziya, Pokiston, Ekvador) jami 28 500 nafar ko‘ngilli ishtirokida o‘tkazildi. ZF-UZ-VAC2001 koronavirusga qarshi vaksinasining 3-bosqich klinik sinovlari natijalari joriy yilning 27-28 avgust kunlari e'lon qilindi.
  Ushbu tadqiqotlar natijalari shuni ko‘rsatdiki, 3 dozadan keyin vaksina samaradorligi barcha shtammlar uchun 81,76% va «Britancha» shtammga nisbatan 92,93% ni tashkil etadi. O‘lim va og‘ir holatlarga qarshi 100% samarali ekanligi isbotlangan.
  30 ssentyabr' kuni «Jurabek Laboratories» farmasevtika korxonasida ZF-UZ-VAC2001 vaksinasining birinchi partiyasi Xitoylik hamkorlar bilan hamkorlikni boshlaganimizdan deyarli bir yil o‘tib ishlab chiqarildi. 
`,
  mainUzb: "",
  titleRus: "Вакцина",
  textRus: `На сайте www.vaccination.uz собраны данные о вакцине ZF-UZ-VAC2001:
  ▪️ Статистика вакцинирования населения;
  ▪️ Новости;
  ▪️ Статьи;
  ▪️ Видео;
  ▪️ Процесс производства;
  ▪️ Ответы на популярные вопросы.
  Сотрудничество Узбекистана в области науки и инноваций с Китайской Народной Республикой берет свое начало с 2017 года. В ходе государственного визита Президента Шавката Мирзиёева в КНР было достигнуто соглашение между Министерством инновационного развития Узбекистана и Министерством науки и технологий Китая о создании комитета по научно-техническому сотрудничеству. На сегодняшний день в рамках данного сотрудничества осуществляются совместные гранты, которые финансируются с узбекской и китайской сторон, а также обмен молодыми учеными. 
  Именно в рамках данного партнерства в Узбекистане одобрено проведение международного, мультицентрового, рандомизированного, двойного слепого, плацебо-контролируемого клинического исследования в РУз согласно требованиям международного стандарта GCP. Исследовательская и организационная работа ученых Центра передовых технологий и Института Вирусологии была высоко оценена китайскими специалистами и независимыми экспертами. Китайская сторона заявила о принятии Узбекистана в качестве ее соавтора и было принято совместное решение дать ей наименование ZF-UZ-Vac2001. Это достижение позволило нашей стране стать в ряду стран имеющих собственную вакцину.
  Результаты 3 фазы клинических испытаний вакцины против коронавируса ZF-UZ-VAC2001 были опубликованы 27-28 августа текущего года.
  Результаты этих исследований показали, что эффективность вакцины после 3 дозы составила 81,76% для всех штаммов и 92,93% против «британского» штамма. Она показал 100% эффективность против смертельных случаев и тяжелых случаев. Согласно требованиям ВОЗ вакцина должна показать эффективность не менее 50%.
  Уже 30 сентября была выпущена первая партия вакцины ZF-UZ-VAC2001 на фармацевтичеком предприятии Jurabek Laboratories, практически через год после того, как мы начали свое сотрудничество с китайскими партнерами. Сейчас уже налажено производство до 10 миллионов вакцины в месяц.
`,
  mainRus: ``,
  titleEng: "Vaccine",
  textEng: `The official site www.vaccination.uz contains information about the ZF-UZ-VAC2001 vaccine:
  ▪️ Population vaccination statistics;
  ▪️ News;
  ▪️ Articles;
  ▪️ Video;
  ▪️ Production process;
  ▪️ Answers to the most frequently asked questions.
  Uzbekistan's cooperation with the People's Republic of China in the field of science and innovation began in 2017. Within the framework of the state visit of President Shavkat Mirziyoyev to China, an agreement was reached between the Ministry of Innovative Development of the Republic of Uzbekistan and the Ministry of Science and Technology of China on the establishment of a committee on scientific and technical cooperation.
  Within the framework of this cooperation, the third clinical study of the Uzbek-Chinese vaccine was conducted in our country.
  The research and organizational work of the scientists of the Center of Advanced Technologies and the Institute of Virology was highly appreciated by Chinese experts and independent experts. China announced that it accepted Uzbekistan as a co-designer, and a joint decision was made to name it ZF-UZ-VAC2001. This achievement allowed our country to join the ranks of countries that have their own vaccine.
  It should be noted that Phase III clinical trials were conducted in 5 countries (China, Uzbekistan, Indonesia, Pakistan, Ecuador) with the participation of a total of 28,500 volunteers. The results of phase 3 clinical trials of ZF-UZ-VAC2001 anti-coronavirus vaccine were announced on August 27-28 this year.
  The results of these studies showed that the effectiveness of the vaccine after 3 doses is 81.76% for all strains and 92.93% for the "British" strain. It is proven to be 100% effective against death and severe cases.
  On September 30, the first batch of ZF-UZ-VAC2001 vaccine was produced at the pharmaceutical enterprise "Jurabek Laboratories", almost a year after we started cooperation with Chinese partners.
`,
  mainEng: ``,
};

export default Vaccine
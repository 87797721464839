import React from "react";
import { useContext } from "react";
import Laboratory from "../components/Laboratory/Laboratory";
import { LanguageContext } from "../context/languageContext";
import { fudomicEng, fudomicRus, fudomicUzb } from "../data/laboratories/fudomic";

const LaboratoryoftheFudomics = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Laboratory
        data={fudomicUzb}
        title="Oziq-ovqat sanoati laboratoriyasi"
      />
    );
  } else if (lang === "rus") {
    return (
      <Laboratory data={fudomicRus} title="Лаборатория фудомики" />
    );
  } else if (lang === "eng") {
    return (
      <Laboratory data={fudomicEng} title="Laboratory of foodomics" />
    );
  }
};

export default LaboratoryoftheFudomics;

import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";
import {
  scientficCouncilEng,
  scientficCouncilRus,
  scientficCouncilUzb,
} from "../../data/scientificCouncil";
import { Wrapper } from "./style";

const ScientificCouncilComp = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <h1>
          Ilg’or texnologiyalar markazining ilmiy kengash a’zolari ro’yxati
        </h1>
        <table border={1}>
          <thead>
            <tr>
              <th>№</th>
              <th>F.I.SH</th>
              <th>Ilmiy daraja</th>
            </tr>
          </thead>
          <tbody>
            {scientficCouncilUzb.map((value) => {
              return (
                <tr>
                  <td>{value?.id}</td>
                  <td>{value?.name}</td>
                  <td>{value?.position}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <h1>Cписок Учённого совета центра передовых технологий</h1>
        <table border={1}>
          <thead>
            <tr>
              <th>№</th>
              <th>Ф.И.О.</th>
              <th>Ученая степень</th>
            </tr>
          </thead>
          <tbody>
            {scientficCouncilRus.map((value) => {
              return (
                <tr>
                  <td>{value?.id}</td>
                  <td>{value?.name}</td>
                  <td>{value?.position}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <h1>
          The Scientific Council members of the center of advanced technologies
        </h1>
        <table border={1}>
          <thead>
            <tr>
              <th>№</th>
              <th>Name and surname</th>
              <th>Academic degree</th>
            </tr>
          </thead>
          <tbody>
            {scientficCouncilEng.map((value) => {
              return (
                <tr>
                  <td>{value?.id}</td>
                  <td>{value?.name}</td>
                  <td>{value?.position}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    );
  }
};

export default ScientificCouncilComp;

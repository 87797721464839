import React from "react";
import { LanguageContext } from "../../context/languageContext";
import { newsEng, newsRu, newsUz } from "../../data/news";
import AnnouncementCard from "../Announcements/AnnouncementCard/AnnouncementCard";
import { Wrapper } from "./style";

const NewsComp = ({ home }) => {
  const [lang] = React.useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <h1 className="heading">Yangiliklar</h1>
        <div className="content">
          {!home
            ? newsUz.map((value, index) => {
                return (
                  <AnnouncementCard
                    key={value?.id}
                    index={index}
                    value={value}
                    path="news"
                  />
                );
              })
            : newsUz.slice(0, 6).map((value, index) => {
                return (
                  <AnnouncementCard
                    index={index}
                    key={value?.id}
                    value={value}
                    path="news"
                  />
                );
              })}
        </div>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <h1 className="heading">Новости</h1>
        <div className="content">
          {!home
            ? newsRu.map((value, index) => {
                return (
                  <AnnouncementCard
                    key={value?.id}
                    index={index}
                    value={value}
                    path="news"
                  />
                );
              })
            : newsRu.slice(0, 6).map((value, index) => {
                return (
                  <AnnouncementCard
                    index={index}
                    key={value?.id}
                    value={value}
                    path="news"
                  />
                );
              })}
        </div>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <h1 className="heading">News</h1>
        <div className="content">
          {!home
            ? newsEng.map((value, index) => {
                return (
                  <AnnouncementCard
                    key={value?.id}
                    index={index}
                    value={value}
                    path="news"
                  />
                );
              })
            : newsEng.slice(0, 6).map((value, index) => {
                return (
                  <AnnouncementCard
                    index={index}
                    key={value?.id}
                    value={value}
                    path="news"
                  />
                );
              })}
        </div>
      </Wrapper>
    );
  }
};

export default NewsComp;

import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/footer";
import Navbar from "../components/Navbar/navbar";
import { Container, Main } from "./style";

const Layout = () => {
  return (
    <div>
      <Navbar />
      <Main>
        <Container>
          <Outlet />
        </Container>
      </Main>
      <Footer />
    </div>
  );
};

export default Layout;

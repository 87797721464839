import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0;

  h3 {
    text-align: center;
  }
  p {
    color: rgba(255, 255, 255, 0.9);
  }
  .swiper {
    width: 100%;
    padding: 20px 0;
    background-color: rgba(0, 0, 0, 0.15);
    background-color: rgba(208, 208, 208, 0.5);
    margin-bottom: 20px;
  }
  .swiper-slide {
    width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-color: rgba(208, 208, 208, 0.5);
    border-radius: 50%;
    border: 0.5px solid white;
    width: 100%;
    max-width: 50px;
    height: 100%;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: white;
    font-size: 20px;
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 80vh;
  }
  @media (max-width: 600px) {
    margin: 20px 0;
    img {
      height: 20vh;
    }
    .swiper-button-prev,
    .swiper-button-next {
      max-width: 20px;
      max-height: 20px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 12px;
    }
  }
`;

import React from "react";
import { useContext } from "react";
import Laboratory from "../components/Laboratory/Laboratory";
import { LanguageContext } from "../context/languageContext";
import {
  phsicochemicalEng,
  phsicochemicalRus,
  phsicochemicalUzb,
} from "../data/laboratories/phsicochemical";

const LaboratoryofPhysicochemical = () => {
  const [lang] = useContext(LanguageContext);

  if (lang === "uzb") {
    return (
      <Laboratory
        data={phsicochemicalUzb}
        title="Fizik-kimyoviy tadqiqotlar usullari laboratoriyasi"
      />
    );
  } else if (lang === "rus") {
    return (
      <Laboratory
        data={phsicochemicalRus}
        title="Лаборатория физико-химических методов исследования"
      />
    );
  } else if (lang === "eng") {
    return (
      <Laboratory
        data={phsicochemicalEng}
        title="Laboratory of Physicochemical Research Methods"
      />
    );
  }
};

export default LaboratoryofPhysicochemical;

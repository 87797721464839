export const videos = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/Q2r8uFGocmY?si=v9VlnVBQrlnG_spJ",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/EGwUyPmKfSo?si=xUkF_a5091_qm8E9",
  },
  {
    id: 3,
    src: "https://www.youtube.com/embed/bRjkCznUvGw?si=BWfRAIqd3o-4pe2T",
  },
  {
    id: 4,
    src: "https://www.youtube.com/embed/GHW9NIEjMuI?si=miyAki3iHMHd2Wxh",
  },
];

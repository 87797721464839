import React from "react";
import { LanguageContext } from "../../../context/languageContext";
import { Box } from "./style";

const Logo = () => {
  const [lang] = React.useContext(LanguageContext);
  return lang === "uzb" ? (
    <Box>
      <p>Oliy Ta'lim, Fan va </p>
      <p>Innovatsiyalar Vazirligi huzuridagi</p>
      <p className="nowrap">Ilg'or Texnologiyalar Markazi</p>
    </Box>
  ) : lang === "rus" ? (
    <Box>
      <p>Центр Передовых Технологий</p>
      <p>при Министерстве Высшего Образования,</p>
      <p className="nowrap">Науки и Инноваций</p>
    </Box>
  ) : (
    <Box>
      <p>Center for Advanced Technologies </p>
      <p>under the Ministry of Higher Education,</p>
      <p className="nowrap">Science and Innovation</p>
    </Box>
  );
};

export default Logo;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
  @media (max-width: 600px) {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  @media (max-width: 750px) {
    p{
      font-size: 14px;
    }
    h1, h2, h3{
      font-size: 16px;
    }
  }
  .heading {
    margin-bottom: 20px;
  }
  .content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

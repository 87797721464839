import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";

import { Wrapper } from "./style";
import {
  memorandumEng,
  memorandumRus,
  memorandumUzb,
} from "../../data/memorandums";

const MemorandumsComp = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <h1>
          MEMORADUMLAR Ilg'or texnologiyalar markazining hamkorligi to'g'risida
        </h1>
        <table border={1}>
          <thead>
            <tr>
              <th>№</th>
              <th>Biznes nomi</th>
              <th>Davlat</th>
              <th>Sana</th>
            </tr>
          </thead>
          <tbody>
            {memorandumUzb.map((value) => {
              return (
                <tr>
                  <td>{value?.id}</td>
                  <td>{value?.title}</td>
                  <td>{value?.country}</td>
                  <td>{value?.date}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <h1>МЕМОРАНДУМЫ о сотрудничестве Центра передовых технологий</h1>
        <table border={1}>
          <thead>
            <tr>
              <th>№</th>
              <th>Наименование предприятия</th>
              <th>Государство</th>
              <th>Дата</th>
            </tr>
          </thead>
          <tbody>
            {memorandumRus.map((value) => {
              return (
                <tr>
                  <td>{value?.id}</td>
                  <td>{value?.title}</td>
                  <td>{value?.country}</td>
                  <td>{value?.date}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <h1>
          MEMORANDUMS about the cooperation of the Center for Advanced
          Technologies
        </h1>
        <table border={1}>
          <thead>
            <tr>
              <th>№</th>
              <th>Business name</th>
              <th>State</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {memorandumEng.map((value) => {
              return (
                <tr>
                  <td>{value?.id}</td>
                  <td>{value?.title}</td>
                  <td>{value?.country}</td>
                  <td>{value?.date}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    );
  }
};

export default MemorandumsComp;

import React from "react";
import { Container, Main, Section } from "./style";
import SelectForLaguage from "./SelectForLanguage";
import MenuForNavbar from "./Menu";
import logo from "../../assets/images/cropped-favicon.png";
import { useNavigate } from "react-router-dom";
import DrawerForNavbar from "./Drawer/Drawer";
import Logo from "./Logo/logo";
const Navbar = ({ home }) => {
  const navigate = useNavigate();
  return (
    <Main home={home}>
      <Container>
        <Section>
          <div className="logo" onClick={() => navigate("/")}>
            <img src={logo} alt="logo" />
            <Logo />
          </div>
          <div className="menu">
            <MenuForNavbar />
          </div>
          <div className="drawer">
            <DrawerForNavbar />
          </div>
          <div className="select">
            <SelectForLaguage />
          </div>
        </Section>
      </Container>
    </Main>
  );
};

export default Navbar;

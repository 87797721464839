export const raqamlashtirishUzb = [
  {
    id: 30,
    title: "Davlat ilmiy dasturlari uchun yagona aqlli elektron platforma",
    img: "https://mininnovation.uz/media/interactive_services/digital1_upgsBs0.png",
    path: "https://mininnovation.uz/projects/davlat-ilmiy-dasturlari-uchun-yagona-aqlli-elektro",
  },
  {
    id: 31,
    title:
      "PHD.MININNOVATION.UZ - Oliy o'quv yurtidan keyingi ta'limni muvofiqlashtirish tizimi",
    img: "https://mininnovation.uz/media/interactive_services/digital2_RM6KDvT.png",
    path: "https://mininnovation.uz/projects/phdmininnovationuz-oliy-oquv-yurtidan-keyingi-tali",
  },
  {
    id: 32,
    title: "Invest.mininnovation.uz - Grant va investitsiya loyihalari tizimi",
    img: "https://mininnovation.uz/media/interactive_services/digital3_m1peKGX.png",
    path: "https://mininnovation.uz/projects/investmininnovationuz-grant-va-investitsiya-loyiha",
  },
  {
    id: 34,
    title: "IAS.GOV.UZ - Ilmiy -texnik ma'lumotlarning axborot -tahlil tizimi",
    img: "https://mininnovation.uz/media/interactive_services/digital4.png",
    path: "https://mininnovation.uz/projects/iasgovuz-ilmiy-texnik-malumotlarning-axborot-tahli",
  },
  {
    id: 33,
    title: "NSP.GOV.UZ - Milliy ilmiy portali",
    img: "https://mininnovation.uz/media/interactive_services/digital5.png",
    path: "https://mininnovation.uz/projects/nspgovuz-milliy-ilmiy-portali",
  },
  {
    id: 35,
    title: "SLIB.UZ - Elektron kutubxona",
    img: "https://mininnovation.uz/media/interactive_services/digital6.png",
    path: "https://mininnovation.uz/projects/slibuz-elektron-kutubxona",
  },
];
export const raqamlashtirishRus = [
  {
    id: 30,
    title:
      "Единая интеллектуальная электронная платформа государственных научных программ",
    img: "https://mininnovation.uz/media/interactive_services/digital1_upgsBs0.png",
    path: "https://mininnovation.uz/projects/davlat-ilmiy-dasturlari-uchun-yagona-aqlli-elektro",
  },
  {
    id: 31,
    title:
      "PHD.MININNOVATION.UZ - Система координации послевузовского образования",
    img: "https://mininnovation.uz/media/interactive_services/digital2_RM6KDvT.png",
    path: "https://mininnovation.uz/projects/phdmininnovationuz-oliy-oquv-yurtidan-keyingi-tali",
  },
  {
    id: 32,
    title: "Invest.mininnovation.uz - Grant va investitsiya loyihalari tizimi",
    img: "https://mininnovation.uz/media/interactive_services/digital3_m1peKGX.png",
    path: "https://mininnovation.uz/projects/investmininnovationuz-grant-va-investitsiya-loyiha",
  },
  {
    id: 34,
    title:
      "IAS.GOV.UZ - Информационно аналитическая система научно- технической информации",
    img: "https://mininnovation.uz/media/interactive_services/digital4.png",
    path: "https://mininnovation.uz/ru/projects/iasgovuz-ilmiy-texnik-malumotlarning-axborot-tahli",
  },
  {
    id: 33,
    title: "NSP.GOV.UZ - Национальный научный портал",
    img: "https://mininnovation.uz/media/interactive_services/digital5.png",
    path: "https://mininnovation.uz/projects/nspgovuz-milliy-ilmiy-portali",
  },
  {
    id: 35,
    title: "SLIB.UZ - Электронная библиотека",
    img: "https://mininnovation.uz/media/interactive_services/digital6.png",
    path: "https://mininnovation.uz/ru/projects/slibuz-elektron-kutubxona",
  },
];
export const raqamlashtirishEng = [
  {
    id: 30,
    title:
      "The only intelligent electronic platform for public research programs",
    img: "https://mininnovation.uz/media/interactive_services/digital1_upgsBs0.png",
    path: "https://mininnovation.uz/en/projects/davlat-ilmiy-dasturlari-uchun-yagona-aqlli-elektro",
  },
  {
    id: 31,
    title:
      "PHD.MININNOVATION.UZ - Coordination system for postgraduate education",
    img: "https://mininnovation.uz/media/interactive_services/digital2_RM6KDvT.png",
    path: "https://mininnovation.uz/en/projects/phdmininnovationuz-oliy-oquv-yurtidan-keyingi-tali",
  },
  {
    id: 32,
    title: "Invest.mininnovation.uz - Grant and investment project system",
    img: "https://mininnovation.uz/media/interactive_services/digital3_m1peKGX.png",
    path: "https://mininnovation.uz/en/projects/investmininnovationuz-grant-va-investitsiya-loyiha",
  },
  {
    id: 34,
    title:
      "IAS.GOV.UZ - Information analytical system of scientific and technical information",
    img: "https://mininnovation.uz/media/interactive_services/digital4.png",
    path: "https://mininnovation.uz/en/projects/iasgovuz-ilmiy-texnik-malumotlarning-axborot-tahli",
  },
  {
    id: 33,
    title: "NSP.GOV.UZ - National Science Portal",
    img: "https://mininnovation.uz/media/interactive_services/digital5.png",
    path: "https://mininnovation.uz/en/projects/nspgovuz-milliy-ilmiy-portali",
  },
  {
    id: 35,
    title: "SLIB.UZ - Digital library",
    img: "https://mininnovation.uz/media/interactive_services/digital6.png",
    path: "https://mininnovation.uz/en/projects/slibuz-elektron-kutubxona",
  },
];

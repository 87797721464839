import React, { useRef } from "react";
import { Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import emailjs from "@emailjs/browser";
import { Section } from "./style";
import { LanguageContext } from "../../context/languageContext";

const ContactComp = () => {
  const [lang] = React.useContext(LanguageContext);
  const form = useRef();

  const sendEmail = (e) => {
    emailjs.sendForm(
      "service_jq7bzbp",
      "template_7f363lt",
      form.current,
      "rKN9WNhKxEOAP2GjR"
    );
  };
  if (lang === "uzb") {
    return (
      <Section>
        <h1>Biz bilan bog'lanish</h1>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <label>F.I.Sh</label>
            <Input type="text" name="user_name" />
          </div>
          <div>
            <label>Email</label>
            <Input type="em ail" name="user_email" />
          </div>
          <div>
            <label>Xabar matni</label>
            <TextArea name="message" rows={12} />
          </div>
          <Input
            type="submit"
            value="Jo'natish"
            style={{ width: "120px", cursor: "pointer", fontSize: "16px" }}
          />
        </form>
      </Section>
    );
  } else if (lang === "rus") {
    return (
      <Section>
        <h1>Cвяжитесь с нами</h1>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <label>Ф.И.О</label>
            <Input type="text" name="user_name" />
          </div>
          <div>
            <label>Почта</label>
            <Input type="em ail" name="user_email" />
          </div>
          <div>
            <label>Сообщение</label>
            <TextArea name="message" rows={12} />
          </div>
          <Input
            type="submit"
            value="Отправить"
            style={{ width: "120px", cursor: "pointer", fontSize: "16px" }}
          />
        </form>
      </Section>
    );
  } else if (lang === "eng") {
    return (
      <Section>
        <h1>Contact us</h1>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <label>Full name</label>
            <Input type="text" name="user_name" />
          </div>
          <div>
            <label>Email</label>
            <Input type="em ail" name="user_email" />
          </div>
          <div>
            <label>Message</label>
            <TextArea name="message" rows={12} />
          </div>
          <Input
            type="submit"
            value="Send"
            style={{ width: "120px", cursor: "pointer", fontSize: "16px" }}
          />
        </form>
      </Section>
    );
  }
};

export default ContactComp;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h1 {
    margin-bottom: 0;
  }
  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 60px 20px;
    margin-top: 50px;
  }
  @media (max-width: 1100px) {
    .cards-wrapper {
      grid-template-columns: repeat(3, 1fr);
      gap: 50px 10px;
    }
  }
  @media (max-width: 750px) {
    gap: 0;
    h1 {
      font-size: 16px;
    }
    .cards-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";
import { Wrapper } from "./style";
import { videos } from "./videos";

const VideogalleryComp = () => {
  const [lang] = useContext(LanguageContext);
  return (
    <Wrapper>
      <h1>
        {lang === "uzb"
          ? "Videogalereya"
          : lang === "rus"
          ? "Видеогалерея"
          : "Videogallery"}
      </h1>
      <div className="top">
        <div className="left">
          <iframe
            src="https://www.youtube.com/embed/jaG4_n5FS1w"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div className="right">
          <iframe
            src="https://www.youtube.com/embed/ThoIhGVgaFc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/0PuKMUuvzWY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className="bottom2">
        {videos.map((value) => {
          return (
            <div className="wrapper" key={value.id}>
              <iframe
                src={value.src}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default VideogalleryComp;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  .content {
    width: 100%;
    background-color: var(--cardColor);
    padding: 20px;
    border-radius: 20px;
    .content {
      h3 {
        text-align: center;
      }
    }
  }
  margin-bottom: 50px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
  @media (max-width: 750px) {
    p {
      font-size: 14px;
    }
    h1,
    h2,
    h3 {
      font-size: 16px;
    }
  }
`;

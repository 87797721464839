import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 50px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
  .top {
    width: 100%;
    display: flex;
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      iframe {
        width: 100%;
        height: 100%;
        max-height: 420px;
        box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -moz-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      iframe {
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 200px;
        box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -moz-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    .right {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      iframe {
        width: 100%;
        max-width: 600px;
        height: 320px;
      }
    }
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      iframe {
        width: 100%;
        height: 100%;
        max-height: 420px;
      }
    }
  }
  .bottom2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-right: 40px;
    iframe {
      width: 100%;
      max-width: 600px;
      height: 300px;
      box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -moz-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    }
  }

  @media (max-width: 600px) {
    .top {
      flex-direction: column;
      gap: 20px;
    }
    .bottom {
      flex-direction: column;
      gap: 20px;
    }
    .bottom2 {
      grid-template-columns: repeat(2, 1fr);
      padding-right: 0;
    }
  }
`;

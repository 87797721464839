import styled from "styled-components";
export const Section = styled.div`
  margin: 50px 0;
  width: 100%;
  h1 {
    margin-bottom: 20px;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
    background-color: var(--cardColor);
    padding: 20px;
    display: flex;
    gap: 20px;
    .left {
      width: 50%;
      img {
        width: 100%;
        max-width: 900px;
      }
    }
    .right {
      width: 50%;
    }
  }
  @media (max-width: 600px) {
    margin: 20px 0;
  }
  @media (max-width: 750px) {
    p,
    a {
      font-size: 14px;
    }
    h1,
    h2,
    h3 {
      font-size: 16px;
    }
    .card {
      flex-direction: column;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
`;

import React from "react";
import SamePages from "../components/SamePages/SamePages";
import davlat_dasturlari from "../assets/images/davlat-dasturlari.jpg"
const StateProgramms = () => {
  return <SamePages data={data} />;
};

const data = {
  img: davlat_dasturlari,
  titleUzb: "Davlat dasturlari",
  textUzb: `“Inson qadrini ulug‘lash va faol mahalla yili”da amalga oshirishga oid Davlat dasturida vazirllika jami 20 ta topshiriq yuklatilgan bo‘lib, asosiy ijrochi sifatida 7 ta band, hamijrochi sifatida 13 ta bandda topshiriqlar belgilangan.

Dasturni 85-bandida – Farmasevtika sanoatimahsulotlari ishlab chiqarish hajmini 3 barobar ko‘paytirish va mahalliy bozorni ta’minlash darajasini 80foizga yetkazish borasida 2022 yil mart oyi yakuniga qadar Vazirlar Mahkamasining qarori loyihasini ishlab chiqish vazifasi yuklatilgan.
Mazkur band ijrosi yuzasidan O‘zbekiston Respublikasi Vazirlar Mahkamasining “Ilg‘or texnologiyalar markazida biotexnologik tajriba-sanoat ishlab chiqarishni yo‘lga qo‘yish to‘g‘risida”gi qarori loyihasi ishlab chiqildi (ID-57612). Loyiha vazirlik va idoralar bilan o‘rnatilgan tartibda kelishildi hamda Adliya vazirligidan huquqiy ekspertizadan o‘tkazilib, 2022-yil 28-martda Vazirlar Mahkamasiga kiritildi.
Mazkur qaror loyihasida Ilg‘or texnologiyalar markazi tuzilmasida biotexnologik preparatlarni, jumladan vaksinalar, koronovirus infeksiyasiga qarshi yangi avlod mahalliy vaksinalar, hujayra preparatlari, gormonlar, antitanalar, oqsillar, peptidlarni tajriba-ishlab chiqarishga ixtisoslashgan “CAT-Biotech” bo‘linmasini tashkil etish masalalari ham inobatga olingan.
VII. Dasturni 320-bandida – Koronavirus infeksiyasiga qarshi yangi avlod mahalliy vaksinalarni yaratish borasida 2022-yil mart oyi yakuniga qadar Vazirlar Mahkamasining qarori loyihasi ishlab chiqish vazifasi yuklatilgan.
Mazkur band ijrosi yuzasidan O‘zbekiston Respublikasi Vazirlar Mahkamasining “Ilg‘or texnologiyalar markazida biotexnologik tajriba-sanoat ishlab chiqarishni yo‘lga qo‘yish to‘g‘risida”gi qarori loyihasi ishlab chiqildi (ID-57612). Loyiha vazirlik va idoralar bilan o‘rnatilgan tartibda kelishildi hamda Adliya vazirligidan huquqiy ekspertizadan o‘tkazilib, 2022-yil 28-martda Vazirlar Mahkamasiga kiritildi.
`,
  mainUzb:
    "Ma’lumot uchun: Mazkur qaror loyihasida Ilg‘or texnologiyalar markazi tuzilmasida biotexnologik preparatlarni, jumladan vaksinalar, koronovirus infeksiyasiga qarshi yangi avlod mahalliy vaksinalar, hujayra preparatlari, gormonlar, antitanalar, oqsillar, peptidlarni tajriba-ishlab chiqarishga ixtisoslashgan “CAT-Biotech” bo‘linmasini tashkil etish masalalari ham inobatga olingan.",
  titleRus: "Государственые программы",
  textRus: `В Государственной программе по реализации «Года прославления человеческой ценности и активного добрососедства» Министерству поручено всего 20 задач, 7 пунктов в качестве основного исполнителя и 13 пунктов в качестве соисполнителя.
В пункте 85 программы поставлена ​​задача разработать до конца марта 2022 года проект постановления Кабинета Министров об увеличении объемов производства продукции фармацевтической промышленности в 3 раза и повышении уровня поставок на местный рынок до 80 процент.
В связи с реализацией данного пункта разработан проект постановления Кабинета Министров Республики Узбекистан «О создании биотехнологического опытно-промышленного производства в центре передовых технологий» (ID-57612). Проект в установленном порядке согласован с министерствами и ведомствами, прошел правовую экспертизу Минюста и 28 марта 2022 года внесен в Кабинет Министров.
В данном проекте решения есть вопросы создания подразделения «КАТ-Биотех», которое специализируется на экспериментальном производстве биотехнологических препаратов, в том числе вакцин, местных вакцин нового поколения против короновирусной инфекции, клеточных препаратов, гормонов, антител, белков, пептидов, в структуре Центра перспективных технологий.
VII. Пункт 320 программы — задача разработать до конца марта 2022 года проект постановления Кабинета Министров о создании отечественных вакцин нового поколения от коронавирусной инфекции.
В связи с реализацией данного пункта разработан проект постановления Кабинета Министров Республики Узбекистан «О создании биотехнологического опытно-промышленного производства в центре передовых технологий» (ID-57612). Проект в установленном порядке согласован с министерствами и ведомствами, прошел правовую экспертизу Минюста и 28 марта 2022 года внесен в Кабинет Министров.
Профсоюзный комитет Центра участвует в собраниях коллегиях, курултаях и др.мероприятиях проводимые объединённым профсоюзным комитетом Академии наук Республики Узбекистан.
`,
  mainRus:
    "Для справки: ООО «CAT-BIOTECH», специализирующееся на опытном производстве биотехнологических препаратов, в том числе вакцин, местных вакцин нового поколения против короновирусной инфекции, клеточных препаратов, гормонов, антител, белков, пептидов, в структуре Центра передовых технологий.",
  titleEng: "State programms",
  textEng: `In the State Program for the implementation of “Glorification of Human Value and Active Neighborhood Year”, the Ministry is assigned a total of 20 tasks, 7 items as the main executor, and 13 items as a co-executor.
In item 85 of the program, it is tasked to develop a draft decision of the Cabinet of Ministers by the end of March 2022 on increasing the production volume of pharmaceutical industry products by 3 times and increasing the level of supply to the local market to 80 percent.
In connection with the implementation of this clause, a draft decision of the Cabinet of Ministers of the Republic of Uzbekistan “On establishing biotechnological experimental-industrial production in the center of advanced technologies” was developed (ID-57612). The project was agreed with the ministries and agencies in the established order and passed the legal expertise of the Ministry of Justice and was submitted to the Cabinet of Ministers on March 28, 2022.
In this draft decision, there are issues of establishing the “CAT-Biotech” division, which specializes in the experimental production of biotechnological preparations, including vaccines, new-generation local vaccines against corona virus infection, cell preparations, hormones, antibodies, proteins, peptides, within the structure of the Advanced Technologies Center. taken into account.
VII. Item 320 of the program – the task of developing a draft decision of the Cabinet of Ministers by the end of March 2022 on the creation of a new generation of local vaccines against coronavirus infection.
In connection with the implementation of this clause, the draft resolution of the Cabinet of Ministers of the Republic of Uzbekistan “On establishing biotechnological experimental-industrial production in the center of advanced technologies” was developed (ID-57612). The project was agreed with the ministries and agencies in the established order and passed the legal expertise of the Ministry of Justice and was submitted to the Cabinet of Ministers on March 28, 2022.
`,
  mainEng:
    "For information: “CAT-Biotech” specializing in pilot production of biotechnological preparations, including vaccines, new-generation local vaccines against coronovirus infection, cell preparations, hormones, antibodies, proteins, peptides, in the structure of the Advanced Technologies Center. issues of organization of linma were also taken into account.",
};

export default StateProgramms;

import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 50px 0;
  width: 100%;
  .header {
    h1 {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 600px) {
    margin: 20px 0;
  }
  @media (max-width: 750px) {
    p{
      font-size: 14px;
    }
    h1, h2, h3{
      font-size: 16px;
    }
  }
`;

export const Hero = styled.div`
  width: 100%;
  background-color: var(--cardColor);
  padding: 20px;
  box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
  -webkit-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
  -moz-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
  p {
    line-height: 1.6;
  }
  .img-wrapper {
    width: 100%;
    max-width: 500px;
    float: left;
    margin-right: 20px;

    img {
      width: 100%;
      max-width: 500px;
    }
  }
  @media (max-width: 859px) {
    .img-wrapper {
      margin-bottom: 20px;
    }
  }
`;

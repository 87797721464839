import img1 from "../assets/images/adminstration/shahloT.jpg";
import img2 from "../assets/images/adminstration/dilbarA.jpg";
import img3 from "../assets/images/adminstration/nizamiF.jpg";
import noperson from "../assets/images/noperson.jpg";
export const adminstrationUzb = [
  {
    id: 1,
    img: img1,
    name: "Turdikulova Shahlo Utkurovna",
    position: "Ilg’or texnologiyalar markazi direktori",
    degree: "Biologiya fanlari doktori professor",
    number: "+99871 227-43-20 ichki (45-00)",
    email: "sh.turdikulova@cat-science.uz",
    time: "Fuqarolarni qabul qilish kunlari: Payshanba 15.00 dan 15.30 gacha.",
    btn: "Batafsil",
    title1: "Biografiya",
    info1:
      "Turdikulova Shahlo Utkurovna 1973-yil 28-aprelda Toshkent shahrida tug‘ilgan. Sh.Turdikulova biologiya fanining bir qancha yo‘nalishlari bo‘yicha ilmiy faoliyat olib boradi va O‘zbekiston Respublikasida molekulyar biotexnologiya va molekulyar genetika fanlarini rivojlantirishga katta hissa qo‘shib, yoshlarning intellektual salohiyatini oshirishga katta e’tibor qaratmoqda. U molekulyar genetika va molekulyar biotexnologiya bo'yicha o'zining ilmiy maktabini yaratdi. Faoliyati davomida 160 dan ortiq ilmiy maqolalar, jumladan, 2 ta darslik va 2 ta monografiya, 30 dan ortiq xalqaro maqolalar chop etgan. “h-indeks” – 14, boshqa olimlar keltirgan iqtiboslar statistikasi – 2291, shuni ta’kidlash joizki, Sh.Turdiqulova O‘zbekiston Respublikasi bo‘yicha eng yuqori umumiy impakt faktorga ega – 149,5.",
    title2: "Ta'lim:",
    info2: [
      "1995-yil Mirzo Ulug‘bek nomidagi Toshkent Davlat universitetining biologiya fakultetini tamomlagan.",
      "2002-yildan 2005-yilgacha u Los-Anjelesdagi Kaliforniya universitetida doktoranturani tamomlagan.",
    ],
    title3: "Ilmiy daraja, unvon:",
    info3: "Biologiya fanlari doktori (2009), professor (2019).",
    title4: "Kasbiy faoliyati:",
    info4: [
      "2010-2012 yillarda - O‘zbekiston Respublikasi Fanlar akademiyasi Biokimyo instituti direktori o‘rinbosari.",
      "2017 – 2018 yillarda – O‘zbekiston Respublikasi Fanlar akademiyasi Bioorganik kimyo instituti direktori o‘rinbosari.",
      "2018 yildan hozirgi kungacha — O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi huzuridagi Ilg‘or texnologiyalar markazi direktori.",
      "2020 yildan hozirgi kunga qadar — Oʻzbekiston Respublikasi Innovatsion rivojlanish vazirining birinchi oʻrinbosari.",
    ],
    title5: "Mukofotlar va unvonlar:",
    info5: [
      `2009 yilda TWAS (Jahon fanlar akademiyasi) tomonidan o'tkazilgan "Yilning yosh olimlari" tanlovida g'olib chiqdi.`,
      `2018 yilda u nominatsiya bo'yicha Scopus Award-2018 mukofotiga sazovor bo'ldi.`,
      `2018-yilda O‘zbekiston Respublikasi Davlat mukofoti – “Do‘stlik” ordeni bilan taqdirlangan.`,
    ],
  },
  {
    id: 2,
    img: img2,
    name: "Dalimova Dilbar Akbarovna",
    position:
      "Ilg‘or texnologiyalar markazi direktorining ilmiy ishlar bo‘yicha o‘rinbosari",
    degree: "Biologiya fanlari nomzodi (PhD)",
    number: "+99871 227-43-21 ichki (45-01)",
    email: "d.dalimova@cat-science.uz",
    time: "Fuqarolarni qabul qilish kunlari: Chor, Juma 16.00 dan 16.30 gacha.",
    btn: "Batafsil",
    title1: "Biografiya",
    info1:
      "Dalimova Dilbar Akbarovna 1973-yil 4-sentabrda Toshkent shahrida tug’ilgan.",
    title2: "Ta'lim:",
    info2: [
      "Toshkent Davlat universitetining biologiya fakultetini tamomlagan. Mirzo Ulug‘bek (1995).",
    ],
    title3: "Ilmiy daraja, unvon:",
    info3: "Biologiya fanlari nomzodi, katta ilmiy xodim.",
    title4: "Kasbiy faoliyati:",
    info4: [
      "2012-2017 yillarda yillarda - O‘zbekiston Respublikasi Fanlar akademiyasi Bioorganik kimyo instituti genomika laboratoriyasi katta ilmiy xodimi.",
      "2017-2018  yillarda – Yuqori texnologiyalar o‘quv tajriba markazi davlat korxonasi Biologiya laboratoriyasi mudiri.",
      `2018-2022 yillarda — O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi huzuridagi 
      Ilg‘or texnologiyalar markazi Biotexnologiya laboratoriyasi mudiri`,
      `2022 yildan hozirgi kunga qadar — O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi huzuridagi 
      Ilg‘or texnologiyalar markazi direktorining ilmiy ishlar bo‘yicha o‘rinbosari
      `,
    ],
    title5: "Mukofotlar va unvonlar:",
    info5: [`II darajali «Salomatlik ordeni» (2020 yil)`],
  },
  {
    id: 3,
    img: noperson,
    name: "Sevara Abbasovna Karimova",
    position: "Umumiy ishlar bo’yicha direktor o’rinbosari",
    number: "+99871 227-43-23 ichki (45-20)",
    email: "s.karimova@cat-science.uz",
    time: "Fuqarolarni qabul qilish kunlari: Seshanba 14.30 dan 15.00 gacha.",
    btn: "Batafsil",
    title1: "Biografiya",
    info1: "Sevara Abbasovna Karimova, 1978 yilda Toshkent shahrida tug‘ilgan.",
    title2: "Ta'lim:",
    info2: [
      "1997-2001yy Toshkent farmatsevtika instituti (bakalavriatura)",
      "2003 yy Toshkent farmatsevtika instituti (aspirantura)",
      "2004-2006 yy Toshkent farmatsevtika instituti (magistratura)",
    ],
    title4: "Kasbiy faoliyati:",
    info4: [
      "2009-2010 yy Rossiya Davlat Intellektual mulk akademiyasi",
      "2010-2019 yy O‘zbekiston Respublikasi Adliya vazirligi xuzuridagi Intellektual mulk agentligi bo‘lim boshlig‘i",
      "2019-2023 yy O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi boshqarma boshlig‘i o‘rinbosari",
      "2023 – h.v O‘zbekiston Respublikasi Oliy ta’lim, fan va innovatsiyalar vazirligi huzuridagi Innovatsion rivojlanish agentligi boshqarma boshlig‘i",
    ],
  },
];

export const adminstrationRus = [
  {
    id: 1,
    img: img1,
    name: "Турдикулова Шахло Уткуровна",
    position: "Директор Центра передовых технологий",
    degree: "Доктор биологических наук профессор",
    number: "+99871 227-43-20 внутренний (45-00)",
    email: "sh.turdikulova@cat-science.uz",
    time: "Прием граждан каждый: Четверг с 15.00 до 15.30.",
    btn: "Более",
    title1: "Биография",
    info1:
      "Турдикулова Шахло Уткуровна родилась 28 апреля 1973 года в городе Ташкенте. Ш.Турдикулова осуществляет научную деятельность в нескольких областях биологии и внесла значительный вклад в развитие молекулярной биотехнологии и молекулярной генетики в Республике Узбекистан, уделяет огромное внимание повышению интеллектуального потенциала молодежи. Создала собственную научную школу по молекулярной генетике и молекулярной биотехнологии. За свою карьеру опубликовала более 160 научных работ, в том числе 2 учебника и 2 монографии, свыше 30 международных статей. “h-индекс” – 14, статистика цитирований другими учёными — 2291, следует отметить, что у Ш.Турдикуловой самый высокий общий импакт-фактор в Республике Узбекистан – 149,5.",
    title2: "Образование:",
    info2: [
      "Окончила биологический факультет Ташкентского государственного университета им. Мирзо Улугбека (1995)",
      "С 2002 по 2005 год прошла обучение в докторантуре Калифорнийского университета в Лос-Анджелесе.",
    ],
    title3: "Ученая степень, звание:",
    info3: "Доктор биологических наук (2009), профессор (2019).",
    title4: "Профессиональная деятельность:",
    info4: [
      "С 2010 по 2012 годы – Заместитель директора института биохимии Академии наук Республики Узбекистан.",
      "В 2017 – 2018 годы — Заместитель директора института биоорганической химии Академии наук Республики Узбекистан.",
      "С 2018 года по н.в. — Директор Центра передовых технологий при Министерстве инновационного развития Республики Узбекистан.",
      "С 2020 по н.в – Первый заместитель министра инновационного развития Республики Узбекистан.",
    ],
    title5: " Награды, звания и премии:",
    info5: [
      `В 2009 году победила в конкурсе «Молодые учёные года», проводимого  TWAS (Всемирной Академией наук).`,
      `В 2018 году получила награду Scopus Award-2018 в номинации «Женщины в науке».`,
      `В 2018 году была награждена Государственной наградой Республики Узбекистан – орденом «Дустлик».`,
    ],
  },
  {
    id: 2,
    img: img2,
    name: "Далимова Дилбар Акбаровна",
    position: "Заместитель директора по науке Центра передовых технологий",
    degree: "Кандидат биологических наук (PHD)",
    number: "+99871 227-43-21 внутренний (45-01)",
    email: "d.dalimova@cat-science.uz",
    time: "Прием граждан каждый: Среда, Пятница с 16.00 до 16.30.",
    btn: "Более",
    title1: "Биография",
    info1:
      "Далимова Дильбар Акбаровна родилась 4 сентября 1973 года в Ташкенте.",
    title2: "Образование:",
    info2: [
      "Окончил биологический факультет Ташкентского государственного университета. Мирзо Улугбек (1995).",
    ],
    title3: "Ученая степень, звание:",
    info3: "Кандидат биологических наук, старший научный сотрудник.",
    title4: "Профессиональная деятельность:",
    info4: [
      "В 2012-2017 годах был старшим научным сотрудником лаборатории геномики Института биоорганической химии Академии наук Республики Узбекистан.",
      "С 2017 по 2021 год — заведующий Биологической лабораторией государственного предприятия Учебно-экспериментальный центр высоких технологий.",
      `2018-2022 гг. — При Министерстве инновационного развития Республики Узбекистан
      Заведующий биотехнологической лабораторией Центра перспективных технологий`,
      `С 2022 года при Министерстве инновационного развития Республики Узбекистан
      Заместитель директора Центра передовых  технологий по науке
      `,
    ],
    title5: " Награды, звания и премии:",
    info5: [`II степень орден «Salomatlik» (2020 г.)`],
  },
  {
    id: 3,
    img: noperson,
    name: "Севара Аббасовна Каримова",
    position: "Заместитель директора по общим вопросам",
    number: "+99871 227-43-23 внутренний (45-20)",
    email: "s.karimova@cat-science.uz",
    time: "Прием граждан каждый Вторник с 14.30 до 15.00.",
    btn: "Более",
    title1: "Биография",
    info1: "Севара Аббасовна Каримова, родилась в 1978 году в городе Ташкенте.",
    title2: "Образование:",
    info2: [
      "1997-2001 г.г Ташкентский фармацевтический институт (бакалавриатура)",
      "2003 г.г Ташкентский фармацевтический институт (аспирантура)",
      "2004-2006 г.г Ташкентский фармацевтический институт (магистратура)",
    ],
    title4: "Профессиональная деятельность:",
    info4: [
      "2009-2010 г.г. Российская Государственная Академия интеллектуальной собственности",
      "2001-2019 г.г. Начальник отдела Агенства по интеллектуальной собственности при Министерстве юстиции РУз",
      "2019-2023 г.г. Заместитель начальника управления Министерства инновационного развития",
      "2023 – н.в. Начальник управления Агентства инновационного развития при Министерстве образования, науки и инноваций",
    ],
  },
];

export const adminstrationEng = [
  {
    id: 1,
    img: img1,
    name: "Turdikulova Shahlo Utkurovna",
    position: "Director of the Center for Advanced Technologies",
    degree: "Doctor of Biological Sciences Professor",
    number: "+99871 227-43-20 internal (45-00)",
    email: "sh.turdikulova@cat-science.uz",
    time: "Reception of citizens every Thursday from 15.00 to 15.30.",
    btn: "More",
    title1: "Biography",
    info1:
      "Turdikulova Shahlo Utkurovna was born on April 28, 1973 in the city of Tashkent. Sh. Turdikulova carries out scientific activities in several areas of biology and has made a significant contribution to the development of molecular biotechnology and molecular genetics in the Republic of Uzbekistan, pays great attention to increasing the intellectual potential of young people. She created her own scientific school in molecular genetics and molecular biotechnology. During her career she has published more than 160 scientific papers, including 2 textbooks and 2 monographs, over 30 international articles. “h-index” - 14, statistics of citations by other scientists - 2291, it should be noted that Sh.Turdikulova has the highest overall impact factor in the Republic of Uzbekistan - 149.5.",
    title2: "Education:",
    info2: [
      "Graduated from the Faculty of Biology of the Tashkent State University. Mirzo Ulugbek (1995)",
      "From 2002 to 2005, she completed her doctoral studies at the University of California, Los Angeles.",
    ],
    title3: "Academic degree, title:",
    info3: "Doctor of Biological Sciences (2009), Professor (2019).",
    title4: "Professional activity:",
    info4: [
      "From 2010 to 2012 - Deputy Director of the Institute of Biochemistry of the Academy of Sciences of the Republic of Uzbekistan.",
      "In 2017 - 2018 - Deputy Director of the Institute of Bioorganic Chemistry of the Academy of Sciences of the Republic of Uzbekistan.",
      "From 2018 to present — Director of the Center for Advanced Technologies under the Ministry of Innovative Development of the Republic of Uzbekistan.",
      "From 2020 to present - First Deputy Minister of Innovative Development of the Republic of Uzbekistan.",
    ],
    title5: " Awards, titles and awards:",
    info5: [
      `In 2009, she won the Young Scientists of the Year competition held by TWAS (World Academy of Sciences).`,
      `In 2018, she received the Scopus Award-2018 in the category`,
      `In 2018, she was awarded the State Award of the Republic of Uzbekistan - the Order "Dustlik".`,
    ],
  },
  {
    id: 2,
    img: img2,
    name: "Dalimova Dilbar Akbarovna",
    position:
      "Ilg‘or texnologiyalar markazi direktorining ilmiy ishlar bo‘yicha o‘rinbosari",
    degree: "Biologiya fanlari nomzodi (PhD)",
    number: "+99871 227-43-21 internal (45-01)",
    email: "d.dalimova@cat-science.uz",
    time: "Reception of citizens every Wednesday, Friday from 16.00 to 16.30.",
    btn: "More",
    title1: "Biography",
    info1:
      "Dalimova Dilbar Akbarovna was born on September 4, 1973 in Tashkent.",
    title2: "Education:",
    info2: [
      "Graduated from the Faculty of Biology of Tashkent State University. Mirzo Ulugbek (1995).",
    ],
    title3: "Academic degree, title:",
    info3: "Candidate of Biological Sciences, senior Researcher",
    title4: "Professional activity:",
    info4: [
      "In 2012-2017, he was a senior researcher at the genomics laboratory of the Institute of Bioorganic Chemistry of the Academy of Sciences of the Republic of Uzbekistan.",
      "From 2017 to 2021 - Head of the Biological Laboratory of the state enterprise Educational and Experimental Center for High Technologies.",
      `2018-2022 — Under the Ministry of Innovative Development of the Republic of Uzbekistan
      Head of the Biotechnology Laboratory of the Advanced Technologies Center`,
      `Since 2022 under the Ministry of Innovative Development of the Republic of Uzbekistan
      Deputy Director of the Center for Advanced Technologies for Science
      `,
    ],
    title5: " Awards, titles and awards:",
    info5: [`2nd degree Order "Salomatlik" (2020)`],
  },
  {
    id: 3,
    img: noperson,
    name: "Sevara Abbasovna Karimova",
    position: "Deputy Director for General Affairs",
    number: "+99871 227-43-23 internal (45-20)",
    email: "s.karimova@cat-science.uz",
    time: "Reception of citizens every Tuesday from 14.30 to 15.00.",
    btn: "More",
    title1: "Biography",
    info1: "Sevara Abbasovna Karimova was born in 1978, Tashkent.",
    title2: "Education:",
    info2: [
      "1997-2001 Tashkent Pharmaceutical Institute (bachelor's degree)",
      "2003 Tashkent Pharmaceutical Institute (PhD)",
      "2004-2006 Tashkent Pharmaceutical Institute (master's degree)",
    ],
    title4: "Professional activity:",
    info4: [
      "2009-2010 Russian State Academy of Intellectual Property",
      "2001-2019 Head of Department of the Agency for Intellectual Property under the Ministry of Justice of the Republic of Uzbekistan",
      "2019-2023 Deputy Head of Department of the Ministry of Innovative Development",
      "2023 - present Head of Department of the Agency for Innovative Development under the Ministry of Education, Science and Innovation",
    ],
    title5: " Awards, titles and awards:",
    info5: [
      `For good work in the field of strengthening the rule of law, he was repeatedly encouraged by the Prosecutor General of the Republic of Uzbekistan.`,
      `For the successes achieved in his work, he was awarded a commemorative medal in honor of the Independence of the Republic of Uzbekistan, as well as a Certificate of Honor from the Prime Minister - Chairman of the State Commission for Drug Control.`,
    ],
  },
];

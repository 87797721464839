import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .logo {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(var(--bgColor), rgba(4, 28, 84, 0.05));
    border-radius: 50%;
    img {
      margin: 30px;
      width: 200px;
      height: 200px;
    }
    cursor: pointer;
  }

  .row1,
  .row2,
  .row3 {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .row1,
  .row3 {
    width: 70%;
  }
  .row2 {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left,
    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 70px;
    }
  }

  .box {
    height: 80px;
    width: 100%;
    max-width: 400px;
    background-color: var(--btnColor);
    display: flex;
    align-items: center;
    padding-left: 40px;
    gap: 10px;
    cursor: pointer;
    img {
      height: 40px;
    }
    :hover {
      background-color: rgba(16, 41, 87, 0.4);
      color: var(--hoverFontColor);
    }
  }

  @media (max-width: 800px) {
    .logo {
      width: 150px;
      height: 150px;
      img {
        margin: 10px;
        width: 70px;
        height: 70px;
      }
    }
  }
  @media (max-width: 600px) {
    .logo-wrapper {
      display: none;
    }
    gap: 20px;
    p,
    a,
    div {
      font-size: 14px;
    }
    .logo {
      position: absolute;
      top: 10px;
      display: none;
    }
    .box {
      width: 100%;
    }
    .row1,
    .row2,
    .row3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
    }
    .row2 {
      flex-direction: column;
      justify-content: flex-end;
      .left,
      .right {
        gap: 20px;
      }
    }
  }
`;

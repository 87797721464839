import {
  About,
  Accelerator,
  Adminstration,
  Announcement,
  Announcements,
  BusinessTrips,
  CenterStructure,
  Contact,
  Cooperation,
  Departments,
  Doctoranture,
  Documents,
  FightAgainst,
  GenderEquality,
  Laboratories,
  LaboratoryofBigData,
  LaboratoryofBiotechnology,
  LaboratoryofExperimentalBiophysics,
  LaboratoryofGeophysicsandNanomineralogy,
  LaboratoryofPhysicochemical,
  LaboratoryoftheCellTechnology,
  LaboratoryoftheFudomics,
  MakerSpace,
  MassMediaAboutUs,
  Memorandums,
  New,
  News,
  Photogallery,
  ScientificActivities,
  ScientificCouncil,
  ScientificSecretary,
  StateProgramms,
  UnionCommittee,
  Vacancies,
  Vaccine,
  Videogallery,
  ScientificArticles,
  ScientificDevelopments,
  ScientificDevelopment,
} from "../pages";

export const routes = [
  {
    id: 1,
    title: "About Center",
    path: "about",
    element: <About />,
  },
  {
    id: 3,
    title: "Adminstration",
    path: "adminstration",
    element: <Adminstration />,
  },
  {
    id: 4,
    title: "Announcements",
    path: "announcements",
    element: <Announcements />,
  },
  {
    id: 2,
    title: "Announcement",
    path: "announcement",
    element: <Announcement />,
  },
  {
    id: 222,
    title: "Announcement",
    path: "announcements/:param",
    element: <Announcement />,
  },
  {
    id: 5,
    title: "Business Trips",
    path: "business-trips",
    element: <BusinessTrips />,
  },
  {
    id: 6,
    title: "Center Structure",
    path: "center-structure",
    element: <CenterStructure />,
  },
  {
    id: 7,
    title: "Contact",
    path: "contact",
    element: <Contact />,
  },
  {
    id: 8,
    title: "Cooperation",
    path: "cooperation",
    element: <Cooperation />,
  },
  {
    id: 9,
    title: "Departments",
    path: "departments",
    element: <Departments />,
  },
  {
    id: 10,
    title: "Documents",
    path: "documents",
    element: <Documents />,
  },
  {
    id: 11,
    title: "Fight Against Corruption",
    path: "fight-against-corruption",
    element: <FightAgainst />,
  },
  {
    id: 12,
    title: "Gender Equality",
    path: "gender-equality",
    element: <GenderEquality />,
  },
  {
    id: 13,
    title: "Laboratories",
    path: "laboratories",
    element: <Laboratories />,
  },
  {
    id: 14,
    title: "MakerSpace",
    path: "maker-space",
    element: <MakerSpace />,
  },
  {
    id: 15,
    title: "Mass Media About Us",
    path: "mass-media-about-us",
    element: <MassMediaAboutUs />,
  },

  {
    id: 17,
    title: "Memorandums",
    path: "memorandums",
    element: <Memorandums />,
  },
  {
    id: 18,
    title: "News",
    path: "news",
    element: <News />,
  },
  {
    id: 117,
    title: "New",
    path: "new",
    element: <New />,
  },
  {
    id: 118,
    title: "New",
    path: "news/:param",
    element: <New />,
  },
  {
    id: 19,
    title: "Photogallery",
    path: "photogallery",
    element: <Photogallery />,
  },
  {
    id: 20,
    title: "Scientific Activities",
    path: "scientific-activities",
    element: <ScientificActivities />,
  },
  {
    id: 21,
    title: "Scientific Council",
    path: "scientific-council",
    element: <ScientificCouncil />,
  },
  {
    id: 22,
    title: "Scientific Secretary",
    path: "scientific-secretary",
    element: <ScientificSecretary />,
  },
  {
    id: 23,
    title: "State Programms",
    path: "state-programms",
    element: <StateProgramms />,
  },
  {
    id: 24,
    title: "Union Committee",
    path: "union-committee",
    element: <UnionCommittee />,
  },
  {
    id: 25,
    title: "Vacancies",
    path: "vacancies",
    element: <Vacancies />,
  },
  {
    id: 26,
    title: "Videogallery",
    path: "videogallery",
    element: <Videogallery />,
  },
  {
    id: 27,
    title: "Vaccine",
    path: "vaccine",
    element: <Vaccine />,
  },
  {
    id: 28,
    title: "Doctoranture",
    path: "Doctoranture",
    element: <Doctoranture />,
  },
  {
    id: 29,
    title: "Accelerator",
    path: "accelerator",
    element: <Accelerator />,
  },
  {
    id: 30,
    title: "Laboratory of Cell Technology",
    path: "Laboratory-of-the-Cell-Technology",
    element: <LaboratoryoftheCellTechnology />,
  },
  {
    id: 31,
    title: "Laboratoryof Geophysics and Nanomineralogy",
    path: "Laboratory-of-Geopyhsics-and-Nanomineralogy",
    element: <LaboratoryofGeophysicsandNanomineralogy />,
  },
  {
    id: 32,
    title: "Laboratory of Experimental Biophysics",
    path: "Laboratory-of-Experimental-Biophysics",
    element: <LaboratoryofExperimentalBiophysics />,
  },
  {
    id: 33,
    title: "Laboratory of Biotechnology",
    path: "Laboratory-of-Biotechnology",
    element: <LaboratoryofBiotechnology />,
  },
  {
    id: 34,
    title: "Laboratory of Physicochemical",
    path: "Laboratory-of-Physicochemical",
    element: <LaboratoryofPhysicochemical />,
  },
  {
    id: 35,
    title: "Laboratory of Fudomics",
    path: "Laboratory-of-the-Fudomics",
    element: <LaboratoryoftheFudomics />,
  },
  {
    id: 35,
    title: "Laboratory of Big data",
    path: "Laboratory-of-the-Big-data",
    element: <LaboratoryofBigData />,
  },
  {
    id: 36,
    title: "Scientific Articles",
    path: "scientific-articles",
    element: <ScientificArticles />,
  },
  {
    id: 37,
    title: "Scientific Developments",
    path: "scientific-developments",
    element: <ScientificDevelopments />,
  },
  {
    id: 38,
    title: "Scientific Developments",
    path: "scientific-developments/:param",
    element: <ScientificDevelopment />,
  },
];

import React, { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";
import { councilEng, councilRus, councilUzb } from "../../data/council";
import Employee from "../Employee/Employee";
import { Wrapper } from "./style";

const ScientificSecretaryComp = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb")
    return (
      <Wrapper>
        <Employee data={councilUzb} title="Ilmiy kotib" />
      </Wrapper>
    );
  else if (lang === "rus")
    return (
      <Wrapper>
        <Employee data={councilRus} title="Учённый секретарь" />
      </Wrapper>
    );
  else if (lang === "eng")
    return (
      <Wrapper>
        <Employee data={councilEng} title="Scientific secretary" />
      </Wrapper>
    );
};

export default ScientificSecretaryComp;

import React from "react";

<script language="javascript" type="text/javascript">
  top_js="1.0";top_r="id=47120&r="+escape(document.referrer)+"&pg="+escape(window.location.href);document.cookie="smart_top=1;
  path=/"; top_r+="&c="+(document.cookie?"Y":"N")
</script>;
<script language="javascript1.1" type="text/javascript">
  top_js="1.1";top_r+="&j="+(navigator.javaEnabled()?"Y":"N")
</script>;
<script language="javascript1.2" type="text/javascript">
  top_js="1.2";top_r+="&wh="+screen.width+'x'+screen.height+"&px="+
  (((navigator.appName.substring(0,3)=="Mic"))?screen.colorDepth:screen.pixelDepth)
</script>;
<script language="javascript1.3" type="text/javascript">
  top_js="1.3";
</script>;

const VisitorCounter = () => {
  return (
    <div>
      <a href="http://www.uz/ru/res/visitor/index?id=47120" target="_top">
        <img
          height="31"
          src="https://cnt0.www.uz/counter/collect?id=47120&pg=http%3A//uzinfocom.uz&&col=133E43&amp;t=ffffff&amp;p=E6850F"
          width="88"
          border="0"
          alt="Топ рейтинг www.uz"
        />
      </a>
    </div>
  );
};

export default VisitorCounter;

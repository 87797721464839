import React from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "./style";

const CardForLab = (props) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      {props?.type !== "raqamlashtirish" ? (
        <div
          className="card"
          onClick={() => {
            navigate(`/${props?.path}`);
            window.scrollTo(0, 0);
          }}
        >
          <figure>
            <img className="circle" src={props?.img} alt="x" />
          </figure>
          <p>{props?.title}</p>
        </div>
      ) : (
        <a className="card" href={props?.path}>
          <figure>
            <img src={props?.img} alt="x" />
          </figure>
          <p>{props?.title}</p>
        </a>
      )}
    </Wrapper>
  );
};

export default CardForLab;

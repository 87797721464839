import React from "react";
import SamePages from "../components/SamePages/SamePages";
import img from "../assets/images/doct.jpg";

const Doctoranture = () => {
  return <SamePages data={data}/>;
};

const data = {
  img: img,
  titleUzb: "Doktorantura",
  textUzb: `Oliy ta’limdan keyingi ta’lim — doktorlik dissertatsiyasini tayyorlash va himoya qilish maqsadida mutaxassislikni chuqur o‘rganishni va ilmiy izlanishlar olib borishni nazarda tutadigan tayanch doktorantura, doktorantura va mustaqil izlanuvchanlik asosida ilmiy darajaga ega ilmiy va ilmiy-pedagogik kadrlar tayyorlashni ta’minlaydigan ta’lim turi;
  oliy ta’limdan keyingi ta’lim instituti — ilmiy tashkilot yoki ta’lim muassasalarida tayanch doktorantura, doktorantura yoki mustaqil izlanuvchilik shakllaridagi oliy ta’limdan keyingi ta’limni amalga oshirish tizimi;
  stajyor-tadqiqotchilik — ishlab chiqarishdan ajralgan holda tegishli ixtisoslik bo‘yicha ilmiy va ilmiy-ijodiy izlanishlarni olib borish va falsafa doktori (PhD) ilmiy darajasini olish uchun izlanuvchini tayyorlash shakli;
  tayanch doktorantura — falsafa doktori (Doctor of Philosophy (PhD) ilmiy darajasiga da’vogar izlanuvchilar uchun ishlab chiqarishdan ajralgan holda tashkil etiladigan oliy malakali ilmiy va ilmiy-pedagog kadrlar ixtisosligi bo‘yicha oliy ta’limdan keyingi ta’lim shakli;
  doktorantura — fan doktori (Doctor of Science (DSc) ilmiy darajasiga da’vogar izlanuvchilar uchun ishlab chiqarishdan ajralgan holda tashkil etiladigan oliy malakali ilmiy va ilmiy-pedagog kadrlar ixtisosligi bo‘yicha oliy ta’limdan keyingi ta’lim shakli;
  mustaqil izlanuvchilik — falsafa doktori (Doctor of Philosophy (PhD) yoki fan doktori (Doctor of Science (DSc) ilmiy darajalariga da’vogar izlanuvchilar uchun ishlab chiqarishdan ajralmagan holda tashkil etiladigan oliy malakali ilmiy va ilmiy-pedagog kadrlar ixtisosligi bo‘yicha oliy ta’limdan keyingi ta’lim shakli;
  stajyor-tadqiqotchi — ilmiy tashkilot yoki ta’lim muassasalariga stajyor-tadqiqotchilikka belgilangan tartibda qabul qilingan shaxs;
  doktorant — ilmiy tashkilot yoki ta’lim muassasalariga tayanch doktorantura yoki doktoranturaga belgilangan tartibda qabul qilingan shaxs;
  mustaqil izlanuvchi — ilmiy tashkilot yoki ta’lim muassasalariga mustaqil izlanuvchilikka belgilangan tartibda qabul qilingan shaxs;
  izlanuvchi — falsafa doktori (PhD) yoki fan doktori (DSc) ilmiy darajasini olish uchun doktorlik dissertatsiyasini tayyorlayotgan shaxs.
  O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi tomonidan 2019 yilda oliy ta’limdan keyingi ta’lim tizimida qabul kvotalari bo‘yicha buyurtmalarni shakllantirish, hujjatlarni topshirish va ro‘yxatga olish, faoliyat samaradorligi monitoringini olib borishni Oliy ta’limdan keyingi ta’limni muvofiqlashtirishning yagona elektron tizimi (phd.mininnovation.uz) sinov tariqasida amalga oshirilmoqda.  
  Izlanuvchilarda mavjud muammo va qiyinchiliklarini hal etish maqsadida:
  – vazirlik, idoralar va OTM, ilmiy tashkilotlarning mas’ul xodimlari orasida tezkor axborot almashish va maslahat berib borish maqsadida telegram guruh (Doktorantura online) tashkil qilindi va doimiy maslahatlar berib borish yo‘lga qo‘yildi;
  – Oliy ta’limdan keyingi ta’lim institutiga kirish istagida bo‘lganlar uchun t.me/doktoranturatalabgor hamda tizim yuzasidan taklif va mulohazalarga t.me/test_phd telegram guruhlari tashkil qilindi va doktorantlarning savollariga faol ravishda javoblar berib borilmoqda.
`,
  mainUzb: "",
  titleRus: "Докторантура",
  textRus: `Послевузовское образование — вид непрерывного образования, направленный на обеспечение потребностей общества в научных и научно-педагогических кадрах высшей квалификации;
  институт послевузовского образования — система реализации послевузовского образования в высших образовательных учреждениях (далее — ВОУ) или научно-исследовательских учреждениях (далее — НИУ) в виде базовой докторантуры, докторантуры или самостоятельного соискательства;
  базовая докторантура — форма послевузовского образования по специальности научных и научно-педагогических кадров высшей квалификации, организуемого в ВОУ или НИУ для соискателей ученой степени доктора философии (Doctor of Philosophy, далее — PhD) по углубленному изучению специальности и ведению ими научных изысканий в целях подготовки и защиты докторской диссертации с отрывом от производства, финансируемая за счет средств Государственного бюджета Республики Узбекистан;
  докторантура — форма послевузовского образования по специальности научных и научно-педагогических кадров высшей квалификации, организуемого в ВОУ или НИУ для соискателей ученой степени доктора наук (Doctor of Science, далее — DSc) по углубленному изучению специальности и ведению ими научных изысканий в целях подготовки и защиты докторской диссертации с отрывом от производства, финансируемая за счет средств Государственного бюджета Республики Узбекистан;
  самостоятельное соискательство — форма послевузовского образования по специальности научных и научно-педагогических кадров высшей квалификации, организуемого в ВОУ или НИУ для соискателей ученых степеней доктора философии (PhD) или доктора наук (DSc)по углубленному изучению специальности и ведению ими научных изысканий в целях подготовки и защиты докторской диссертации без отрыва от производства;
  докторант — лицо, зачисленное в установленном порядке в базовую докторантуру или докторантуру ВОУ или НИУ;
  самостоятельный соискатель — лицо, оформленное в установленном порядке в ВОУ или НИУ на самостоятельное соискательство;
  соискатель — лицо, подготавливающее докторскую диссертацию на соискание ученой степени доктора философии (PhD) или доктора наук (DSc).
  В целях решения существующих проблем и трудностей исследователей:
  Министерство инновационного развития Республики Узбекистан с 2019 года осуществляет формирование приказов о квотах на поступление в систему послевузовского образования, регистрацию и оформление документов, мониторинг эффективности деятельности в единой электронной системе координации (phd.mininnovation.uz) послевузовского образования в тестовом режиме;
  Создана телеграмм группа (Doktorantura online) для обеспечения быстрого обмена информацией, а также регулярных консультаций по возникающим вопросам между ответственными лицами министерств, ведомств и университетов, исследовательских организаций;
  Для желающих поступить в институт постдипломного образования организованы телеграмм группы (t.me/doktoranturatalabgor и t.me/test_phd) для предложений и замечаний по работе системы, также для получения ответов на возникающие вопросы у докторантов.
`,
  mainRus: ``,
  titleEng: "Doctorate",
  textEng: `Postgraduate education – a type of continuing education aimed at meeting the needs of society in scientific and scientific-pedagogical personnel of higher qualification;
  Institute of postgraduate education – a system of implementation of postgraduate education in higher educational institutions (hereinafter – HEI) or research institutions (hereinafter – RI) in the form of basic doctoral studies, doctoral studies or self-study;
  Basic doctoral studies – a form of postgraduate education in the specialty of scientific and scientific-pedagogical personnel of higher qualification, organized in HEI or HEI for applicants for the degree of Doctor of Philosophy (hereinafter – PhD) in-depth study of specialty and the conduct of their scientific research in order to prepare and defend a doctoral thesis, while leaving work, funded by the State Budget of the Republic of Uzbekistan;
  Doctoral studies – a form of postgraduate education in the specialty of scientific and scientific-pedagogical personnel of higher qualification, organized in the HEI or HEI for applicants for the degree of Doctor of Science (Doctor of Science, hereinafter – DSc) in-depth study of specialty and the conduct of scientific research in order to prepare and defend a doctoral thesis, while leaving work, funded from the State Budget of the Republic of Uzbekistan;
  Independent study – a form of postgraduate education in the specialty of scientific and scientific-pedagogical personnel of higher qualification, organized in HEI or RI for applicants for the degree of Doctor of Philosophy (PhD) or Doctor of Science (DSc) in-depth study of specialty and the conduct of scientific research in order to prepare and defend a doctoral thesis without interrupting work;
  Doctoral student – a person enrolled in accordance with the established procedure in the basic doctoral or doctoral studies of HEI or RI;
  independent co-applicant – a person registered in the prescribed manner in the HEI or RI for independent co-application;
  applicant – a person preparing a doctoral thesis for the degree of Doctor of Philosophy (PhD) or Doctor of Science (DSc).
  In order to solve existing problems and difficulties of researchers:
  Since 2019, the Ministry of Innovative Development of the Republic of Uzbekistan carries out the formation of orders on quotas for admission to the system of postgraduate education, registration and registration of documents, monitoring of performance in a unified electronic system of coordination (phd.mininnovation.uz) of postgraduate education in the test mode;
  A telegram group (Doktorantura online) was created to ensure the rapid exchange of information, as well as regular consultations on emerging issues between responsible officials of ministries, departments and universities and research organizations;
  For those wishing to enroll in the Institute of Postgraduate Education, there are telegram groups (t.me/doktoranturatalabgor and t.me/test_phd) for suggestions and comments on the system, and for answering questions from doctoral students.
`,
  mainEng: ``,
};
export default Doctoranture;

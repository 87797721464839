import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";
import { Wrapper } from "./style";
import { videos } from "./videos";
const MassmediaaboutusComp = () => {
  const [lang] = useContext(LanguageContext);
  return (
    <Wrapper>
      <h1>
        {lang === "uzb"
          ? "OAV biz haqimizda"
          : lang === "rus"
          ? "СМИ о нас"
          : "Mass media about us"}
      </h1>
      <div className="top">
        <div className="left">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/g6wV5PLVYiY?si=w8CA6ox8BWB9wN__"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div className="right">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/K_L0DD4o4L0?si=fnUo0grNxi5ygesb"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ThpCAk12Uuk?si=SPIGZTZt9oweX_02"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          
          ></iframe>
        </div>
      </div>
      <div className="bottom2">
        {videos.map((value) => {
          return (
            <div className="wrapper" key={value.id}>
              <iframe
                src={value.src}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default MassmediaaboutusComp;

import styled from "styled-components";

export const Container = styled.div`
  .ant-select .ant-select-selector {
    background-color: transparent !important;
  }
  p {
    color: white !important;
  }
  .ant-select-arrow {
    color: white !important;
  }
  @media (max-width: 750px) {
    display: ${({ mobile }) => (mobile ? "block" : "none")};
  }
`;

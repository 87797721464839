import React from "react";
import { Wrapper } from "./style";
import { scientificDevelopments } from "../../data/scientificDevelopments";
import { useNavigate } from "react-router-dom";

const ScientificDevelopmentsComp = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <h3>НАУЧНЫЕ РАЗРАБОТКИ</h3>
      <ul>
        {scientificDevelopments.map((value) => {
          return (
            <li key={value.id}>
              <span
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(`${value.id}`);
                }}
              >
                {value.title}
              </span>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default ScientificDevelopmentsComp;

import { Select } from "antd";
import React from "react";
import { LanguageContext } from "../../../context/languageContext";
import { Container } from "./style";

export const SelectForLaguage = ({mobile}) => {
  const [, setLanguage] = React.useContext(LanguageContext);
  return (
    <Container mobile={mobile}>
      <Select
        value={
          localStorage.getItem("language")
            ? localStorage.getItem("language")
            : "O'zb"
        }
        onChange={(value) => {
          localStorage.setItem("language", value);
          setLanguage(localStorage.getItem("language"));
        }}
        options={[
          {
            label: <p style={{ color: "var(--bgColor)" }}>O'zb</p>,
            value: "uzb",
          },
          {
            label: <p style={{ color: "var(--bgColor)" }}>Рус</p>,
            value: "rus",
          },
          {
            label: <p style={{ color: "var(--bgColor)" }}>Eng</p>,
            value: "eng",
          },
        ]}
      />
    </Container>
  );
};

export default SelectForLaguage;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0;
  @media (max-width: 600px) {
    margin: 20px 0;
  }

  .img-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    background-color: rgba(88, 97, 116, 0.5);
    img {
      width: 95%;
      height: 65vh;
    }
  }
`;

export const usefullinksUzb = [
  {
    id: 1,
    title: "O'zbekiston Respublikasi Milliy yuridik Internet portali",
    path: "https://huquqiyportal.uz/",
    img: "https://mininnovation.uz/media/interactive_services/partner_2.png",
  },
  {
    id: 2,
    title: "Yagona interaktiv davlat xizmatlari portali",
    path: "https://my.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb.png",
  },
  {
    id: 3,
    title: "O'zbekiston Respublikasi ochiq ma'lumotlar portali",
    path: "https://data.egov.uz/uzbKr/organizations/",
    img: "https://mininnovation.uz/media/interactive_services/DAtagov.png",
  },
  {
    id: 4,
    title:
      "O‘zbekiston Respublikasi Qonun hujjatlari ma’lumotlari milliy bazasi",
    path: "https://lex.uz/",
    img: "https://mininnovation.uz/media/interactive_services/LEx.png",
  },
  {
    id: 5,
    title: "O'zbekiston Respublikasi Prezidenti virtual qabulxonasi",
    path: "https://pm.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb_gseXtqJ.png",
  },
  {
    id: 6,
    title: "O‘zbekiston Respublikasi Prezidentining Hukumat portali",
    path: "https://gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb_TLe5I55.png",
  },
  {
    id: 7,
    title: "Yagona interaktiv davlat xizmatlari portali",
    path: "https://my.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/partner_1.png",
  },
  {
    id: 8,
    title: "O'zbekiston Respublikasi ochiq ma'lumotlar portali",
    path: "https://data.egov.uz/uzbKr/organizations/",
    img: "https://mininnovation.uz/media/interactive_services/DAtagov.png",
  },
];
export const usefullinksRus = [
  {
    id: 1,
    title: "Национальный правовой интернет-портал  Республики Узбекистан",
    path: "https://huquqiyportal.uz/",
    img: "https://mininnovation.uz/media/interactive_services/partner_2.png",
  },
  {
    id: 2,
    title: "Единый портал интерактивных государственных услуг",
    path: "https://my.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb.png",
  },
  {
    id: 3,
    title: "Портал открытых данных Республики Узбекистан",
    path: "https://data.egov.uz/uzbKr/organizations/",
    img: "https://mininnovation.uz/media/interactive_services/DAtagov.png",
  },
  {
    id: 4,
    title: "Национальная база данных законодательства Республики Узбекистан",
    path: "https://lex.uz/",
    img: "https://mininnovation.uz/media/interactive_services/LEx.png",
  },
  {
    id: 5,
    title: "Виртуальная приемная Президента Республики Узбекистан",
    path: "https://pm.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb_gseXtqJ.png",
  },
  {
    id: 6,
    title: "Виртуальная приемная Президента Республики Узбекистан",
    path: "https://gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb_TLe5I55.png",
  },
  {
    id: 7,
    title: "Единый портал интерактивных государственных услуг",
    path: "https://my.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/partner_1.png",
  },
  {
    id: 8,
    title: "O'zbekiston Respublikasi ochiq ma'lumotlar portali",
    path: "https://data.egov.uz/uzbKr/organizations/",
    img: "https://mininnovation.uz/media/interactive_services/DAtagov.png",
  },
];
export const usefullinksEng = [
  {
    id: 1,
    title: "National legal Internet portal of the Republic of Uzbekistan",
    path: "https://huquqiyportal.uz/",
    img: "https://mininnovation.uz/media/interactive_services/partner_2.png",
  },
  {
    id: 2,
    title: "The only interactive public services portal",
    path: "https://my.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb.png",
  },
  {
    id: 3,
    title: "Open information portal of the Republic of Uzbekistan",
    path: "https://data.egov.uz/uzbKr/organizations/",
    img: "https://mininnovation.uz/media/interactive_services/DAtagov.png",
  },
  {
    id: 4,
    title:
      "National database of information on legal documents of the Republic of Uzbekistan",
    path: "https://lex.uz/",
    img: "https://mininnovation.uz/media/interactive_services/LEx.png",
  },
  {
    id: 5,
    title: "Virtual reception of the President of the Republic of Uzbekistan",
    path: "https://pm.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb_gseXtqJ.png",
  },
  {
    id: 6,
    title: "Government portal of the Republic of Uzbekistan",
    path: "https://gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/gerb_TLe5I55.png",
  },
  {
    id: 7,
    title: "The only interactive public services portal",
    path: "https://my.gov.uz/",
    img: "https://mininnovation.uz/media/interactive_services/partner_1.png",
  },
  {
    id: 8,
    title: "Open information portal of the Republic of Uzbekistan",
    path: "https://data.egov.uz/uzbKr/organizations/",
    img: "https://mininnovation.uz/media/interactive_services/DAtagov.png",
  },
];

import styled from "styled-components";
import { ReactComponent as x } from "../../assets/icons/x.svg";
export const Wrapper = styled.div`
  margin: 50px 0;
  position: relative;
  @media (max-width: 600px) {
    margin: 20px 0;
  }

  .modal-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
    overflow: hidden;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    img {
      position: absolute;
    }
  }
  .icon-wrapper {
    cursor: pointer;
  }

  .wrapper-images {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  @media (max-width: 800px) {
    .modal-wrapper {
      img {
        height: auto;
        width: 90%;
      }
    }
  }
`;

export const Icon = styled.div``;
Icon.x = styled(x)`
  width: 100%;
  max-width: 30px;
  height: 30px;
  background-color: gray;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  top: 3%;
  right: 5%;
  :hover {
    transform: scale(0.9);
    background-color: var(--hoverFontColor);
  }
`;

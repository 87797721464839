export const memorandumUzb = [
  {
    id: 1,
    title: `O‘zbekiston Milliy universiteti`,
    country: `O'zbekiston`,
    date: `07.09.2018`,
  },
  {
    id: 2,
    title: `Viktoriya texnologiya va konsalting`,
    country: `O'zbekiston`,
    date: `09.09.2018`,
  },
  {
    id: 3,
    title: `UZLITI muhandislik`,
    country: `O'zbekiston`,
    date: `17.09.2018`,
  },
  {
    id: 4,
    title: `Shanxay dorivor moddalar instituti`,
    country: `XXR`,
    date: `07.10.2018`,
  },
  {
    id: 5,
    title: `GadotAgro `,
    country: `Isroil`,
    date: `24.10.2018`,
  },
  {
    id: 6,
    title: `"Belarus Milliy Fanlar Akademiyasining Geofizika monitoringi markazi" davlat muassasasi`,
    country: `Belarus`,
    date: `24.10.2018`,
  },
  {
    id: 7,
    title: `GadotAgro `,
    country: `Isroil`,
    date: `25.10.2018`,
  },
  {
    id: 8,
    title: `IGB&Company bosh direktori `,
    country: `Koreya Respublikasi`,
    date: `25.10.2018`,
  },
  {
    id: 9,
    title: `GroundZero Business Accelerator™`,
    country: `O'zbekiston`,
    date: `25.10.2018`,
  },
  {
    id: 10,
    title: `ANRUz Navoiy filiali`,
    country: `O'zbekiston`,
    date: `25.10.2018`,
  },
  {
    id: 11,
    title: `Belarus Milliy Fanlar Akademiyasining Biologik faol birikmalar biokimyosi instituti`,
    country: `Belarus`,
    date: `29.10.2018`,
  },
  {
    id: 12,
    title: `Markaziy Osiyoning ilmiy-texnikaviy va iqtisodiy axborot markazi XUAR XXR`,
    country: `XXR`,
    date: `14.12.2018`,
  },
  {
    id: 13,
    title: `Toshkent davlat stomatologiya instituti`,
    country: `O'zbekiston`,
    date: `01.01.2019`,
  },
  {
    id: 14,
    title: `O‘zbekiston Liberal-demokratik partiyasi`,
    country: `O'zbekiston`,
    date: `01.01.2019`,
  },
  {
    id: 15,
    title: `Milliy kollej ixtirochilar va ixtirochilar alyansi, d/b/a VentureWell `,
    country: `Italiya`,
    date: `17.01.2019`,
  },
  {
    id: 16,
    title: `Dreyfus Poytaxt `,
    country: `AQSH`,
    date: `30.01.2019`,
  },
  {
    id: 17,
    title: `S.Seyfulina nomidagi Qozog'iston agrotexnika universiteti 
    `,
    country: `Qozog'iston`,
    date: `02.01.2019`,
  },
  {
    id: 18,
    title: `O‘zbek -Yapon markazi`,
    country: `O'zbekiston`,
    date: `12.02.2019 yil`,
  },
  {
    id: 19,
    title: `Shinjon ekologiya va geografiya instituti`,
    country: `XXR`,
    date: `01.04.2019`,
  },
  {
    id: 20,
    title: `"Qozon (Volga viloyati) federal universiteti" Federal davlat avtonom oliy ta'lim muassasasi`,
    country: `Rossiya Federatsiyasi`,
    date: `17.06.2019`,
  },
  {
    id: 21,
    title: `Belarusiya NAS fiziologiya instituti`,
    country: `Belarus`,
    date: `09.08.2019`,
  },
  {
    id: 22,
    title: `Belarusiya Milliy Fanlar akademiyasining Biofizika va hujayra muhandisligi instituti`,
    country: `Belarus`,
    date: `09.08.2019`,
  },
  {
    id: 23,
    title: `Samara viloyat tibbiyot markazi sulolasi`,
    country: `Rossiya Federatsiyasi`,
    date: `28.08.2019`,
  },
  {
    id: 24,
    title: `YEA Engineering , Yerevan`,
    country: `Armaniston`,
    date: `30.10.2019`,
  },
  {
    id: 25,
    title: `LTD Protexservis , Stavropol`,
    country: `Rossiya Federatsiyasi`,
    date: `31.10.2019`,
  },
  {
    id: 26,
    title: `Devx , Gujarat , Hindiston`,
    country: `Hindiston`,
    date: `29.11.2019`,
  },
  {
    id: 27,
    title: `yarataman _ Hindiston`,
    country: `Hindiston`,
    date: `29.11.2019`,
  },
  {
    id: 28,
    title: `Xitoy SUARning tabiiy resurslar va ekologik muhit tadqiqot markazi`,
    country: `XXR`,
    date: `29.11.2019`,
  },
  {
    id: 29,
    title: `Markaziy Osiyoning ilmiy-texnikaviy va iqtisodiy axborot markazi XUAR XXR`,
    country: `XXR`,
    date: `10.12.2019`,
  },
  {
    id: 30,
    title: `Islom Karimov nomidagi Toshkent davlat texnika universiteti qoshidagi “Yoshlar uchun Oʻzbekiston -Yaponiya innovatsiyalar markazi” davlat muassasasi`,
    country: `O'zbekiston`,
    date: `20.03.2020`,
  },
  {
    id: 31,
    title: `Shanxay fan va texnologiya almashinuv markazi o'rtasida`,
    country: `XXR`,
    date: `01.08.2020`,
  },
  {
    id: 32,
    title: `O'rmon huzhaliga ilmiy-tadqiqot instituti`,
    country: `O'zbekiston`,
    date: `24.02.2021`,
  },
  {
    id: 33,
    title: `Belarus Milliy Fanlar akademiyasining Genetika va sitologiya instituti`,
    country: `Belarus`,
    date: ``,
  },
  {
    id: 34,
    title: `Xitoy Xalq Respublikasining ilmiy-texnik axborot markazi`,
    country: `XXR`,
    date: ``,
  },
  {
    id: 35,
    title: `O'zbekiston milliy universiteti`,
    country: `O'zbekiston`,
    date: ``,
  },
  {
    id: 36,
    title: `Xorazm manum akademiyasi`,
    country: `O'zbekiston`,
    date: ``,
  },
  {
    id: 37,
    title: `Urganch G.U`,
    country: `O'zbekiston`,
    date: ``,
  },
  {
    id: 38,
    title: `Toshkent pediatriya tibbiyot instituti`,
    country: `O'zbekiston`,
    date: ``,
  },
  {
    id: 39,
    title: `Respublika shoshilinch tibbiy yordam ilmiy markazi`,
    country: `O'zbekiston`,
    date: ``,
  },
];
export const memorandumEng = [
  {
    id: 1,
    title: `National University of Uzbekistan`,
    country: `Uzbekistan`,
    date: `07.09.2018`,
  },
  {
    id: 2,
    title: `Victoria technology and consulting`,
    country: `Uzbekistan`,
    date: `09.09.2018`,
  },
  {
    id: 3,
    title: `UZLITI Engineering`,
    country: `Uzbekistan`,
    date: `09/17/2018`,
  },
  {
    id: 4,
    title: `Shanghai Institute of Medicinal Substances`,
    country: `PRC`,
    date: `07.10.2018`,
  },
  {
    id: 5,
    title: `GadotAgro `,
    country: `Israel`,
    date: `24.10.2018`,
  },
  {
    id: 6,
    title: `State Institution "Center for Geophysical Monitoring of the National Academy of Sciences of Belarus"`,
    country: `Belarus`,
    date: `24.10.2018`,
  },
  {
    id: 7,
    title: `GadotAgro `,
    country: `Israel`,
    date: `25.10.2018`,
  },
  {
    id: 8,
    title: `CEO IGB&Company `,
    country: `The Republic of Korea`,
    date: `25.10.2018`,
  },
  {
    id: 9,
    title: `GroundZero Business Accelerator™`,
    country: `Uzbekistan`,
    date: `25.10.2018`,
  },
  {
    id: 10,
    title: `Navoi branch of ANRUz`,
    country: `Uzbekistan`,
    date: `25.10.2018`,
  },
  {
    id: 11,
    title: `Institute of Biochemistry of Biologically Active Compounds of the National Academy of Sciences of Belarus`,
    country: `Belarus`,
    date: `29.10.2018`,
  },
  {
    id: 12,
    title: `Center for Scientific, Technical and Economic Information of Central Asia XUAR PRC`,
    country: `PRC`,
    date: `12/14/2018`,
  },
  {
    id: 13,
    title: `Tashkent State Dental Institute`,
    country: `Uzbekistan`,
    date: `01.01.2019`,
  },
  {
    id: 14,
    title: `Liberal Democratic Party of Uzbekistan`,
    country: `Uzbekistan`,
    date: `01.01.2019`,
  },
  {
    id: 15,
    title: `National Collegiate Inventors and Innovators Alliance, d/b/a VentureWell `,
    country: `Italy`,
    date: `01/17/2019`,
  },
  {
    id: 16,
    title: `Dreyfus Capital `,
    country: `USA`,
    date: `01/30/2019`,
  },
  {
    id: 17,
    title: `Kazakh Agrotechnical University named after S. Seifulina`,
    country: `Kazakhstan`,
    date: `02/01/2019`,
  },
  {
    id: 18,
    title: `Uzbek -Japanese Center`,
    country: `Uzbekistan`,
    date: `12.02.2019`,
  },
  {
    id: 19,
    title: `Xinjiang Institute of Ecology and Geography, Chinese Academy of Sciences`,
    country: `PRC`,
    date: `04/01/2019`,
  },
  {
    id: 20,
    title: `Federal State Autonomous Educational Institution of Higher Education "Kazan (Volga Region) Federal University"`,
    country: `Russian Federation`,
    date: `06/17/2019`,
  },
  {
    id: 21,
    title: `Institute of Physiology NAS of Belarus`,
    country: `Belarus`,
    date: `09.08.2019`,
  },
  {
    id: 22,
    title: `Institute of Biophysics and Cell Engineering of the National Academy of Sciences of Belarus`,
    country: `Belarus`,
    date: `09.08.2019`,
  },
  {
    id: 23,
    title: `Samara Regional Medical Center Dynasty`,
    country: `Russian Federation`,
    date: `08/28/2019`,
  },
  {
    id: 24,
    title: `YEA Engineering , Yerevan`,
    country: `Armenia`,
    date: `30.10.2019`,
  },
  {
    id: 25,
    title: `LTD Protexservis , Stavropol`,
    country: `Russian Federation`,
    date: `31.10.2019`,
  },
  {
    id: 26,
    title: `Devx , Gujarat , India`,
    country: `India`,
    date: `11/29/2019`,
  },
  {
    id: 28,
    title: `I create India`,
    country: `India`,
    date: `11/29/2019`,
  },
  {
    id: 29,
    title: `Research Center for Natural Resources and Ecological Environment of the XUAR of China`,
    country: `PRC`,
    date: `11/29/2019`,
  },
  {
    id: 30,
    title: `State Public Institution “ Uzbek -Japanese Innovation Center for Youth” at the Tashkent State Technical University named after Islam Karimov`,
    country: `Uzbekistan`,
    date: `03/20/2020`,
  },
  {
    id: 31,
    title: `Between Shanghai Science and Technology Exchange Center`,
    country: `PRC`,
    date: `08/01/2020`,
  },
  {
    id: 32,
    title: `Ormon huzhaliga ilmiy-tadqiqot institutes`,
    country: `Uzbekistan`,
    date: `24.02.2021`,
  },
  {
    id: 33,
    title: `Institute of Genetics and Cytology of the National Academy of Sciences of Belarus`,
    country: `Belarus`,
    date: ``,
  },
  {
    id: 34,
    title: `Center of Scientific and Technical Information of the People's Republic of China`,
    country: `PRC`,
    date: ``,
  },
  {
    id: 35,
    title: `NUUz`,
    country: `Uzbekistan`,
    date: ``,
  },
  {
    id: 36,
    title: `Khorezm Manum Academy`,
    country: `Uzbekistan`,
    date: ``,
  },
  {
    id: 37,
    title: `Urgench G.U`,
    country: `Uzbekistan`,
    date: ``,
  },
  {
    id: 38,
    title: `Tashkent Pediatric Medical Institute`,
    country: `Uzbekistan`,
    date: ``,
  },
  {
    id: 39,
    title: `Republican Scientific Center for Emergency Medical Care`,
    country: `Uzbekistan`,
    date: ``,
  },
];
export const memorandumRus = [
  {
    id: 1,
    title: `Национальный Университет Узбекистан`,
    country: `Узбекистан`,
    date: `07.09.2018`,
  },
  {
    id: 2,
    title: `Victoria Technology and Consulting`,
    country: `Узбекистан`,
    date: `09.09.2018`,
  },
  {
    id: 3,
    title: `UZLITI Engineering`,
    country: `Узбекистан`,
    date: `17.09.2018`,
  },
  {
    id: 4,
    title: `Шанхайский институт лекарственных веществ`,
    country: `КНР`,
    date: `07.10.2018`,
  },
  {
    id: 5,
    title: `GadotAgro `,
    country: `Израиль`,
    date: `24.10.2018`,
  },
  {
    id: 6,
    title: `Государственное учреждение «Центр геофизического мониторинга Национальной академии наук Беларуси»`,
    country: `Беларусь`,
    date: `24.10.2018`,
  },
  {
    id: 7,
    title: `GadotAgro `,
    country: `Израиль`,
    date: `25.10.2018`,
  },
  {
    id: 8,
    title: `CEO IGB&Company `,
    country: `Республика Корея`,
    date: `25.10.2018`,
  },
  {
    id: 9,
    title: `GroundZero Акселератор Бизнеса™`,
    country: `Узбекистан`,
    date: `25.10.2018`,
  },
  {
    id: 10,
    title: `Навоийское отделение АНРУз`,
    country: `Узбекистан`,
    date: `25.10.2018`,
  },
  {
    id: 11,
    title: `Институт биохимии биологически активных соединений НАН Беларуси`,
    country: `Беларусь`,
    date: `29.10.2018`,
  },
  {
    id: 12,
    title: `Центр научно-технической и экономической информации Центральной Азии СУАР КНР`,
    country: `КНР`,
    date: `14.12.2018`,
  },
  {
    id: 13,
    title: `Ташкентский государственный стоматологический институт`,
    country: `Узбекистан`,
    date: `01.01.2019`,
  },
  {
    id: 14,
    title: `Либерально-демократической партией Узбекистан`,
    country: `Узбекистан`,
    date: `01.01.2019`,
  },
  {
    id: 15,
    title: `National Collegiate Inventors and Innovators Alliance, d/b/a VentureWell `,
    country: `Италия`,
    date: `17.01.2019`,
  },
  {
    id: 16,
    title: `Dreyfus Capital `,
    country: `США`,
    date: `30.01.2019`,
  },
  {
    id: 17,
    title: `Казахтанский агротехнический университет им. С.Сейфулина`,
    country: `Казахстан`,
    date: `01.02.2019`,
  },
  {
    id: 18,
    title: `Узбекско-Японский Центр`,
    country: `Узбекистан`,
    date: `12.02.2019`,
  },
  {
    id: 19,
    title: `Синьцзянский институт экологии и географии Академии наук Китая`,
    country: `КНР`,
    date: `01.04.2019`,
  },
  {
    id: 20,
    title: ``,
    country: ``,
    date: ``,
  },
  {
    id: 21,
    title: `Институт Физиологии НАН Беларуси`,
    country: `Беларусь`,
    date: `09.08.2019`,
  },
  {
    id: 22,
    title: `Институт биофизики и клеточной инженерии НАН Беларуси`,
    country: `Беларусь`,
    date: `09.08.2019`,
  },
  {
    id: 23,
    title: `Самарский областной медицинский центр Династия`,
    country: `Российская Федерация`,
    date: `28.08.2019`,
  },
  {
    id: 24,
    title: `YEA Engineering, Ереван`,
    country: `Армения`,
    date: `30.10.2019`,
  },
  {
    id: 25,
    title: `LTD Protexservis, Ставрополь`,
    country: `Российская Федерация`,
    date: `31.10.2019`,
  },
  {
    id: 26,
    title: `Devx, Gujarat, India`,
    country: `Индия`,
    date: `29.11.2019`,
  },
  {
    id: 27,
    title: `I create India`,
    country: `Индия`,
    date: `29.11.2019`,
  },
  {
    id: 28,
    title: `Исследовательский центр природных ресурсов и экологический среды СУАР КНР`,
    country: `КНР`,
    date: `29.11.2019`,
  },
  {
    id: 29,
    title: `Центр научно-технической и экономической информации Центральной Азии СУАР КНР`,
    country: `КНР`,
    date: `10.12.2019`,
  },
  {
    id: 30,
    title: `ГПУ “Узбекско-Японский молодежный центр инновации» при Ташкентском государственном техническом университете имени Ислам Каримова`,
    country: `Узбекистан`,
    date: `20.03.2020`,
  },
  {
    id: 31,
    title: `Между Шанхайским центром обмена наукой и технологии`,
    country: `КНР`,
    date: `01.08.2020`,
  },
  {
    id: 32,
    title: `Ўрмон хўжалиги илмий-тадқиқот институти`,
    country: `Узбекистан`,
    date: `24.02.2021`,
  },
  {
    id: 33,
    title: `Институт генетики и цитологии Национальной Академии наук Беларуси`,
    country: `Беларусь`,
    date: ``,
  },
  {
    id: 34,
    title: `Центр Научно-Технической информации КНР`,
    country: `КНР `,
    date: ``,
  },
  {
    id: 35,
    title: `НУУз`,
    country: `Узбекистан`,
    date: ``,
  },
  {
    id: 36,
    title: `Хорезмская академия Манума`,
    country: `Узбекистан`,
    date: ``,
  },
  {
    id: 37,
    title: `Ургенчский Г.У`,
    country: `Узбекистан`,
    date: ``,
  },
  {
    id: 38,
    title: `Ташкентский Педиатрический Медицинский Институт`,
    country: `Узбекистан`,
    date: ``,
  },
  {
    id: 39,
    title: `Республиканский научным центром экстренной медицинской помощи`,
    country: `Узбекистан`,
    date: ``,
  },
];

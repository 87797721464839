import React from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/languageContext";
import { Section } from "./style";
import vaz_mah_qarorlar from "../../assets/images/vaz_mah_qarorlar.jpg"

const DocumentsComp = () => {
  const navigate = useNavigate();
  const [lang] = React.useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Section>
        <a
          className="card"
          href="https://lex.uz/docs/-20596?ONDATE=09.02.2021%2001"
          target={"_blank"}
        >
          <img
            src="https://mitc.uz/media/363321b0-5d18-3e50-f64b-a4cac846ca9c.jpg"
            alt="#"
          />
          <p>O’zbekiston Respublikasi Konstitutsiyasi </p>
        </a>
        <a
          className="card"
          target={"_blank"}
          href="https://president.uz/oz/site/document/2?menu_id=144"
        >
          <img
            src="https://www.uzbekistonmet.uz/uploads/12fdd89d-2c48-f77f-1696-63f834f29af4_lists_176.jpg"
            alt="#"
          />
          <p>O’zbekiston Respublikasi Prezidentining qarorlari </p>
        </a>
        <a
          className="card"
          target={"_blank"}
          href="https://mehnat.uz/oz/documents/ozbekiston-respublikasi-vazirlar-mahkamasining-qarorlari"
        >
          <img
            src={vaz_mah_qarorlar}
            alt="#"
          />
          <p>O’zbekiston Respublikasi Vazirlar Mahkamasining qarorlari </p>
        </a>
      </Section>
    );
  }else if(lang==="rus") {
    return (
      <Section>
        <a
          className="card"
          href="https://lex.uz/docs/-20596?ONDATE=09.02.2021%2001"
          target={"_blank"}
        >
          <img
            src="https://mitc.uz/media/363321b0-5d18-3e50-f64b-a4cac846ca9c.jpg"
            alt="#"
          />
          <p>Конституция Республики Узбекистан</p>
        </a>
        <a
          className="card"
          target={"_blank"}
          href="https://president.uz/oz/site/document/2?menu_id=144"
        >
          <img
            src="https://www.uzbekistonmet.uz/uploads/12fdd89d-2c48-f77f-1696-63f834f29af4_lists_176.jpg"
            alt="#"
          />
          <p>Решения Президента Республики Узбекистан</p>
        </a>
        <a
          className="card"
          target={"_blank"}
          href="https://mehnat.uz/oz/documents/ozbekiston-respublikasi-vazirlar-mahkamasining-qarorlari"
        >
          <img
            src={vaz_mah_qarorlar}
            alt="#"
          />
          <p>Постановления Кабинета Министров Республики Узбекистан</p>
        </a>
      </Section>
    );
  }
  else if(lang==="eng") {
    return (
      <Section>
        <a
          className="card"
          href="https://lex.uz/docs/-20596?ONDATE=09.02.2021%2001"
          target={"_blank"}
        >
          <img
            src="https://mitc.uz/media/363321b0-5d18-3e50-f64b-a4cac846ca9c.jpg"
            alt="#"
          />
          <p>Constitution of the Republic of Uzbekistan </p>
        </a>
        <a
          className="card"
          target={"_blank"}
          href="https://president.uz/oz/site/document/2?menu_id=144"
        >
          <img
            src="https://www.uzbekistonmet.uz/uploads/12fdd89d-2c48-f77f-1696-63f834f29af4_lists_176.jpg"
            alt="#"
          />
          <p>Decisions of the President of the Republic of Uzbekistan </p>
        </a>
        <a
          className="card"
          target={"_blank"}
          href="https://mehnat.uz/oz/documents/ozbekiston-respublikasi-vazirlar-mahkamasining-qarorlari"
        >
          <img
            src={vaz_mah_qarorlar}
            alt="#"
          />
          <p>
            Decisions of the Cabinet of Ministers of the Republic of Uzbekistan{" "}
          </p>
        </a>
      </Section>
    );
  }
};

export default DocumentsComp;

import React from "react";
import { Wrapper } from "./style";
import { useParams } from "react-router-dom";
import { scientificDevelopments } from "../../data/scientificDevelopments";

const ScientificDevelopmentComp = () => {
  const params = useParams().param;

  const development = scientificDevelopments[params - 1];
  return (
    <Wrapper>
      <h3>{development.title}</h3>
      {development.images.map((img, index) => {
        return (
          <div className="image-wrapper" key={index}>
            <img src={img} alt="123" />
          </div>
        );
      })}
      <p>{development?.text1}</p>
      <p>{development?.text2}</p>
    </Wrapper>
  );
};

export default ScientificDevelopmentComp;

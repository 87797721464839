import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
  @media (max-width: 750px) {
    p{
      font-size: 14px;
    }
    h1, h2, h3{
      font-size: 16px;
    }
  }
`;

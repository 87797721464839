import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  padding: 20px;
  background-color: var(--cardColor);
  .image {
    width: 100%;
  }
  .content-image {
    max-width: 700px;
  }
  .content {
    h3 {
      text-align: center;
    }

    h3:first-child {
      margin-top: 20px;
    }
  }

  .hero {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
  }

  .logo-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    .logo-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--hoverFontColor);
      border-radius: 10px;
      padding: 20px 10px;
      .logo {
        border-radius: 10px;
      }
    }

    @media (max-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr 1fr;
      .logo {
        max-width: 250px;
      }
    }
    @media (max-width: 420px) {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      .logo-wrapper {
        width: 100%;
        .logo {
          width: 50%;
        }
      }
    }
  }

  iframe {
    width: 100%;
    height: 700px;
    @media (max-width: 600px) {
      height: 200px;
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    margin: 20px 0;
  }
`;

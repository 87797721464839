import { useContext } from "react";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../../context/languageContext";
import { newsEng, newsRu, newsUz } from "../../data/news";
import { Wrapper } from "./style";

const Newcomp = () => {
  const params = useParams();
  const infoUz = newsUz[params?.param];
  const infoRus = newsRu[params?.param];
  const infoEng = newsEng[params?.param];
  const [lang] = useContext(LanguageContext);

  if (lang === "uzb") {
    return (
      <Wrapper>
        <div className="img-wrapper">
          <img src={infoUz.img} alt="" />
        </div>
        <h3>{infoUz.title}</h3>
        <p>{infoUz.text}</p>
        <p>{infoUz?.main}</p>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <div className="img-wrapper">
          <img src={infoUz.img} alt="" />
        </div>
        <h3>{infoRus.title}</h3>
        <p>{infoRus.text}</p>
        <p>{infoRus?.main}</p>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <div className="img-wrapper">
          <img src={infoUz.img} alt="" />
        </div>
        <h3>{infoEng.title}</h3>
        <p>{infoEng.text}</p>
        <p>{infoEng?.main}</p>
      </Wrapper>
    );
  }
};

export default Newcomp;

import React, { useContext } from "react";
import { Wrapper } from "./style";
import { LanguageContext } from "../../context/languageContext";

//content images
import mainImg from "../../assets/images/laboratories/geophysics/1.jpg";
import image1 from "../../assets/images/laboratories/geophysics/6.jpg";
import image2 from "../../assets/images/laboratories/geophysics/3.jpg";
import image3 from "../../assets/images/laboratories/geophysics//4.jpg";
import image4 from "../../assets/images/laboratories/geophysics/7.jpg";

// logos of companies
import logo1 from "../../assets/images/laboratories/geophysics/hamkorlik//1.png";
import logo2 from "../../assets/images/laboratories/geophysics/hamkorlik//2.png";
import logo3 from "../../assets/images/laboratories/geophysics/hamkorlik//3.png";
import logo4 from "../../assets/images/laboratories/geophysics/hamkorlik//4.png";
import logo5 from "../../assets/images/laboratories/geophysics/hamkorlik//5.png";
import logo6 from "../../assets/images/laboratories/geophysics/hamkorlik//6.png";
import logo7 from "../../assets/images/laboratories/geophysics/hamkorlik//7.png";
import logo8 from "../../assets/images/laboratories/geophysics/hamkorlik//8.png";
import logo9 from "../../assets/images/laboratories/geophysics/hamkorlik//9.png";
import logo10 from "../../assets/images/laboratories/geophysics/hamkorlik//10.png";
import logo11 from "../../assets/images/laboratories/geophysics/hamkorlik//11.png";
import logo12 from "../../assets/images/laboratories/geophysics/hamkorlik//12.png";
import logo13 from "../../assets/images/laboratories/geophysics/hamkorlik//13.png";
import logo14 from "../../assets/images/laboratories/geophysics/hamkorlik//14.png";

// images of equipments
import equipment1 from "../../assets/images/laboratories/geophysics/uskunalar/1.jpg";
import equipment2 from "../../assets/images/laboratories/geophysics/uskunalar/2.jpg";
import equipment3 from "../../assets/images/laboratories/geophysics/uskunalar/3.png";
import equipment4 from "../../assets/images/laboratories/geophysics/uskunalar/4.jpg";
import equipment5 from "../../assets/images/laboratories/geophysics/uskunalar/5.jpg";
import equipment6 from "../../assets/images/laboratories/geophysics/uskunalar/6.png";

const LaboratoryofGeophysicsandNanomineralogyComp = () => {
  const [language] = useContext(LanguageContext);
  if (language === "uzb") return uzbek;
  else if (language === "rus") return russian;
  else if (language === "eng") return english;
};

export default LaboratoryofGeophysicsandNanomineralogyComp;
const uzbek = (
  <Wrapper>
    <div className="heading">
      <h1>GEOFIZIKA VA NANOMINERALOGIYA LABORATORIYASI</h1>
    </div>

    <div className="content">
      <h3>LABORATORIYA XODIMLARI</h3>
      <p>
        Laboratoriya xodimlari ilmiy va amaliy loyihalarda ko’p yillik tajribaga
        ega. Laboratoriya faoliyatida 17 nafar xodim, jumladan, 2 nafar fan
        doktori (DSc) va 3 nafar fan nomzodi (PhD) ishtirok etmoqda. Bakalavr va
        magistratura bosqichida tahsil olayotgan talabalar ham laboratoriya
        faoliyatiga jalb qilingan. 40 yoshgacha bo'lgan xodimlar soni 12 kishi.
      </p>
      <img src={mainImg} alt="" className="image main-image" />

      {/* ILMIY FAOLIYAT YO'NALISHLARI */}
      <h3>ILMIY FAOLIYAT YO'NALISHLARI</h3>
      <p>
        Laboratoriyaning maqsadi geologiya sohasining ilmiy va innovatsion
        faoliyatini rivojlantirish uchun shart-sharoitlar yaratish, shuningdek,
        laboratoriya bazasining ilmiy va innovatsion salohiyatidan samarali
        foydalanish orqali fan, ta’lim va ishlab chiqarish integratsiyasini
        ta’minlashdan, jumladan ilg’or innovatsion g’oyalar, texnologiyalar va
        loyihalarni ishlab chiqish va amalga oshirishdan iborat.
      </p>
      <div className="hero">
        <img src={image1} alt="" className="image content-image" />
        <img src={image2} alt="" className="image content-image" />
      </div>

      <h3>LABORATORIYA FAOLIYATI</h3>
      <p>
        Labaratoriyada fundamental, amaliy va inovasion tadqiqotlar olib boorish
        bilan birga, geologiyaning turli muammolarini xal qilish bo’yicha
        tadqiqotlar olib boriladi. Ayni payitda fan, ta’lim va ishlab chiqarish
        integrasiyasini ta’minlash orqali olimlar va tadqiqotchilarni
        xamkorlikka jalb etish uchun zarur shart-sharoit yaratilmoqda. Olit
        ta’lim, fan va sano’at bilan hamkorlikning samarali shakillari yo’lga
        qo’yilgan, qo’shma ilmiy tadqiqotlar olib borilmoqda.
      </p>

      {/* CONTENT2 IMAGES */}
      <div className="hero">
        <img src={image3} alt="" className="image content-image" />
        <img src={image4} alt="" className="image content-image" />
      </div>

      {/* LOYIHALAR */}
      <h3>LOYIHALAR</h3>
      <p>
        <span>
          *REP-04032022/209 “IMONS - O‘zbekiston hududidagi seysmik jarayonlarni
          kompleks kuzatish tarmog‘ini takomillashtirish”.
        </span>
        <br /> Loyihaning asosiy maqsadi Yer qobig’idagi jarayonlarni, birinchi
        navbatda, geodinamik harakatlarni monitoring qilish uchun milliy
        tarmoqni yaratishdir. Yangi tuzilgan tarmoq G‘arbiy Tyan-Shanning
        alohida bloklari va plitalarining zamonaviy harakatlarini mintaqaviy
        darajada o‘rganishdagi bo‘shliqni deformatsiya ma’lumotlaridan
        foydalanish orqali to‘ldiradi.
      </p>
      <p>
        <span>
          *IL-5221091401 “Bino va inshoatlarning seysmik ta’siriga qarshilik
          tanqisligini baholash uchun apparat dasturiy kompleks yaratish”.
        </span>
        <br /> Loyhaning asosiy g’oyasi: bino va inshoatlarning seysmik
        chidamliligi baholash uchun maxalliy apparat-dasturiy kompleksini
        yaratishdan iborat. Apparat-dasturiy kompleks ixchamligi, mahalliy
        sharoitga moslashuvi va xorijiy analoglarga nisbatan arzonligi bilan
        ajralib turadi.
      </p>
      <p>
        <span>
          *ALМ-202109013 “Arxeologik yodigorliklarni tadqiq etishda inovasion
          geofizik, biologik, fizik-kimyoviy texnologiyalarni
          qo’llash(Dalvarzintepa, Qoratepa, Xayrobodtepa va Loylagan
          mozor-qo’rgoni misolida)”
        </span>
        <br /> Loyhaning asosiy g’oyasi: tadqiqot obyekti tuproq qatlami bilan
        yashiringan arxeologik obyektlar va geofizik maydonlar o’rtasidagi
        aloqalarni aniqlashdir. Tadqiqotni maqsadi – arxeologik yodigorliklarni
        o’rganishda geofizik, biologik, fizik-kimyoviy usullarni qo’llash,
        tekshirilayotgan obyektlarning tarixiy ma’lumotlarini tiklashdir.
      </p>
      <p>
        <span>
          *PZ-202008288 “Geologiya yo’nalishida ta’lim olayotgan talabalarga
          amaliy mashg’ulotlarni o’tkazish uchun geofizika fanidan raqamli
          dasturlar majmu’asini yaratish”
        </span>
        <br /> Loyhaning asosiy g’oyasi: Talabalar tomonidan geologik muhitda
        fizik maydonlarning tarqalish jarayonini ko’rgazmali tasvirlash uchun
        geofizik usullar bo’yicha labaratoriya va amaliy ishlar majmu’asini
        ishlab chiqish.
      </p>
      <p>
        <span>
          *REP-24112021/70 “O’zbekiston yashil iqtisodiyoti uchun kam uglerodli
          texnologiyalarni resurslar bilan ta’minlash”.
        </span>
        <br /> Loyihaning asosiy maqsadi Oʻzbekistonning yashil iqtisodiyoti
        uchun mineral resurslarni baholash va xaritalarini baholashga yordam
        beruvchi maxsus innovatsion geomaʼlumotlar bazasi va ArcGIS
        platformasini yaratishdan iborat.
      </p>
      <p>
        <span>
          *AL-21091412 «O'zbekistonning intruzivlari (karbongacha): nodir va
          rangli metallar rudasining nanomineralogik va geokimyoviy mezonlari
          bo'yicha istiqbolli hududlarni aniqlash”.
        </span>
        <br /> Loyihaning asosiy maqsadi Oʻzbekistondagi (karbondangacha)
        intruziyalarning qoʻshimcha minerallashuvini MGN usullaridan foydalangan
        holda oʻrganish, ularning anʼanaviy va noanʼanaviy minerallashuvining
        hosil boʻlishidagi rolini aniqlashdan iborat.
      </p>

      {/* HAMKORLIK */}
      <h3>HAMKORLIK</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p> “O‘zGASHKLITI” MChJ</p>
          <img src={logo1} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> X.M.Abdullayev nomidagi “Geologiya va geofizika instituti”</p>
          <img src={logo2} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>G.A.Mavlonova nomidagi Seysmologiya instituti</p>
          <img src={logo3} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> San'at tarixi instituti</p>
          <img src={logo4} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Mirzo Ulug‘bek nomidagi O‘zbekiston Milliy universiteti</p>
          <img src={logo5} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Islom Karimov nomidagi Toshkent davlat texnika universiteti</p>
          <img src={logo6} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            I.M.Gubkin nomidagi Rossiya davlat neft va gaz universiteti,
            Toshkent filiali.
          </p>
          <img src={logo7} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>M.V.Lomonosov nomidagi Moskva davlat universiteti.</p>
          <img src={logo8} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Belarusiya Milliy fanlar akademiyasining Geofizik monitoring markazi
            davlat muassasasi
          </p>
          <img src={logo9} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Yer xaqidagi fanlar tadqiqot markazi, Potsdam</p>
          <img src={logo10} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>Yevropa geofiziklar va muhandislar uyushmasi</p>
          <img src={logo11} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>Xalqaro ruda konlar genezisi assotsiatsiyasi</p>
          <img src={logo12} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>Xalqaro mineralogiya assotsiatsiyasi</p>
          <img src={logo13} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>Iqtisodiy geologlar jamiyati</p>
          <img src={logo14} alt="" className="logo" />
        </div>
      </div>

      {/* USKUNALAR */}
      <h3>USKUNALAR</h3>

      <div className="equipment-section">
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment1} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">Geometrics G proton magnitometri – 856AX.</p>
            <p>
              Model G-856AX portativ proton magnitometri (Amerikaning Geometrics
              kompaniyasi tomonidan ishlab chiqarilgan) o'rnatilgan raqamli
              xotiraga va 0,1 nT sezgirligiga ega. Magnit maydon
              ko'rsatkichlarini, kunning vaqtini, sanani, stantsiya raqamini va
              ichki RAM xotirasida satr raqamini saqlash. Raqamli displeyga va
              RS-232 porti orqali keyingi ishlov berish uchun ma'lumotlarni
              kompyuterga chiqarish. Tizim, shuningdek, magnitometr tayanch
              stantsiyasi sifatida foydalanish uchun avtomatik rejimda o'qishni
              olish uchun o'rnatilishi mumkin.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment2} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              Ko'p kanalli raqamli seysmik tizim (48 kanal) X610-S
            </p>
            <p>
              MAE X610-S (Italiyadan kelib chiqqan) 24-bitli seysmik stansiya
              oʻzining keyingi avlod maʼlumotlarni yigʻish platformasi va
              qidiruv tizimiga ega foydalanuvchilarga qulay operatsion tizim
              bilan ajralib turadi. Seysmik stansiyaning funktsiyalari seysmik
              tadqiqot turiga bog'liq bo'lib, uni tanlash oddiygina katta
              monitorning rangli sensorli kraniga tegish orqali amalga
              oshiriladi. Ushbu seriyadagi seysmik stantsiyalarning asosiy
              xususiyati shundaki, har bir alohida kanal 24 bitli ruxsatni
              ta'minlaydi.Bu natijaga seysmik stansiyaning har bir kirish
              kanalida analogdan raqamliga oʻzgartirgich (SST texnologiyasi)
              bilan jihozlangan yangi 24-bitli MAE maʼlumotlar yigʻish
              kartasidan foydalanish tufayli erishildi. Ushbu arxitektura X610-S
              ni barcha turdagi faol va passiv seysmik tadqiqotlar uchun,
              shuningdek, binolar va infratuzilmaning konstruktiv va holatini
              tekshirish uchun ideal qiladi (akselerometrlar yoki past
              chastotali seysmik qabul qiluvchilar yordamida tebranish
              ma'lumotlarini yig'ish, tomografik tadqiqotlar va
              boshqalar).Geofonlar: 4,5 Gts chastotali vertikal va
              gorizontal.Seysmik qo'zg'alish manbai 8 kg og'irlikdagi balyozdir.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment3} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">MAE C313-SEV elektr qidiruv uskunalari</p>
            <p>
              Ushbu to'rt elektrodli stantsiya qarshilikni, o'z-o'zidan
              polarizatsiya potentsialini, polarizatsiyani, induktsiyalangan
              polarizatsiyani (IP) yoki VESni (vertikal elektr sensori) o'lchash
              uchun mo'ljallangan.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment4} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              V8 Feniks Ko'p funksiyali qabul qiluvchi Magnetotellurik va audio
              magnitotellurik usullar
            </p>
            <p>
              V8 hisoblagichlari Feniks tomonidan ishlab chiqarilgan eng yangi
              avlod uskunalari bo'lib, dunyoda ishlab chiqarilgan boshqalarga
              nisbatan bir qator afzalliklarga ega. Elektr qidiruv
              uskunalarining VR modellari:
            </p>
            <p>
              - ro'yxatga olishning kengroq chastota diapazoni, ro'yxatga olish
              bir vaqtning o'zida barcha kanallarda amalga oshiriladi;
            </p>
            <p>- ro'yxatga olishning yuqori ishonchliligi va aniqligi;</p>
            <p>- aholi punktlari yaqinida ishlarni bajarish qobiliyati;</p>
            <p>- ish haroratining keng diapazoni, -40 dan +500S gacha;</p>
            <p>
              - kichik o'lchamlari va engil vazni (7 kg), avtonom elektr
              ta'minoti transport uchun qulay va ularni ko'chma (elka) versiyada
              ishlatishga imkon beradi va har qanday masshtabdagi va kirish
              qiyin bo'lgan hududlarni MT/AMT bilan qoplash imkonini beradi.
              seysmik va boshqa elektr qidiruv usullari, shu bilan ko'proq
              ma'lumotli va yuqori sifatli dala materiallarini olish;
            </p>
            <p>
              - masofadan qat'iy nazar hisoblagichlarni sun'iy yo'ldosh orqali
              sinxronlashtirish, o'lchovlarning ishonchliligi va aniqligini
              ta'minlash;
            </p>
            <p>
              - ishning barcha bosqichlarida dasturiy ta'minotning to'liqligi,
              ro'yxatga olish va qayta ishlashdan tortib 2D va 3D geoelektrik
              uchastkalarini olishgacha.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment5} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              Kanadada ishlab chiqarilgan PulseEkko PRO yerga kiruvchi radar 25,
              50, 100 va 250 MGts antennalar to'plami bilan.
            </p>
            <p>Ovoz berish chuqurligi 0,1-30 m.</p>
            <p>Georadarlar turli sohalarda keng qo'llanilishini topdilar:</p>
            <p>
              Geologiya - geologik uchastkalarni qurish, yer osti suvlari
              sathining holatini, muz qalinligini, daryo va ko'llar tubining
              chuqurligi va profilini, karerlarda foydali qazilmalarning
              tarqalish chegaralarini, karst chuqurliklari va bo'shliqlarining
              holatini aniqlash.
            </p>
            <p>
              Transport qurilishi - yo'l qoplamasining konstruktiv qatlamlari
              qalinligini va yo'l qurilish materiallarining siqilish sifatini
              aniqlash, transport inshootlari uchun poydevorlarni tahlil qilish,
              tuproq massalari va yo'l inshootlarida muzlash chuqurligini
              aniqlash.
            </p>
            <p>
              Sanoat va fuqarolik qurilishi - beton konstruksiyalarning
              (ko'priklar, binolar va boshqalar) holatini, to'g'on va
              to'g'onlarning holatini aniqlash, ko'chki zonalarini aniqlash,
              muhandislik tarmoqlarini (metall va plastmassa quvurlar, kabellar
              va boshqa kommunal xizmatlar) joylashtirish.
            </p>
            <p>
              Atrof-muhitni muhofaza qilish - tuproqning ifloslanishini
              baholash, neft va suv quvurlari va ekologik xavfli chiqindilar
              ko'miladigan joylardan sizib chiqishni aniqlash.
            </p>
            <p>
              Arxeologiya - arxeologik ob'ektlar va ularning tarqalish
              chegaralarini topish.
            </p>
            <p>
              Huquqni muhofaza qilish organlari georadarlardan keshlar va
              dafnlarni qidirish va aniqlash, shuningdek, alohida muhofaza
              qilinadigan ob'ektlarga tunnellarni aniqlash bilan bog'liq turli
              xil turdagi ishlarni bajarish uchun foydalanishi mumkin; bir
              jinsli yuklarda kontrabanda predmetlarini aniqlash; kon
              maydonchalari, er osti tunnellari, kommunikatsiyalar, omborlar,
              uskunalarning joylashishini aniqlash.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment6} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">Seysmometr TROMINO</p>
            <p>Seysmik tadqiqot usullarini qo'llash doirasi:</p>
            <p>-ustki qobiqning strukturaviy tadqiqi;</p>
            <p>-yer osti suvlari darajasini aniqlash;</p>
            <p>
              -shaharlarni, chiziqli yo'nalishlarni va qurilish maydonchalarini
              seysmik rayonlashtirish;
            </p>
            <p>
              -uglevodorod konlari va ruda va metall bo'lmagan foydali
              qazilmalarni qazib olish joylarining seysmik monitoringi;
            </p>
            <p>
              -to'g'on va dambalarning seysmik ta'sirlarga, shuningdek, inshoot
              va binolarning dinamik ta'sirlarga munosabatini o'rganish va
              kuchli zilzilalar paytida ularning xatti-harakatlarini bashorat
              qilish.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);
/////////////////////////////////////////////////////////////////////////////////////////////////
const russian = (
  <Wrapper>
    <div className="heading">
      <h1>ЛАБОРАТОРИЯ ГЕОФИЗИКИ И НАНОМИНЕРАЛОГИИ</h1>
    </div>

    <div className="content">
      <h3>Сотрудники лаборатории</h3>
      <p>
        Сотрудники лаборатории имеют многолетний опыт реализации научных и
        практических проектов. В деятельности лаборатории принимают участие 17
        сотрудников из них 2 доктора наук (DSc) и 3 кандидата наук (PhD). Также
        участвуют студенты обучающиеся в бакалавриате или магистратуре.
        Численность сотрудников в возрасте до 40 лет составляет 12 человек.
      </p>
      <img src={mainImg} alt="" className="image main-image" />

      {/* ILMIY FAOLIYAT YO'NALISHLARI */}
      <h3>Направления научной деятельности</h3>
      <p>
        * Цель лаборатории – создание условий для развития научной и
        инновационной деятельности в области геологии, а также обеспечение
        интеграции науки, образования и производства за счет эффективного
        использования научного и инновационного потенциала лабораторной базы,
        включая передовые инновационные идеи, технологии и проекты.
      </p>
      <div className="hero">
        <img src={image1} alt="" className="image content-image" />
        <img src={image2} alt="" className="image content-image" />
      </div>

      <h3>ЛАБОРАТОРНАЯ ДЕЯТЕЛЬНОСТЬ</h3>
      <p>
        Labaratoriyada fundamental, amaliy va inovasion tadqiqotlar olib boorish
        bilan birga, geologiyaning turli muammolarini xal qilish bo’yicha
        tadqiqotlar olib boriladi. Ayni payitda fan, ta’lim va ishlab chiqarish
        integrasiyasini ta’minlash orqali olimlar va tadqiqotchilarni
        xamkorlikka jalb etish uchun zarur shart-sharoit yaratilmoqda. Olit
        ta’lim, fan va sano’at bilan hamkorlikning samarali shakillari yo’lga
        qo’yilgan, qo’shma ilmiy tadqiqotlar olib borilmoqda.
      </p>

      {/* CONTENT2 IMAGES */}
      <div className="hero">
        <img src={image3} alt="" className="image content-image" />
        <img src={image4} alt="" className="image content-image" />
      </div>

      {/* LOYIHALAR */}
      <h3>Проекты</h3>
      <p>
        <span>
          *REP-04032022/209 «Совершенствование комплексной сети мониторинга
          сейсмических процессов на территории Узбекистана».
        </span>
        <br />
        Основная цель проекта – создание национальной сети мониторинга земных
        процессов, в первую очередь геодинамических. В результате проекта будет
        создана сеть мониторинга земных процессов. Новая сеть восполнит пробел в
        использовании данных о деформациях при изучении региональных современных
        движений отдельных блоков и плит Западного Тянь-Шаня.
      </p>
      <p>
        <span>
          *IL-5221091401 «Создание программно-аппаратного комплекса для оценки
          недостаточной устойчивости зданий и сооружений к сейсмическому
          воздействию».
        </span>
        <br />
        Основная идея проекта – разработка локального аппаратно-программного
        комплекса для оценки сейсмостойкости зданий и сооружений.
        Аппаратно-программный комплекс будет характеризоваться компкатностью,
        адаптацией к местным условиям и невысокой стоимостью по сравнению с
        зарубежными аналогами.
      </p>
      <p>
        <span>
          *ALМ-202109013 «Применение инновационных геофизических, биологических,
          физико-химических технологий при изучении археологических памятников
          (на примере курганов Далварзинтепа, Каратепа, Хайрабодтепа и
          Лойлагана).
        </span>
        <br /> Основная идея проекта: Цель исследования – определить взаимосвязь
        между археологическими объектами и геофизическими полями, скрытыми под
        слоем почвы. Цель исследования – использование геофизических,
        биологических, физических и химических методов при изучении
        археологических памятников для восстановления исторических данных
        изучаемых объектов.
      </p>
      <p>
        <span>
          *PZ-202008288 «Создание цифрового программного комплекса для
          проведения практических занятий по дисциплине геофизика для студентов,
          обучающихся по геологическому направлению».
        </span>
        <br /> Основная идея проекта: разработать комплекс
        лабораторно-практических занятий по геофизическим методам для студентов,
        позволяющих визуализировать процессы распространения физических полей в
        геологических средах.
      </p>
      <p>
        <span>
          *REP-24112021/70 Основная идея проекта: разработать комплекс
          лабораторно-практических занятий по геофизическим методам для
          студентов, позволяющих визуализировать процессы распространения
          физических полей в геологических средах.
        </span>
        <br /> Основной целью проекта является создание специальной
        инновационной базы геоданных и платформы ArcGIS, помогающей проводить
        оценку полезных ископаемых и карт представляющих интерес в зеленой
        экономике Узбекистана
      </p>
      <p>
        <span>
          *AL-21091412 «Интрузивы Узбекистана (до карбона): выделение
          перспективных участков по наноминералогическим и геохимическим
          критериям на рудоносность благородных и цветных металлов».
        </span>
        <br /> Целью проекта является изучение акцессорной минерализации
        докарбоновых интрузий в Узбекистане с использованием методов МГН для
        определения их роли в формировании традиционной и нетрадиционной
        минерализации.
      </p>

      {/* HAMKORLIK */}
      <h3>Сотрудничество</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p> “O‘zGASHKLITI” OOO</p>
          <img src={logo1} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            "Institute of Geology and Geophysics" named after Kh.M. Abdullayev
          </p>
          <img src={logo2} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>Институт сейсмологии имени Г.А. Мавлянова</p>
          <img src={logo3} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Институт искусствознания</p>
          <img src={logo4} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Национальный университет Узбекистана имени Мирзо Улугбека</p>
          <img src={logo5} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            {" "}
            Ташкентский государственный технический университет имени Ислама
            Каримова
          </p>
          <img src={logo6} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Филиал Российского Государственного Университета нефти и газа имени
            И.М. Губкина в Ташкенте
          </p>
          <img src={logo7} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Московский государственный университет имени М.В. Ломоносова</p>
          <img src={logo8} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Государственное учреждение центр геофизического мониторинга
            Национальной академии наук Беларуси
          </p>
          <img src={logo9} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Немецкий исследовательский центр наук о Земле, Потсдам</p>
          <img src={logo10} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Европейская ассоциация геоучёных и инженеров</p>
          <img src={logo11} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Международная ассоциация генезиса рудных месторождений</p>
          <img src={logo12} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Международная минералогическая ассоциация</p>
          <img src={logo13} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Общество экономических геологов</p>
          <img src={logo14} alt="" className="logo" />
        </div>
      </div>

      {/* USKUNALAR */}
      <h3>ОБОРУДОВАНИЕ</h3>

      <div className="equipment-section">
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment1} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">Протонный магнитометр Geometrics G – 856AX.</p>
            <p>
              Модель G-856AX портативный протонный магнитометр (производства
              американской компании Geometrics) с встроенной цифровой памятью и
              чувствительностью 0,1 нТл. Сохранение показаний магнитного поля,
              время суток, дата, номер станции и номер строки во внутренней
              памяти RAM. Вывод данных на цифровой дисплей, и через RS-232 порт
              к компьютеру для последующей обработки. Система также может быть
              установлена для снятия показаний в автоматическом режиме для
              использования в качестве базовой станции магнитометра.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment2} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              Многоканальная цифровая сейсморазведочная система (48 канальная)
              X610-S
            </p>
            <p>
              24-битная сейсмостанция MAE X610-S (Итальянского происхождения),
              предназначенная для сейсмических исследований, выделяется
              благодаря своей платформе сбора данных нового поколения,
              объединенной с удобной для пользователя операционной системой,
              имеющую структуру меню с возможностью поиска. Функции
              сейсмостанции зависят от вида сейсмического исследования, выбор
              которого осуществляется простым касанием цветного сенсорного крана
              монитора большого размера. Основная характеристика сейсмостанций
              той серии– то, что каждый отдельный канал обеспечивает 24-битное
              разрешение. Такого результата удалось достичь благодаря
              использованию на каждом входном канале сейсмостанции новой
              24-битной карты сбора данных MAE, снабженной аналогово-цифровым
              преобразователем (технология SST). Такая архитектура делает
              сейсмостанцию X610-S идеальной для всех типов активной и пассивной
              сейсмической разведки, а также для структурных исследований и
              проверки состояния зданий, и сооружений инфраструктуры (сбор
              данных по вибрации при помощи акселерометров или низкочастотных
              сейсмоприемников, томографические исследования, и т.д.). Геофоны:
              вертикальные и горизонтальные с частотностью 4,5 Гц.. Источник
              сейсмического возбуждения – кувалда на 8кг.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment3} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">Электроразведочная аппаратура MAE C313-SEV</p>
            <p>
              Данная четырех электродная станция предназначена для измерения
              сопротивления, измерения потенциала самопроизвольной поляризации,
              поляризуемости, вызванной поляризации (IP) или ВЭЗ (Вертикальное
              электрическое зондирование).
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment4} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              V8 Phoenix Многофункциональный приемник Магнитотеллурический и
              аудио магнитотеллурические методы
            </p>
            <p>
              Измерители V8 являются последним поколением выпускаемой Феникс
              аппаратурой и обладают рядом достоинств по сравнению с другими
              выпускаемыми в н. вр моделями электроразведочной аппаратуры:
            </p>
            <p>
              -более широким частотным диапазоном регистрации, при этом
              регистрация производится одновременно на всех каналах;
            </p>
            <p>- высокая надежность и точность регистрации;</p>
            <p>- возможность проводить работы вблизи населённых пунктов;</p>
            <p>- широкий диапазон рабочих температур, от -40 до +500С;</p>
            <p>
              - малогабаритность и лёгкий вес (7 кг), автономное питание удобны
              при транспортировке и позволяют использовать их в переносном
              (наплечном) варианте и покрывать МТ/АМТ-съёмками любого масштаба
              труднодоступные для сейсморазведки и др. методов электроразведки
              районы и участки, получая при этом более информативный и
              качественный полевой материал;
            </p>
            <p>
              - спутниковая синхронизация измерителей, независимо от расстояния,
              обеспечивающая надежность и точность измерений;
            </p>
            <p>
              - завершенность программного обеспечения на всех этапах работ, от
              регистрации и обработки до получения геоэлектрических разрезов 2Д
              и 3Д.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment5} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              Георадар PulseEkko PRO канадского производства с набором антенн
              25, 50, 100 и 250 МГц.
            </p>
            <p>Глубина зондирования 0.1-30м.</p>
            <p>Георадары нашли широкое применение в различных сферах:</p>
            <p>
              Геология - построение геологических разрезов, определение
              положения уровня грунтовых вод, толщины льда, глубины и профиля
              дна рек и озёр, границ распространения полезных ископаемых в
              карьерах, положения карстовых воронок и пустот.
            </p>
            <p>
              Транспортное строительство - определение толщины конструктивных
              слоёв дорожной одежды и качества уплотнения дорожно-строительных
              материалов, анализ оснований под транспортные сооружения,
              определения глубины промерзания в грунтовых массивах и дорожных
              конструкциях.
            </p>
            <p>
              Промышленное и гражданское строительство - определение состояния
              бетонных конструкций (мостов, зданий и т.д.), состояния дамб и
              плотин, выявления оползневых зон, месторасположения инженерных
              сетей (металлических и пластиковых труб, кабелей и других объектов
              коммунального хозяйства).
            </p>
            <p>
              Охрана окружающей среды - для оценки загрязнения почв, обнаружения
              утечек из нефте- и водопроводов, мест захоронения экологически
              опасных отходов.
            </p>
            <p>
              Археология - для нахождения археологических объектов и границы их
              распространения.
            </p>
            <p>
              Силовые структуры могут использовать георадары для выполнения
              разного рода задач связанных с поиском и обнаружением тайников и
              захоронений, а также выявления подкопов к особо охраняемым
              объектам; для обнаружения контрабандных вложений в гомогенных
              однородных грузах; для обнаружения мест заложения мин,
              расположения подземных тоннелей, коммуникаций, складов, техники.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment6} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">Сейсмометр TROMINO</p>
            <p>Область применения сейсмических методов исследований:</p>
            <p>-структурное изучение верхней части земной коры;</p>
            <p>-выявление уровня грунтовых вод;</p>
            <p>
              -сейсмическое районирование городов, линейных трасс и площадок для
              строительства;
            </p>
            <p>
              -сейсмический мониторинг месторождений углеводородов и объектов
              добычи рудных и нерудных ископаемых;
            </p>
            <p>
              -сейсмический мониторинг месторождений углеводородов и объектов
              добычи рудных и нерудных ископаемых.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);
const english = (
  <Wrapper>
    <div className="heading">
      <h1>LABORATORY OF GEOPHYSICS AND NANOMINERALOGY</h1>
    </div>

    <div className="content">
      <h3>Laboratory staff</h3>
      <p>
        * The staff of the laboratory have many years of experience in
        scientific and practical projects. In total, 17 employees take part in
        the activities of the laboratory, including 2 Doctors of Science (DSc)
        and 3 Candidates of Science (PhD). Bachelor's and master's degrees
        students also involving to the laboratory works. The number of employees
        under the age of 40 is 12 people.
      </p>
      <img src={mainImg} alt="" className="image main-image" />

      {/* ILMIY FAOLIYAT YO'NALISHLARI */}
      <h3>Areas of scientific activity</h3>
      <p>
        * The goal of the laboratory is to create conditions for the development
        of scientific and innovative activities in the field of geology, as well
        as to ensure the integration of science, education and production
        through the effective use of the scientific and innovative potential of
        the laboratory base, including advanced innovative ideas, technologies
        and projects.
      </p>
      <div className="hero">
        <img src={image1} alt="" className="image content-image" />
        <img src={image2} alt="" className="image content-image" />
      </div>

      <h3>LABORATORY ACTIVITY</h3>
      <p>
        * The laboratory carries out fundamental, applied and innovative
        research, as well as research to solve various problems of geology. At
        the moment, the necessary conditions are being created to attract
        leading scientists and researchers to cooperation by ensuring the
        integration of science, education and production. Effective forms of
        cooperation with academic, educational and industrial institutions have
        been established, and joint scientific researches being carried out.
      </p>

      {/* CONTENT2 IMAGES */}
      <div className="hero">
        <img src={image3} alt="" className="image content-image" />
        <img src={image4} alt="" className="image content-image" />
      </div>

      {/* LOYIHALAR */}
      <h3>Projects</h3>
      <p>
        <span>
          * REP-04032022/209 «Enhancement of an integrated monitoring network of
          seismic processes on the territory of Uzbekistan».
        </span>
        <br />
        The main goal of the project is to create a national network for Earth
        processes monitoring, primarily on geodynamic processes. As a result of
        the project, a network for Earth processes monitoring will be created.
        The new network will fill the gap in the use of deformation data in the
        study of regional modern movements of individual blocks and plates of
        the Western Tien Shan. 6.
      </p>
      <p>
        <span>
          * IL-5221091401 "Creation of a software and hardware complex for
          assessing the lack of resistance to seismic effects of buildings and
          structures."
        </span>
        <br /> The main idea of the project is the development of a local
        hardware and software complex for assessing the seismic resistance of
        buildings and structures. The hardware and software complex will be
        characterized by compactness, adaptation to local conditions and low
        cost compared to foreign analogues.
      </p>
      <p>
        <span>
          * ALМ-202109013 “Application of innovative geophysical, biological,
          physical and chemical technologies in the study of archaeological
          sites (on the example of Dalvarzintepa, Karatepa, Khairabodtepa and
          Loylagan barrows).
        </span>
        <br /> The main idea of the project: The object of the study is to
        determine the relationship between archaeological sites and geophysical
        fields hidden under the soil layer. The purpose of the study is the use
        of geophysical, biological, physical and chemical methods in the study
        of archaeological sites to restore the historical data of the objects
        under study.
      </p>
      <p>
        <span>
          * PZ-202008288 "Creating a complex of digital programming in
          geophysics to conduct practical training for students studying
          geology".
        </span>
        <br /> The main idea of the project: to develop a set of laboratory and
        practical classes on geophysical methods for students to visualize the
        processes of propagation of physical fields in the geological media.
      </p>
      <p>
        <span>
          * REP-24112021/70 "Resourcing low-carbon technologies for green
          economy of Uzbekistan»
        </span>
        <br /> The main goal of the project is to create a special innovative
        geodatabase and ArcGIS platform that helps assess the mineral resources
        and maps of interest in the green economy of Uzbekistan.
      </p>
      <p>
        <span>
          * AL-21091412 “Intrusives of Uzbekistan (up to Carboniferous): the
          allocation of promising areas by nanomineralogical and geochemical
          criteria for the ore content of noble and base metals”
        </span>
        <br /> The goal of the project is to study the accessory mineralization
        of (pre-Carboniferous) intrusions in Uzbekistan using MGN methods to
        determine their role in the formation of conventional and unconventional
        mineralization.
      </p>

      {/* HAMKORLIK */}
      <h3>Cooperation</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p> “O‘zGASHKLITI” JSC</p>
          <img src={logo1} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            {" "}
            “Institute of Geology and Geophysics” named after Kh.M. Abdullayev
          </p>
          <img src={logo2} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Institute of Seismology named after G.A. Mavlyanova</p>
          <img src={logo3} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Institute of Art History</p>
          <img src={logo4} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> National University of Uzbekistan named after Mirzo Ulugbek</p>
          <img src={logo5} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Tashkent State Technical University named after Islam Karimov</p>
          <img src={logo6} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Branch of the Russian State University of Oil and Gas (National
            Research University) named after I.M. Gubkin in Tashkent
          </p>
          <img src={logo7} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Moscow State University named after M.V. Lomonosov</p>
          <img src={logo8} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            State institution Center for Geophysical Monitoring of the National
            Academy of Sciences of Belarus
          </p>
          <img src={logo9} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> German Research Centre for Geosciences, Potsdam</p>
          <img src={logo10} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> European Association of Geoscientists and Engineers</p>
          <img src={logo11} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> International Ore Deposit Genesis Association</p>
          <img src={logo12} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> International Mineralogical Association</p>
          <img src={logo13} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p> Society of Economic Geologists</p>
          <img src={logo14} alt="" className="logo" />
        </div>
      </div>

      {/* USKUNALAR */}
      <h3>EQUIPMENT</h3>

      <div className="equipment-section">
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment1} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">Geometrics G proton magnetometer – 856AX.</p>
            <p>
              Model G-856AX portable proton magnetometer (Geometrics of America
              manufactured by the company) built-in digital has a memory and a
              sensitivity of 0.1 nT. Magnetic field indicators, time of day,
              date, station number and store line number in internal RAM memory.
              To the digital display and Data for further processing through the
              RS-232 port output to computer. The system is also a magnetometer
              base Read in automatic mode to use as a station can be set to get
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment2} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              Multi-channel digital seismic system (48 channels) X610-S
            </p>
            <p>
              MAE X610-S (made in Italy) 24-bit seismic station its
              next-generation data collection platform and a user-friendly
              operating system with a search engine is distinguished by The
              functions of the seismic station are seismic Depending on the type
              of research, the choice is simply huge done by touching the color
              touch screen of the monitor is increased. The main seismic
              stations in this series feature is that each individual channel
              has 24-bit resolution provides. Each input of the seismic station
              to this result analog-to-digital converter (SST technology) on the
              channel equipped with a new 24-bit MAE data acquisition achieved
              by using the card. This architecture is X610-S for all types of
              active and passive seismic surveys, as well as structural and
              condition of buildings and infrastructure makes it ideal for
              testing (accelerometers or low vibration using frequency seismic
              receivers data collection, tomographic studies and
              others).Geophones: 4.5 Hz vertical and horizontal. The source of
              seismic excitation is a sledgehammer weighing 8 kg.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment3} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">MAE C313-SEV electric search equipment</p>
            <p>
              This four-electrode station measures the resistance, by itself
              polarization potential, polarization, induced measuring
              polarization (IP) or VES (vertical electric sensor). intended for.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment4} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              V8 Phoenix Multifunction receiver Magnetotelluric and audio
              magnetotelluric methods
            </p>
            <p>
              The V8 counters are the latest from Phoenix generation equipment,
              to others produced in the world has a number of advantages.
              Electric search VR models of equipment:
            </p>
            <p>
              - a wider frequency range of registration, registration is carried
              out simultaneously on all channels;
            </p>
            <p>- high reliability and accuracy of registration;</p>
            <p>- the ability to perform work near settlements;</p>
            <p>- a wide range of operating temperatures, from -40 to +500С;</p>
            <p>
              - small size and light weight (7 kg), autonomous electric The
              supply is convenient for transport and in a portable (shoulder)
              version allows you to use and any scale and access allows covering
              difficult areas with MT/AMT. seismic and other electrical
              exploration methods, and more obtaining informative and
              high-quality field materials;
            </p>
            <p>
              - meters regardless of distance via satellite synchronization,
              reliability and accuracy of measurements provide;
            </p>
            <p>
              - the completeness of the software at all stages of work, from
              registration and processing to 2D and 3D geoelectrics until
              getting plots.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment5} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">
              Canadian-made PulseEkko PRO ground-penetrating radar 25, With a
              set of 50, 100 and 250 MHz antennas.
            </p>
            <p>The sounding depth is 0.1-30 m.</p>
            <p>Georadars have found wide application in various fields:</p>
            <p>
              Geology - construction of geological sections, underground water
              the state of the surface, the thickness of ice, the bottom of
              rivers and lakes depth and profile of minerals in quarries
              distribution boundaries, karst depressions and cavities determine
              the status.
            </p>
            <p>
              Transport construction - structural layers of the road surface
              thickness and compression quality of road construction materials
              determination, analysis of foundations for transport facilities,
              depth of freezing in soil masses and road structures
              determination.
            </p>
            <p>
              Industrial and civil construction - concrete structures (bridges,
              buildings, etc.) condition, dam and determining the condition of
              dams, identifying landslide zones, engineering networks (metal and
              plastic pipes, cables and other utilities) deployment.
            </p>
            <p>
              Environmental protection - soil pollution assessment, oil and
              water pipelines and environmentally hazardous waste detection of
              leakage from burial sites.
            </p>
            <p>
              Archeology - archaeological objects and their distribution find
              the limits.
            </p>
            <p>
              Law enforcement agencies use georadar caches and search and
              identification of burials, as well as special protection various
              related to the identification of tunnels to objects can be used to
              perform different types of work; one identification of contraband
              items in sexual cargo; mine platforms, underground tunnels,
              communications, warehouses, determining the location of the
              equipment.
            </p>
          </div>
        </div>
        <div className="equipment-wrapper">
          <div className="img-wrapper">
            <img src={equipment6} alt="" className="equipment" />
          </div>
          <div className="equipment-content">
            <p className="title">Seismometer TROMINO</p>
            <p>Scope of application of seismic research methods:</p>
            <p>- structural research of the upper crust;</p>
            <p>- structural research of the upper crust;</p>
            <p>
              - cities, linear routes and construction sites seismic zoning;
            </p>
            <p>
              -hydrocarbon deposits and ores and non-metallic minerals seismic
              monitoring of mineral extraction sites;
            </p>
            <p>
              -seismic effects of dams and dams, as well as structures and the
              study of the response of buildings to dynamic effects and predict
              their behavior during strong earthquakes to do
            </p>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;

  .heading {
    margin-bottom: 20px;
  }
  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    row-gap: 20px;
  }
  p {
  }
  @media (max-width: 750px) {
    h1 {
      font-size: 16px;
    }
  }
  @media (max-width: 600px) {
    margin-top: 20px;
    margin-bottom: 60px;
    .content {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
`;

import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/languageContext";
import { LaboratoriesData, LaboratoriesData2, LaboratoriesData3 } from "../../utils/laboratories";
import CardForLab from "./CardForLab/CardForLab";
import { Wrapper } from "./style";

const LaboratoriesComp = ({home}) => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Wrapper>
        <h1>Laboratoriyalar</h1>
        <div className="cards-wrapper">
          {LaboratoriesData2?.map((value) => {
            return (
              <CardForLab
                title={value?.title}
                path={value?.path}
                key={value?.id}
                img={value?.img}
              />
            );
          })}
        </div>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <h1>Лаборатории</h1>
        <div className="cards-wrapper">
        {LaboratoriesData3?.map((value) => {
            return (
              <CardForLab
                title={value?.title}
                path={value?.path}
                img={value?.img}
                key={value?.id}
              />
            );
          })}
        </div>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <h1>Laboratories</h1>
        <div className="cards-wrapper">
        {LaboratoriesData?.map((value) => {
            return (
              <CardForLab
                title={value?.title}
                path={value?.path}
                key={value?.id}
                img={value?.img}
              />
            );
          })}
        </div>
      </Wrapper>
    );
  }
};

export default LaboratoriesComp;

import React from "react";
import { Wrapper } from "./style";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper";
const Laboratory = ({ data, title }) => {
  return (
    <Wrapper>
      <h1>{title}</h1>
      <Swiper
        slidesPerView={1}
        modules={[Navigation, Autoplay, Pagination]}
        navigation={true}
        loop={true}
        autoplay={true}
        speed={600}
      >
        {data?.img?.map((value, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={value} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <h3>{data?.title1}</h3>
      <p>{data?.text1}</p>
      <h3>{data?.title2}</h3>
      <p>{data?.text2}</p>
      <h3>{data?.title3}</h3>
      <p>{data?.text3}</p>
      <h3>{data?.title4}</h3>
      <p>{data?.text4}</p>
      <h3>{data?.title5}</h3>
      <p>{data?.text5}</p>
      <h3>{data?.title6}</h3>
      <p>{data?.text6}</p>
      <h3>{data?.title7}</h3>
      <p>{data?.text7}</p>
      <h3>{data?.title8}</h3>
      <p>{data?.text8}</p>
      <h3>{data?.title9}</h3>
      <p>{data?.text9}</p>
      <h3>{data?.title10}</h3>
      <p>{data?.text10}</p>
      <h3>{data?.title11}</h3>
      <p>{data?.text11}</p>
      <h3>{data?.title12}</h3>
      <p>{data?.text12}</p>
      <h3>{data?.title13}</h3>
      <p>{data?.text13}</p>
      <h3>{data?.title14}</h3>
      <p>{data?.text14}</p>
      <h3>{data?.title15}</h3>
      <p>{data?.text15}</p>
      <h3>{data?.title16}</h3>
      <p>{data?.text16}</p>
      <h3>{data?.title17}</h3>
      <p>{data?.text17}</p>
      <h3>{data?.title18}</h3>
      <p>{data?.text18}</p>
      <h3>{data?.title19}</h3>
      <p>{data?.text19}</p>
      <h3>{data?.title20}</h3>
      <p>{data?.text20}</p>
      <h3>{data?.title21}</h3>
      <p>{data?.text21}</p>
      <h3>{data?.title22}</h3>
      <p>{data?.text22}</p>
      <h3>{data?.title23}</h3>
      <p>{data?.text23}</p>
    </Wrapper>
  );
};

export default Laboratory;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  justify-content: center;
  margin: 50px 0;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
  img {
    width: 100%;
  }
`;

export const scientficCouncilUzb = [
  {
    id: 1,
    name: "Turdikulova Sh.U. (rais)",
    position: "Biologiya fanlari doktori, professor",
  },
  {
    id: 2,
    name: "Dalimova D.A. (rais o’rinbosari)",
    position: "b.f.n., katta ilmiy xodim",
  },
  {
    id: 3,
    name: `Levitskaya Yu.V. ( ilmiy kotib) 
`,
    position: "b.f.n., katta ilmiy xodim",
  },
  {
    id: 4,
    name: "Sobirov R.Z.",
    position: "Biologiya fanlari doktori, akademik",
  },
  {
    id: 5,
    name: "Abdullaev A.A.",
    position: "Biologiya fanlari doktori, katta ilmiy xodim",
  },
  {
    id: 6,
    name: "Charishnikova O.S.",
    position: "PhD b.n., katta ilmiy xodim",
  },
  {
    id: 7,
    name: "Nazarov X.T.",
    position: "f.-m.f.n., katta ilmiy xodim",
  },
  {
    id: 8,
    name: "Alimuhamedov I.M.",
    position: "g.-m.f.n., katta ilmiy xodim",
  },
  {
    id: 9,
    name: "Xalmatov R.A.",
    position: "g.-m.f.d.",
  },
  {
    id: 10,
    name: "Ibragimova E.M.",
    position: "f.-m.f.d.",
  },
  {
    id: 11,
    name: "Hakimov B.",
    position: "DSc",
  },
  {
    id: 12,
    name: "Yakubov M.D.",
    position: "b.f.n., katta ilmiy xodim",
  },
  {
    id: 13,
    name: "Baev A.Yu.",
    position: "PhD b.f., katta ilmiy xodim",
  },
  {
    id: 14,
    name: "Tsiferova N.A.",
    position: "PhD b.f., katta ilmiy xodim",
  },
  {
    id: 15,
    name: "Zakirov A.Sh.",
    position: "PhD",
  },
  {
    id: 16,
    name: "Muminov Y.V.",
    position: "PhD",
  },
  {
    id: 17,
    name: "Qosimova G.",
    position: "PhD",
  },
  {
    id: 18,
    name: "Tursunkulov O.M.",
    position: "PhD",
  },
  {
    id: 19,
    name: "Yanbuxtin I.",
    position: "PhD g.-m.f.",
  },
  {
    id: 20,
    name: "Abdurahimov A.A",
    position: "PhD b.f.",
  },
  {
    id: 21,
    name: "Bozorov S.S",
    position: "PhD k.f.",
  },
  {
    id: 22,
    name: "Sohibnazarova H.",
    position: "PhD k.f.",
  },
  {
    id: 23,
    name: "Pshenichnov E.",
    position: "Ph.D.",
  },
  {
    id: 24,
    name: "Sariev A.",
    position: "PhD tib.f.",
  },
  {
    id: 25,
    name: "Yudakov A.",
    position: "bo‘lim boshlig‘i",
  },
  {
    id: 26,
    name: "Tsoy V.E.",
    position: "katta ilmiy xodim",
  },
  {
    id: 27,
    name: "Rejapova M.",
    position: "PhD b.f.",
  },
];
export const scientficCouncilEng = [
  {
    id: 1,
    name: "Turdikulova Sh.U. (chairman)",
    position: "Doctor of Biological Sciences, Professor",
  },
  {
    id: 2,
    name: "Dalimova D.A. (vice-chairman)",
    position: "PhD",
  },
  {
    id: 3,
    name: `Levitskaya Yu.V.
(scientific secretary) 
`,
    position: "PhD",
  },
  {
    id: 4,
    name: "Sobirov R.Z.",
    position: "Doctor of Biology, Academician",
  },
  {
    id: 5,
    name: "Abdullaev A.A.",
    position: "Doctor of Biological Sciences",
  },
  {
    id: 6,
    name: "Charishnikova O.S.",
    position: "PhD b.s.",
  },
  {
    id: 7,
    name: "Nazarov Kh.T.",
    position: "PhD",
  },
  {
    id: 8,
    name: "Alimukhamedov I.M.",
    position: "PhD",
  },
  {
    id: 9,
    name: "Khalmatov R.A.",
    position: "Doctor of Geology and Mineralogy",
  },
  {
    id: 10,
    name: "Ibragimova E.M.",
    position: "Doctor of Physical and Mathematical Sciences",
  },
  {
    id: 11,
    name: "Khakimov B.",
    position: "DSc",
  },
  {
    id: 12,
    name: "Yakubov M.D.",
    position: "PhD",
  },
  {
    id: 13,
    name: "Baev A.Yu.",
    position: "PhD b.s.",
  },
  {
    id: 14,
    name: "Tsiferova N.A.",
    position: "PhD b.s.",
  },
  {
    id: 15,
    name: "Zakirov A.Sh.",
    position: "PhD",
  },
  {
    id: 16,
    name: "Muminov Y.V.",
    position: "PhD",
  },
  {
    id: 17,
    name: "Kasimova G.",
    position: "PhD",
  },
  {
    id: 18,
    name: "Tursunkulov O.M.",
    position: "PhD",
  },
  {
    id: 19,
    name: "Yanbukhtin I.",
    position: "PhD g.-m.s.",
  },
  {
    id: 20,
    name: "Abdurakhimov A.A",
    position: "PhD b.s.",
  },
  {
    id: 21,
    name: "Bozorov S.S",
    position: "PhD",
  },
  {
    id: 22,
    name: "Sokhibnazarova H.",
    position: "PhD b.s.",
  },
  {
    id: 23,
    name: "Pshenichnov E.",
    position: "PhD",
  },
  {
    id: 24,
    name: "Sariev A.",
    position: "PhD med.s.",
  },
  {
    id: 25,
    name: "Yudakov A.",
    position: "Department head",
  },
  {
    id: 26,
    name: "Tsoy V.E.",
    position: "Senior Researcher",
  },
  {
    id: 27,
    name: "Rejapova M.",
    position: "PhD b.s.",
  },
];
export const scientficCouncilRus = [
  {
    id: 1,
    name: "Турдикулова Ш.У. (председатель) ",
    position: "д.б.н., профессор",
  },
  {
    id: 2,
    name: "Далимова Д.А. (зам.председателя)",
    position: "к.б.н., с.н.с.",
  },
  {
    id: 3,
    name: `Левицкая Ю.В. 
(ученый секретарь) 
`,
    position: "к.б.н.",
  },
  {
    id: 4,
    name: "Собиров Р.З.",
    position: "д.б.н., академик",
  },
  {
    id: 5,
    name: "Абдуллаев А.А.",
    position: "д.б.н., с.н.с.",
  },
  {
    id: 6,
    name: "Чарышникова О.С.",
    position: "PhD б.н",
  },
  {
    id: 7,
    name: "Назаров Х.Т.",
    position: "к.ф.-м.н.",
  },
  {
    id: 8,
    name: "Алимухамедов И.М.",
    position: "к.г.-м.н.",
  },
  {
    id: 9,
    name: "Халматов Р.А.",
    position: "д.г.-м.н.",
  },
  {
    id: 10,
    name: "Ибрагимова Э.М.",
    position: "д.ф.-м.н.",
  },
  {
    id: 11,
    name: "Хакимов Б.",
    position: "DSc",
  },
  {
    id: 12,
    name: "Якубов М.Д.",
    position: "к.б.н., с.н.с.",
  },
  {
    id: 13,
    name: "Баев А.Ю.",
    position: "PhD б.н.",
  },
  {
    id: 14,
    name: "Циферова Н.А.",
    position: "PhD б.н.",
  },
  {
    id: 15,
    name: "Закиров А.Ш.",
    position: "к.т.н.",
  },
  {
    id: 16,
    name: "Муминов Я.В.",
    position: "к.х.н.",
  },
  {
    id: 17,
    name: "Касимова Г.",
    position: "к.х.н.",
  },
  {
    id: 18,
    name: "Турсункулов О.М.",
    position: "к.ф.-м.н.",
  },
  {
    id: 19,
    name: "Янбухтин  И.",
    position: "PhD г.-м.н.",
  },
  {
    id: 20,
    name: "Абдурахимов А.А.",
    position: "PhD б.н.",
  },
  {
    id: 21,
    name: "Бозоров С.С.",
    position: "PhD х.н.",
  },
  {
    id: 22,
    name: "Сохибназарова Х.",
    position: "PhD б.н.",
  },
  {
    id: 23,
    name: "Пшеничнов Е.",
    position: "к.х.н.",
  },
  {
    id: 24,
    name: "Сариев А.",
    position: "PhD мед.н.",
  },
  {
    id: 25,
    name: "Юдаков А.",
    position: "глава отделения",
  },
  {
    id: 26,
    name: "Цой В.Э.",
    position: "к.и.х.",
  },
  {
    id: 27,
    name: "Режапова М.",
    position: "PhD б.н.",
  },
];

import React, { useContext } from "react";
import { Wrapper } from "./style";
import { LanguageContext } from "../../context/languageContext";

//content images
import mainImg from "../../assets/images/laboratories/cell/main.jpg";
import image1 from "../../assets/images/laboratories/cell/1.jpg";
import image2 from "../../assets/images/laboratories/cell/2.jpg";
import image3 from "../../assets/images/laboratories/cell/3.jpg";
import image4 from "../../assets/images/laboratories/cell/4.jpg";

// logos of companies
import logo1 from "../../assets/images/laboratories/cell/hamkorlar/1.png";
import logo2 from "../../assets/images/laboratories/cell/hamkorlar/2.png";
import logo3 from "../../assets/images/laboratories/cell/hamkorlar/3.png";
import logo4 from "../../assets/images/laboratories/cell/hamkorlar/4.png";
import logo5 from "../../assets/images/laboratories/cell/hamkorlar/5.png";
import logo6 from "../../assets/images/laboratories/cell/hamkorlar/6.png";
import logo7 from "../../assets/images/laboratories/cell/hamkorlar/7.png";

const LaboratoryoftheCellTechnologyComp = () => {
  const [language] = useContext(LanguageContext);
  if (language === "uzb") return uzbek;
  else if (language === "rus") return russian;
  else if (language === "eng") return english;
};

export default LaboratoryoftheCellTechnologyComp;
const uzbek = (
  <Wrapper>
    <div className="heading">
      <h1>HUJAYRA TEXNOLOGIYALARI LABORATORIYASI</h1>
    </div>

    <img src={mainImg} alt="" className="image main-image" />

    <div className="content">
      <h3>LABORATORIYANING TASHKIL TOPISHI</h3>
      <p>
        Laboratoriya 2020-yil 25-noyabrdagi “Biotexnologiyalarni rivojlantirish
        va mamlakatning biologik xavfsizligini ta’minlash tizimini
        takomillashtirish bo‘yicha kompleks chora-tadbirlar to‘g‘risida”gi
        PQ-4899-sonli qaroriga muvofiq 2021-yilda tashkil etilgan.
      </p>

      <h3>LABORATORIYANING FAOLIYATI</h3>
      <p>
        Laboratoriyaning ilmiy faoliyati kindik qonining gematopoetik o’zak
        hujayralari, mezenximal o’zak hujayralari va inson fibroblastlari
        bankini yaratish; ilmiy va tibbiy maqsadlarda sertifikatlangan odam va
        hayvonlar hujayralarining kriokollektsiyasini saqlash va to'ldirish;
        biotibbiy hujayra mahsulotlarini, shu jumladan rekonstruktiv va
        almashtirish terapiyasi uchun to'qima muhandislik tuzilmalarini ishlab
        chiqish va qo’llashga qaratilgan.
      </p>
      <p>
        O‘zbekiston Respublikasining yetakchi mahalliy ilmiy-tadqiqot tibbiyot
        markazlari mutaxassislari bilan birgalikda kobustiologiya, yuz-jag‘
        rekonstruktiv xirurgiyasi sohasida avtologik hujayrali mahsulotlaridan
        foydalanish bo‘yicha ilmiy va klinik tadqiqotlar olib borilmoqda.
      </p>
      <div className="hero">
        <img src={image1} alt="" className="image content-image" />
        <img src={image2} alt="" className="image content-image" />
      </div>

      <h3>ILMIY-TADQIQOT LOYIHALARI</h3>
      <p>
        -O’zak xujayralar bankini yaratishga mo’ljallangan texnologik platforma
        yaratish. Loyiha rahbari Charishnikova O.S.
      </p>
      <p>
        -“Stomatologiya, onkologiya, onkogemotologiya, travmatologiya va
        jarrohlik uchun oʻzak hujayralari va toʻqimamuhandislik qurilmalari
        asosida mahsulotlar olish texnologiyasini ishlab chiqish”. Loyiha
        rahbarlari Тsiferova N.A.
      </p>
      <p>
        -Tanglay nekrozini davolash uchun biotibbiy hujayra mahsulotini
        eksperimental klinik o'rganish. Loyiha rahbarlari Тsiferova N.A. va
        Charishnikova O.S.
      </p>
      <p>
        -Hayvonlar va inson hujayralari liniyalari to'plamini yaratish va
        saqlash. Loyiha rahbari Тsiferova N.A.
      </p>

      <div className="hero">
        <img src={image3} alt="" className="image content-image" />
        <img src={image4} alt="" className="image content-image" />
      </div>

      <h3>2021-2025 YILLARDA USTUVOR ILMIY YO’NALISHLAR</h3>
      <p>
        -Biotibbiyot hujayra mahsulotlarini ishlab chiqarish bo'yicha
        ilmiy-texnologik platformani tashkil etish;
      </p>
      <p>-Biotibbiyot hujayra mahsulotlarini ishlab chiqish;</p>
      <p>-Biotibbiyot hujayra texnologiyalarini klinikaga transfer qilish;</p>
      <p>
        -Amaliy sog'liqni saqlash tizimiga xujayra texnologiyalarni joriy etish;
      </p>

      <h3>O‘ZBEKISTON TIBBIYOT MUASSASALARI BILAN HAMKORLIK</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p>
            TOSHKENT DAVLAT STOMATOLOGIYA INSTITUTI (TASHKENT STATE DENTAL
            INSTITUTE)
          </p>
          <img src={logo1} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>TOSHKENT TIBBIYOT AKADEMIYASI (TASHKENT MEDICAL ACADEMY)</p>
          <img src={logo2} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            RESPUBLIKA SHOSHILINCH TIBBIY YORDAM ILMIY MARKAZI REPUBLICAN
            (RESEARCH CENTRE OF EMERGENCY MEDICINE)
          </p>
          <img src={logo3} alt="" className="logo" />
        </div>
      </div>

      <h3>XALQARO ILMIY HAMKORLIK</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p>
            "Belarus Milliy Fanlar Akademiyasining Biofizika va hujayra
            muhandisligi instituti" davlat ilmiy muassasasi.
          </p>
          <img src={logo4} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Rossiya Federatsiyasining Qozon (Volga viloyati) federal
            universiteti.
          </p>
          <img src={logo5} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Rossiya Federatsiyasi Sog'liqni Saqlash Davlat Byudjeti Muassasasi
            "Samara viloyat tibbiyot markazi - Dinastiya".
          </p>
          <img src={logo6} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>“Regenerativ tibbiyot jamiyati” hududiy jamoat tashkiloti.</p>
          <img src={logo7} alt="" className="logo" />
        </div>
      </div>

      <h3>Xujayra texnologiyalar laboratoriyasi ko’rsatadigan xizmatlar</h3>
      <p>
        -Buyurtmachining murojaatiga binoan hayvonlar va odamlarning mezenximal
        o’zak hujayralari va fibroblastlarini eksperimental tadqiqotlar uchun
        ajratib olish va yetishtirish;
      </p>
      <p>
        -Tadqiqot maqsadlari uchun birlamchi hujayra kulturalari bilan
        ta'minlash;
      </p>
      <p>
        -Buyurtmachining murojaatiga binoan hujayra kulturasini kriogenik
        saqlash bo'yicha kompleks xizmatlarni ko'rsatish (ko’paytirish,
        muzlatish, birlamchi hujayralar bankini krioidishga joylashtirish)
      </p>

      <iframe
        src="https://www.youtube.com/embed/KDhdgwWX_8M?si=1umB3Ntg3izI25In"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        frameBorder={0}
      ></iframe>
    </div>
  </Wrapper>
);
/////////////////////////////////////////////////////////////////////////////////////////////////
const russian = (
  <Wrapper>
    <div className="heading">
      <h1>ЛАБОРАТОРИЯ КЛЕТОЧНЫХ ТЕХНОЛОГИЙ</h1>
    </div>

    <img src={mainImg} alt="" className="image main-image" />

    <div className="content">
      <h3>ОРГАНИЗАЦИЯ ЛАБОРАТОРИИ</h3>
      <p>
        Лаборатория создана в 2021 году в соответствии с Постановлением
        Президента Республики Узбекистан ПП-4899 от 25 ноября 2020 г. «О
        комплексный мерах по развитию биотехнологий и совершенствованию системы
        обеспечения биологической безопасности страны».
      </p>

      <h3>НАПРАВЛЕНИЯ ДЕЯТЕЛЬНОСТИ</h3>
      <p>
        Научные интересы лаборатории фокусируются на создании Банка
        гемопоэтических стволовых клеток пуповинной крови, мезенхимальных
        стволовых клеток и фибробластов человека, поддержании и пополнении
        криоколлекции паспортизированных линий клеток человека и животных для
        научных и медицинских целей, разработке и внедрении биомедицинских
        клеточных продуктов, в том числе тканеинженерных конструкций для
        реконструктивной и заместительной терапии.
      </p>
      <p>
        Совместно со специалистами ведущих отечественных
        научно-исследовательских медицинских центров РУз ведутся научные и
        клинические исследования по применению аутологичных клеточных продуктов
        в области комбустиологии, челюстно-лицевой реконструктивной хирургии.
      </p>
      <div className="hero">
        <img src={image1} alt="" className="image content-image" />
        <img src={image2} alt="" className="image content-image" />
      </div>

      <h3>НАУЧНО-ИССЛЕДОВАТЕЛЬСКИЕ ПРОЕКТЫ</h3>
      <p>
        -Организация научно-технологической платформы для создания банка
        стволовых клеток человека. Руководитель проекта Чарышникова О.С. (узб.
        «Ўзак хужайралар банкини яратишга мўлжалланган технологик платформа
        яратиш»);
      </p>
      <p>
        -Разработка технологии получения продуктов на основе стволовых клеток и
        создание тканеинженерных конструкций для стоматологии, онкологии,
        онкогематологии, травматологии и хирургии. Руководитель проекта Циферова
        Н.А.;
      </p>
      <p>
        -Создание и поддержание коллекции клеточных линий животных и человека.
        Руководитель проекта Циферова Н.А.;
      </p>
      <p>
        -Экспериментальное клиническое исследование биомедицинского клеточного
        продукта для лечения некроза неба. Руководители проекта Циферова Н.А. и
        Чарышникова О.С.;
      </p>

      <div className="hero">
        <img src={image3} alt="" className="image content-image" />
        <img src={image4} alt="" className="image content-image" />
      </div>

      <h3>ПРИОРИТЕТНЫЕ НАПРАВЛЕНИЯ ДЕЯТЕЛЬНОСТИ 2021-2025 ГОДЫ</h3>
      <p>
        -Организация научно-технологической платформы для производства
        биомедицинских клеточных продуктов;
      </p>
      <p>-Разработка биомедицинских клеточных продуктов;</p>
      <p>-Трансфер биомедицинских клеточных технологий;</p>
      <p>-Внедрение клеточных технологий в практическое здравоохранениеж;</p>

      <h3>СОТРУДНИЧЕСТВО С МЕДИЦИНСКИМИ УЧРЕЖДЕНИЯМИ УЗБЕКИСТАНА</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p>ТАШКЕНТСКИЙ ГОСУДАРСТВЕННЫЙ СТОМАТОЛОГИЧЕСКИЙ ИНСТИТУТ</p>
          <img src={logo1} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>ТАШКЕНТСКАЯ МЕДИЦИНСКАЯ АКАДЕМИЯ</p>
          <img src={logo2} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>РЕСПУБЛИКАНСКИЙ НАУЧНЫЙ ЦЕНТР ЭКСТРЕННОЙ МЕДИЦИНСКОЙ ПОМОЩИ</p>
          <img src={logo3} alt="" className="logo" />
        </div>
      </div>

      <h3>МЕЖДУНАРОДНОЕ СОТРУДНИЧЕСТВО</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p>ГНУ «Институт биофизики и клеточной инженерии НАН Беларуси»</p>
          <img src={logo4} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            ФГАОУ ВО «Казанский(Приволжский) Федеральный университет» Российской
            Федерации»
          </p>
          <img src={logo5} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            ГБУ здравоохранения РФ «Самарский областной медицинский центр
            Династия»
          </p>
          <img src={logo6} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Региональная общественная организация «Общество регенеративной
            медицины»
          </p>
          <img src={logo7} alt="" className="logo" />
        </div>
      </div>

      <h3>Услуги лаборатории клеточных технологий</h3>
      <p>
        -Выделение и культивирование стволовых мезенхимальных клеток и
        фибробластов животных и человека для экспериментальных исследований по
        запросу заказчика;
      </p>
      <p>
        -Предоставление первичных культур клеток для исследовательских целей;
      </p>
      <p>
        -Предоставление комплексных услуг криогенного хранилища для культур
        клеток по запросу заказчика (масштабирование, замораживание, размещение
        первичного банка клеток в криохранилище);
      </p>

      <iframe
        src="https://www.youtube.com/embed/KDhdgwWX_8M?si=1umB3Ntg3izI25In"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        frameBorder={0}
      ></iframe>
    </div>
  </Wrapper>
);
const english = (
  <Wrapper>
    <div className="heading">
      <h1>LABORATORY of CELL TECHNOLOGIES</h1>
    </div>

    <img src={mainImg} alt="" className="image main-image" />

    <div className="content">
      <h3>LABORATORY ORGANIZATION</h3>
      <p>
        The Lab. was established in 2021 in accordance with decree of President
        «On comprehensive measures for the development of biotechnologies and
        improvement of the country's biological security system".
      </p>

      <h3>ACTIVITIES</h3>
      <p>
        The scientific interests of the Lab. are focused on the organization of
        the Cord Blood Bank, banking of human mesenchymal stem cells and
        fibroblasts, maintenance and replenishment of the cryocollection of
        certified human and animal cell lines for scientific and medical
        purposes, development and implementation of medical cell-based products,
        including tissue engineering constructions for reconstructive and
        replacement therapy.
      </p>
      <p>
        The Lab. carries out a joint scientific and clinical research with the
        country's leading medical centers on the treatment of autologous
        cell-based medical products in combustiology, maxillofacial and
        reconstructive surgery.
      </p>
      <div className="hero">
        <img src={image1} alt="" className="image content-image" />
        <img src={image2} alt="" className="image content-image" />
      </div>

      <h3>RESEARCH PROJECTS</h3>
      <p>
        Organization of a scientific and technological platform for the creation
        of Stem Cell Bank. Project Manager O.S. Charishnikova;
      </p>
      <p>
        Development of a technology for obtaining stem cell-based products and
        creating tissue engineering constructions for dentistry, oncology,
        oncohematology, traumatology and surgery. Project manager N.A. Tsiferova
        Н.А;
      </p>
      <p>
        -Formation and maintenance of a collection of animal and human cell
        lines. Project manager N.A. Tsiferova;
      </p>
      <p>
        -Pilot clinical trial of a cell-based biomedical product for the
        treatment of palatal necrosis. Project managers N.A. Tsiferova & O.S.
        Charishnikova;
      </p>

      <div className="hero">
        <img src={image3} alt="" className="image content-image" />
        <img src={image4} alt="" className="image content-image" />
      </div>

      <h3>PRIORITY ACTIVITIES 2021-2025</h3>
      <p>
        -Organization of a scientific and technological platform for the
        manufacturing of medical cell-based products;
      </p>
      <p>-Development of medical cell-based products;</p>
      <p>-Transfer of medical cell-based technologies;</p>
      <p>
        -Implementation of medical cell-based technologies in practical
        healthcare;
      </p>

      <h3>COOPERATION WITH UZBEKISTAN MEDICAL INSTITUTIONS</h3>
      <div className="logo-section">
        <div className="logo-wrapper">
          <p>TASHKENT STATE DENTAL INSTITUTE</p>
          <img src={logo1} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>TASHKENT MEDICAL ACADEMY</p>
          <img src={logo2} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>REPUBLICAN RESEARCH CENTRE OF EMERGENCY MEDICINE</p>
          <img src={logo3} alt="" className="logo" />
        </div>
      </div>

      <h3>INTERNATIONAL SCIENTIFIC COOPERATION</h3>
      <div className="logo-section logo-section2">
        <div className="logo-wrapper">
          <p>
            State Scientific Institution "Institute of Biophysics and Cell
            Engineering of the National Academy of Sciences of Belarus"
          </p>
          <img src={logo4} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Federal State Autonomous Educational Institution of Higher Education
            "Kazan (Volga Region) Federal University" of the Russian Federation
            Federation"
          </p>
          <img src={logo5} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            State Budgetary Institution of Healthcare of the Russian Federation
            "Samara Regional Medical Center" Dynasty"
          </p>
          <img src={logo6} alt="" className="logo" />
        </div>
        <div className="logo-wrapper">
          <p>
            Regional public organization "Society for Regenerative medicine"
          </p>
          <img src={logo7} alt="" className="logo" />
        </div>
      </div>

      <h3>Services of cell technology laboratory</h3>
      <p>
        -Isolation and cultivation of mesenchymal stem cells and fibroblasts of
        animals and humans for experimental studies at the request of the
        customer;
      </p>
      <p>-Provision of primary cell cultures for research purposes;</p>
      <p>
        -Provision of complex services of cryogenic storage for cell cultures at
        the request of the customer (scaling, freezing, placement of the primary
        cell bank in cryostorage);
      </p>

    </div>
  </Wrapper>
);

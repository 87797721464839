import styled from "styled-components";

export const Desktop = styled.div`
  display: block;
  @media (max-width: 800px) {
    display: none;
  }
`;
export const Mobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;
export const Main = styled.div`
  width: 100%;
  height: 86vh;
  display: flex;
  justify-content: center;
  position: relative;
  .swiper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
    .swiper-pagination-bullet {
      background-color: white;
      width: 12px;
      height: 12px;
    }
    .swiper-pagination-bullet-active {
      background-color: red;
      width: 25px;
      height: 25px;
    }
    .swiper-pagination-bullet {
      background-color: white;
      width: 12px;
      height: 12px;
      margin-bottom: 20px;
      margin-right: -10px;
    }
  }
  .video {
    position: absolute;
    video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: fixed;
      left: 0;
      right: 0;
      top: 50px;
      bottom: 0;
      z-index: -1;
    }
  }
  @media (max-width: 800px) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
export const Blur = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: rgba(4, 28, 84, 0.65);
  height: 86vh;
  color: black;
  @media (max-width: 800px) {
    height: 100%;

  }
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: var(--container);
  padding: var(--padding);
  position: absolute;
  top: 0;
  z-index: 2;
  @media (max-width: 800px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export const Section = styled.div``;

import React, { useContext } from "react";
import { Wrapper } from "./style";
import imgUz from "../../assets/images/structureUzb.png";
import imgRus from "../../assets/images/structureRus.png";
import { LanguageContext } from "../../context/languageContext";
const CenterStructureComp = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb" || lang === "eng") {
    return (
      <Wrapper>
        <img src={imgUz} alt="" />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <img src={imgRus} alt="" />
      </Wrapper>
    );
  }
};

export default CenterStructureComp;

import React from "react";
import EmpoloyeeCard from "../EmpoloyeeCard/EmpoloyeeCard";
import { Wrapper } from "./style";

const Employee = ({ data, title }) => {
  return (
      <Wrapper>
      <h1>{title}</h1>
        {data.map((value) => {
          return <EmpoloyeeCard data={value} key={value.id} />;
        })}
      </Wrapper>
  );
};

export default Employee;

import img from "../../assets/images/laboratories/fudomics/1.jpg";
export const fudomicUzb = {
  img: [img],
  title1: "Fudomika laboratoriyasining asosiy yo’nalishlari ",
  text1: `Foodomics - bu bizning oziq-ovqatimizda mavjud bo'lgan barcha moddalar to'plamini o'rganadigan tadqiqot intizomi (foodome). Intizom oziq-ovqat tarkibini va shu tariqa uning ozuqaviy xususiyatlarini va salomatlikka ta'sirini o'rganish uchun ilg'or tahliliy platformalardan foydalanadi. Yangi texnikalar, shuningdek, oziq-ovqat sifati haqida batafsil ma'lumot beradi va oziq-ovqat mahsulotlarini firibgarligini aniqlash va oziq-ovqat ishlab chiqarishdagi boshqa muammolarni hal qilish uchun ishlatilishi mumkin. Oziq-ovqat sohasidagi tadqiqot natijalari iste'molchilarga, oziq-ovqat sanoatiga va jamiyatga bevosita ta'sir qiladi.`,
};
export const fudomicRus = {
  img: [img],
  title1: "Основные направления лаборатории фудомики",
  text1: `Foodomics требует сочетания химии пищевых продуктов, биологических наук и анализа данных и в значительной степени помогает ученым в области пищевой науки и питания получить лучший доступ к данным, которые используются для анализа воздействия продуктов питания на здоровье человека.Данные омикс-технологические платформы открыли новые возможности в пищевой индустрии, которые позволят обнаружить и охарактеризовать маркеры пищи в соответствии с определенным пищевым статусом как отдельных лиц, так и групп населения, а также помогут позиционировать наиболее эффективные биологически активные соединения, ответственные за состояние здоровья человека.`,
};
export const fudomicEng = {
  img: [img],
  title1: "Laboratory direction",
  text1: `Foodomics is a research discipline that examines the entire set of substances present in our food (foodome). The discipline uses advanced analytical platforms to investigate the composition of the food and thus, its nutritional properties and impact on health. The new techniques also provide a detailed picture of food quality and can be used to detect food fraud and to find solutions for other challenges in food production. Research results from foodomics have a direct impact on consumers, the food industry and society.`,
};

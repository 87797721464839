import { Button, Drawer } from "antd";
import React from "react";
import { FiMenu } from "react-icons/fi";
import SelectForLaguage from "../SelectForLanguage";
import MenuForNavbar from "../Menu";
import { Container, DrawerAnt } from "./style";
const DrawerForNavbar = () => {
  const [open, setOpen] = React.useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Container>
      <Button type="primary" onClick={showDrawer}>
        <FiMenu />
      </Button>
      <DrawerAnt
        style={{
          background: "var(--bgColor)",
        }}
        placement="left"
        onClose={onClose}
        open={open}
        className="drawerr"
        title={
          <div className="header">
            <p>C.A.T</p> <SelectForLaguage mobile />
          </div>
        }
      >
        <MenuForNavbar mobile="1" />
      </DrawerAnt>
    </Container>
  );
};

export default DrawerForNavbar;

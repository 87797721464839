import img1 from "../assets/images/departments/nigoraOpa.png";
import img2 from "../assets/images/departments/ulugbekAka.png";
import img3 from "../assets/images/departments/zohidAka.png";
import img4 from "../assets/images/departments/xusniddinAka.jpg";
import noperson from "../assets/images/noperson.jpg";

export const departmentsUzb = [
  {
    id: 1,
    img: img1,
    name: "Aripjanova Nigora Toxirovna",
    position: "Xodimlar bilan ishlash bo’limi",
    degree: "Xodimlar bilan ishlash bo’limi boshlig’i",
    number: "+99871 227-43-16 ichki (45-41)",
    email: "n.aripdjanova@cat-science.uz",
    time: "Xodimlar soni:1",
    btn: "Batafsil",
  },
  {
    id: 2,
    img: img2,
    name: "Tulaganov Ulug’bek Abdurazakovich",
    position: "Xo’jalik bo’limi",
    degree: "Xo’jalik bo’limi boshlig’i",
    number: "+99871 227-43-16 ichki (45-54)",
    email: "u.tulaganov@cat-science.uz",
    time: "Xodimlar soni:1",
    btn: "Batafsil",
  },
  {
    id: 3,
    img: img3,
    name: "Raxmatov Zohid Akramovich",
    position: "Moliya-iqtisod bo’limi",
    degree: "Bosh hisobchi",
    number: "+99871 227-43-16 ichki (45-03)",
    email: "z.raxmatov@cat-science.uz",
    time: "Xodimlar soni:3",
    btn: "Batafsil",
  },
  {
    id: 4,
    img: img4,
    name: "Madumarov Xusniddin Xusanovich",
    position:
      "Yangi axborot texnologiyalari tadqiqotlari va ilmiy-texnika axboroti tahlili bo’limi",
    degree: "Bo’lim boshlig’i",
    number: "+99871 227-43-16 ichki (45-36)",
    email: "x.madumarov@cat-science.uz",
    time: "Xodimlar soni:1",
    btn: "Batafsil",
    title1: "Bo’lim ning asosiy faoliyati quyidagilardan iborat:",
    info1: `Yangi axborot-kommunikatsiya texnologiyalarini tadqiq etish va ilmiy-texnikaviy axborotni tahlil qilish boshqarmasi (keyingi o‘rinlarda “Bo‘lim” deb yuritiladi) O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi huzuridagi Ilg‘or texnologiyalar markazining (keyingi o‘rinlarda) tarkibiy bo‘linmasi hisoblanadi. "Markaz" deb ataladi).
    Bo‘lim o‘z faoliyatida Konstitutsiya va qonunlarga, Prezident farmonlariga, Vazirlar Mahkamasining qarorlariga, Markaz ustaviga, O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligining (keyingi o‘rinlarda deb yuritiladi) buyruq va farmoyishlariga amal qiladi. Vazirlik sifatida) va Markaz direktori.
    Markazning tarmoq uskunalarini boshqarish va dasturiy-texnik vositalarni texnik ta’minlash;
     - xalqaro Internet tarmog'idan foydalanishni nazorat qilish va yo'qolishi va ruxsatsiz kirish to'g'risidagi ma'lumotlarning himoya qilinishini ta'minlash;
    - ilmiy-texnikaviy axborot sohasida xalqaro hamkorlikni kengaytirishga yordam beradi;
    - shaxsiy kompyuterlar, orgtexnika va periferik qurilmalarni ta'mirlash, tizimli va texnik nosozliklarni bartaraf etish va ularga texnik xizmat ko'rsatish, kompyuter texnikasi va sarf materiallaridan oqilona foydalanishni ta'minlash;
    - bo‘lim ishi bo‘yicha rejalashtirish va hisobot hujjatlarini, bo‘lim xodimlarining yillik mehnat ta’tillari jadvallarini, bo‘lim uchun zarur bo‘lgan sarf materiallariga arizalarni, bo‘lim xodimlarining xizmat safari bo‘yicha takliflarini Markaz rahbariyatiga taqdim etadi.
    - Markazning tarkibiy bo‘linmalariga dasturiy ta’minot va texnik ta’minot bo‘yicha maslahat yordamini ko‘rsatish;
    - Markazning axborot xavfsizligini ta'minlash va serverda zaxira nusxalarini yaratish.
     - Markaz veb-saytining ishlashini va texnik ta'minotini ta'minlash;
    - boshqarmaning normativ hujjatlarini ishlab chiqish;
    - ilmiy-texnik axborot sohasida Markaz tomonidan o‘tkaziladigan konferensiyalar, forumlar, seminarlar, davra suhbatlarida mutaxassislarning ishtirok etishiga ko‘maklashish;
         - kompyuterlar, orgtexnika, qo'shimcha qurilmalar, butlovchi qismlar va sarf materiallarini sotib olish bo'yicha takliflar tayyorlash;
    - ilmiy axborot va innovatsion faoliyat tizimini o‘rganish hamda ilmiy-texnikaviy axborot madaniyatini oshirish maqsadida ilmiy tashkilotlar bilan o‘zaro hamkorlik qilish bo‘yicha tadbirlar tashkil etadi va o‘tkazadi;
    - ma'lumotlar bazasini ishlab chiqish va joriy etish bo'yicha chora-tadbirlarni amalga oshirish va boshqalar;
    `,
  },
  {
    id: 5,
    img: noperson,
    name: "Vakant",
  },
];
export const departmentsRus = [
  {
    id: 1,
    img: img1,
    name: "Арипжанова Нигора Тахировна",
    position: "Инспектор по кадром",
    degree: "Начальник кадрового отдела",
    number: "+99871 227-43-16 внутренный (45-41)",
    email: "n.aripdjanova@cat-science.uz",
    time: "Количество сотрудников:1",
    btn: "Более",
  },
  {
    id: 2,
    img: img2,
    name: "Туляганов Улугбек Абдуразакович",
    position: "Хозяйственный отдел",
    degree: "Начальник хозяйственного отдела",
    number: "+99871 227-  43-16 внутренный (45-54)",
    email: "u.tulaganov@cat-science.uz",
    time: "Количество сотрудников:1",
    btn: "Более",
  },
  {
    id: 3,
    img: img3,
    name: "Рахматов Зохид Акрамович",
    position: "Финансово экономический отдел",
    degree: "Главный бухгалтер",
    number: "+99871 227-43-22 внутренный (45-03)",
    email: "z.raxmatov@cat-science.uz",
    time: "Количество сотрудников:3",
    btn: "Более",
  },
  {
    id: 4,
    img: img4,
    name: "Мадумаров Хусниддин Хусанович",
    position:
      "Отдел исследований новых информационных технологий и анализа научно-технической информации",
    degree: "Начальник отдела ",
    number: "+99871 227-43-22 внутренный (45-36)",
    email: "x.madumarov@cat-science.uz",
    time: "Количество сотрудников:1",
    btn: "Более",
    title1: `Основными направлениями деятельности отдела являются:`,
    info1: `Отдел исследований новых информационно-коммуникационных технологий и анализа научно-технической информации (далее «Отдел») является структурным подразделением Центра передовых технологий при Министерстве инновационного развития Республики Узбекистан (далее «Центр»).
    В своей деятельности отдел руководствуется Конституцией и Законами, Указами Президента, Постановлениями Кабинета Министров, Уставом Центра, приказами и распоряжениями Министерства инновационного развития Республики Узбекистан (далее «Министерство») и Директора Центра.
    администрирование сетевого оборудования Центра и техническое обсуживание программно-технических средств; 
    - контроль использования международной сети Интернет и обеспечение защиты информации о потери и несанкционированного доступа;
    - способствует расширению международного сотрудничества в сфере научно-технической информации; 
    - ремонт, устранение системных и технических неисправностей и техническое обслуживание персональных компьютеров, оргтехники и периферийных устройств, обеспечение рационального использования вычислительной техники и расходных материалов;
    - представляет администрации Центра плановые и отчетные документы по работе отдела, графики ежегодных отпусков работников отдела, заявки на требуемые для отдела расходные материалы, предложения по служебным командировкам работников отдела.
    - оказание консультативной помощи структурным подразделениям Центра в плане программного и технического обеспечения;
    - обеспечение информационной безопасности Центра и создание резервных копий на сервере.
    - обеспечение функционирования и техническая поддержка сайта Центра;
    - разработка нормативных документов отдела;
    - содействие участию специалистов в конференциях, форумах, семинарах, круглых столах проводимых Центром в сфере научно-технической информации;
    - подготовка предложений по приобретению компьютеров, оргтехники, периферийных устройств, комплектующих и расходных материалов;
    - организовывает и проводит мероприятия по изучению системы научно-информационной и инновационной деятельности и взаимодействию с научными организациями в целях повышения культуры научно-технической информации;
    - осуществление мероприятий по разработке и внедрению базы данных и др.;
    `,
  },
  {
    id: 5,
    img: noperson,
    name: "Вакант",
  },
];
export const departmentsEng = [
  {
    id: 1,
    img: img1,
    name: "Aripjanova Nigora Toxirovna",
    position: "Department of work with employees",
    degree: "Head of the Department of Work with Employees",
    number: "+99871 227-43-16 internal (45-41)",
    email: "n.aripdjanova@cat-science.uz",
    time: "Number of employees:1",
    btn: "More",
  },
  {
    id: 2,
    img: img2,
    name: "Tulaganov Ulugbek Abdurazakovich",
    position: "Centerskeeping department",
    degree: "Head of Department",
    number: "+99871 227-43-16 internal (45-54)",
    email: "u.tulaganov@cat-science.uz",
    time: "Number of employees:1",
    btn: "More",
  },
  {
    id: 3,
    img: img3,
    name: "Raxmatov Zohid Akramovich",
    position: "Financial economics department",
    degree: "The chief accountant",
    number: "+99871 227-43-16 internal (45-03)",
    email: "z.raxmatov@cat-science.uz",
    time: "Number of employees:3",
    btn: "More",
  },
  {
    id: 4,
    img: img4,
    name: "Madumarov Xusniddin Xusanovich",
    position:
      "Department of research of new information technologies and analysis of scientific and technical information",
    degree: "Head of Department",
    number: "+99871 227-43-16 internal (45-36)",
    email: "x.madumarov@cat-science.uz",
    time: "Number of employees:1",
    btn: "More",
    title1: `The main activities of the department are:`,
    info1: `The Department for Research of New Information and Communication Technologies and Analysis of Scientific and Technical Information (hereinafter referred to as the "Department") is a structural subdivision of the Center for Advanced Technologies under the Ministry of Innovative Development of the Republic of Uzbekistan (hereinafter referred to as the "Center").
    In its activities, the department is guided by the Constitution and Laws, Decrees of the President, Resolutions of the Cabinet of Ministers, the Charter of the Center, orders and orders of the Ministry of Innovative Development of the Republic of Uzbekistan (hereinafter referred to as the "Ministry") and the Director of the Center.
    administration of the Center's network equipment and technical support of software and hardware;
     - control of the use of the international Internet network and ensuring the protection of information about the loss and unauthorized access;
    - contributes to the expansion of international cooperation in the field of scientific and technical information;
    - repair, elimination of system and technical malfunctions and maintenance of personal computers, office equipment and peripheral devices, ensuring the rational use of computer equipment and consumables;
    - submits to the Center's administration planning and reporting documents on the work of the department, schedules of annual vacations of the department's employees, applications for consumables required for the department, proposals for business trips of the department's employees.
    - provision of advisory assistance to the structural subdivisions of the Center in terms of software and technical support;
    - ensuring the information security of the Center and creating backup copies on the server.
     - ensuring the functioning and technical support of the Center's website;
    - development of normative documents of the department;
    - promoting the participation of specialists in conferences, forums, seminars, round tables held by the Center in the field of scientific and technical information;
         - preparation of proposals for the purchase of computers, office equipment, peripherals, components and consumables;
    - organizes and conducts events to study the system of scientific information and innovation activities and interact with scientific organizations in order to improve the culture of scientific and technical information;
    - implementation of measures for the development and implementation of the database
`,
  },
  {
    id: 5,
    img: noperson,
    name: "Vacancy",
  },
];

import styled from "styled-components";

export const Section = styled.div`
  margin: 50px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .card {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 100%;
    background-color: var(--cardColor);
    cursor: pointer;
    border-radius: 20px;
    img {
      height: 100px;
      width: 180px;
    }
    transition: all ease 0.1s;
    :hover {
      transform: scale(1.01);
      box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
      -webkit-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
      -moz-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
      p {
        color: var(--hoverFontColor);
      }
    }
  }
  @media (max-width: 600px) {
    .card {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
      }
    }
  }
  @media (max-width: 750px) {
    p{
      font-size: 14px;
    }
    h1, h2, h3{
      font-size: 16px;
    }
  }
`;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./Root/Root";
import AOS from "aos";
import "aos/dist/aos.css";
import "antd/dist/reset.css";
import "swiper/css";
AOS.init();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

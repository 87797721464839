import img from "../assets/images/tender.png";

export const announcmentsUZ = [
  {
    id: 1,
    title: "Tender",
    img: img,
    text1: `O‘zbekiston Respublikasi Oliy ta’lim, fan va innovatsiyalar vazirligi huzuridagi Ilg‘or texnologiyalar markazi quyidagi uskunalarni xarid qilish bo‘yicha eng yaxshi takliflar tanlovini e’lon qiladi:`,
    text2: `1. Labsolar All Automatic photocatalytic online analysis system;
     2.Potentiostat/galvanostat instrument; 
     3. Spin Coater;
    `,
    text3: `Jahon banki hamda Oliy taʼlim, fan va innovatsiyalar vazirligining “OʻZBEKISTON MILLIY INNOVATSION TIZIMINI modernizatsiya qilish” loyihasining REP-03032022/186 subloyihasini “Yuqori samarali energiya konversiyasi uchun metal oksidlar asosidagi invers opal strukturalar” amalga oshirish doirasida amalga oshirilmoqda.`,
    text4: `Ilg‘or texnologiyalar markazi ushbu (REP-03032022/186) subloyihaning grantini oluvchi sifatida  firmalarni yuqorida ko’rsatilgan jihozlarni sotib olish bo'yicha o’z takliflarini topshirishga taklif qiladi.`,
    text5: `. Manfaatdor ishtirokchilar yuborilgan so'rovga muvofiq tender hujjatlarini, shu jumladan xarid qilinadigan uskunaning texnik tavsifini (takliflar so'rovi va texnik topshiriq bilan shartnoma shakli) shokirxojiyev@umail.uz  yoki oybtmuz@gmail.com pochta orqali olishlari mumkin.`,
    text6: `Firmalar takliflari 2023-yil 5-may Toshkent vaqti bilan soat 15:00 (GMT +5) ga qadar quyidagi elektron manzilga yozma ravishda yuborilishi kerak.`,
    text7: `Grant oluvchining aloqa ma'lumotlari:`,
    text8: `Subloyihaning ilmiy rahbari: Shokir Xojiev`,
    text9: `E-mail:: shokirxojiyev@umail.uz `,
    text10: `Subloyihaning ilmiy hamrahbari: Oybek Tursunqulov`,
    text11: `E-mail: oybtmuz@gmail.com`,
    text12: `E-mail:: shokirxojiyev@umail.uz`,
    text13: `Kerakli hujjatlar:`,
    text14: `1. Shartnoma shakli, 2. Taklif shakli, 3. Takliflar so'rovi`,
    text15: `Kerakli hujjatlarni olish uchun quyidagi pochtaga murojaat qiling: `,
    text16: `E-mail: oybtmuz@gmail.com, E-mail:: shokirxojiyev@umail.uz`,
  },
];
export const announcmentsRu = [
  {
    id: 1,
    title: "Тендер",
    img: img,
    text1: `Центр передовых технологий при Министерстве высшего образования, науки и инноваций Республики Узбекистан объявляет о проведении отбора наилучших предложений по приобретению нижеследующего оборудования:`,
    text2: `1. Labsolar All Automatic photocatalytic online analysis system;
    2.Potentiostat/galvanostat instrument; 
    3. Spin Coater;
   `,
    text3: `В рамках выполнения проекта Всемирного Банка и Министерства высшего образования, науки и инноваций «Модернизация Национальной Инновационной системы Узбекистана» (MODERNIZING UZBEKISTAN’S NATIONAL INNOVATION SYSTEM) по суб-проекту REP-03032022/186 по теме: «Metal Oxides Based Inverse Opal Structure for Highly Efficient Energy Conversion» (Инверсная опаловая структура на основе оксидов металлов для высокоэффективного преобразования энергии).`,
    text4: `Центр передовых технологий в качестве грантополучателя данного суб-проекта (REP-03032022/186) приглашает фирмы выразить свою заинтересованность в предоставлении Предложений на покупку выше указанных оборудований.`,
    text5: `Заинтересованные участники могут получить тендерную документацию включая техническую спецификацию закупаемых оборудований (Запрос для предложений и Форма контракта с Техническим заданием) согласно запросу отправленному на почту shokirxojiyev@umail.uz  или oybtmuz@gmail.com `,
    text6: `Предложения Фирм должны быть доставлены в письменной форме на адрес электронной почты, указанный ниже, до 5 мая 2023 года в 15:00 по Ташкентскому времени (GMT +5).`,
    text7: `Контактная информация грантополучателя:
   Шокир Хожиев (Khojiev Shokir) 
   `,
    text8: `Научный руководитель суб-проекта`,
    text9: `E-mail:: shokirxojiyev@umail.uz `,
    text10: `Научный соруководитель суб-проекта
   Ойбек Турсункулов (Tursunkulov Oybek)
   `,
    text11: `E-mail: oybtmuz@gmail.com `,
    text12: ``,
    text13: `Необходимые документы:`,
    text14: `1.	Форма контракта, 2.	Форма для предложений, 3.	Запрос предложений
   `,
    text15: `Для получения необходимых документов обращайтесь на следующую почту:`,
    text16: `E-mail: oybtmuz@gmail.com, E-mail:: shokirxojiyev@umail.uz`,
  },
];
export const announcmentsEng = [
  {
    id: 1,
    title: "Tender",
    img: img,
    text1: `The Center for Advanced Technologies under the Ministry of Higher Education, Science and Innovation of the Republic of Uzbekistan announces the selection of the best proposals for the purchase of the following equipment:`,
    text2: `1. Labsolar All Automatic photocatalytic online analysis system;
     2.Potentiostat/galvanostat instrument; 
     3. Spin Coater;
    `,
    text3: `As part of the implementation of the project of the World Bank and the Ministry of higher education, science and innovation "Modernizing UZBEKISTAN'S NATIONAL INNOVATION SYSTEM" of sub-project REP-03032022/186: "Metal Oxides Based Inverse Opal Structure for Highly Efficient Energy Conversion”. `,
    text4: `The Center for Excellence as a grantee of this sub-project (REP-03032022/186) invites firms to express their interest in submitting Bids for the above equipment.`,
    text5: `Interested participants can receive tender documentation including the technical specification of the purchased equipment (Request for Proposals and Contract Form with Terms of Reference) according to the request sent to shokirxojiyev@umail.uz  or oybtmuz@gmail.com `,
    text6: `Firms' proposals must be delivered in writing to the email address below by May 5, 2023 at 15:00 Tashkent time (GMT +5).`,
    text7: `Grantee contact information:
    Khojiev Shokir 
    `,
    text8: `Sub-project Principal Investigator: Shokir Xojiev`,
    text9: `E-mail:: shokirxojiyev@umail.uz `,
    text10: `Sub-project Co-Principal Investigator: Tursunkulov Oybek `,
    text11: `E-mail: oybtmuz@gmail.com`,
    text12: `E-mail:: shokirxojiyev@umail.uz`,
    text13: `Required documents:`,
    text14: `1. Form of contract, 2. Proposal form, 3. Request for proposals
    `,
    text15: `To receive the necessary documents, contact the following mail:`,
    text16: `E-mail: oybtmuz@gmail.com, E-mail:: shokirxojiyev@umail.uz`,
  },
];

import img1 from "../assets/images/departments/yuliya.jpg";
export const councilUzb = [
  {
    id: 1,
    img: img1,
    name: "Levitskaya Yuliya Vladimirovna",
    degree: "Biologiya fanlari nomzodi",
    number: "+99871 227-43-22 ichki (45-06)",
    email: "y.levitskaya@cat-science.uz",
    title2: "Ilmiy kotib vazifalari:",
    btn: "Batafsil",
    title1: "Biografiya",
    info1: `Levitskaya Yuliya Vladimirovna 1974 yil 21 avgustda Toshkent shahrida tug'ilgan.2019-yilda Islom taraqqiyot banki tomonidan o‘tkazilgan Innovatorlar sammitida (Dakar, Senegal) ishtirok etdi.
      2019-yildan boshlab Yu.Levitskaya Fanlar nomzodi va fanlari nomzodi ilmiy darajalarini berish uchun Dsc.03/30.12.2019.V.01.13 Ilmiy kengashning Ilmiy seminari a’zosi.
      Levitskaya Yu.V. Blood Cells, Molecules, and Diseases, Biophysical Journal, Rocznik Swietokrzyski, Universum kabi nufuzli ilmiy jurnallarda chop etilgan ilmiy maqolalar muallifi. Biologiya talabalari uchun 4 ta darslik muallifi.
      `,
    title2: "Ta'lim:",
    heading: "Ilmiy kotibning vazifalari:",
    info2: [
      "1997 yilda Toshkent davlat universiteti (hozirgi Mirzo Ulug‘bek nomidagi O‘zbekiston Milliy universiteti) (kechki bo‘lim) ni tamomlagan.",
    ],
    title3: "Ilmiy daraja, unvon:",
    info3: "Biofizik, biologiya fanlari nomzodi",
    title4: "Kasbiy faoliyati:",
    info4: [
      "2018-yil 5-sentabrdan Yu.Levitskaya O‘zbekiston Respublikasi Innovatsion rivojlanish vazirligi huzuridagi Ilg‘or texnologiyalar markazi ilmiy kotibi lavozimida ish boshladi. ",
      "Ish staji 29 yil, ilmiy staji 23 yil, davlat organlarida ish staji 9 yil, ushbu lavozimda 2018 yildan beri ishlab kelmoqda.",
    ],
    text: `Ilmiy kotib ilmiy-tadqiqot va ishlanmalarning jamlanma istiqbollari va yillik rejalari bo‘yicha takliflar tayyorlashga rahbarlik qiladi, shuningdek ularni birgalikda amalga oshirishda Markaz laboratoriyalari, tashkilot va korxonalar tomonidan olib boriladigan ilmiy-tadqiqot ishlarining mavzu rejalarini muvofiqlashtiradi.
Belgilangan tematik rejalarning o‘z vaqtida va sifatli bajarilishi hamda muassasa faoliyati bo‘yicha yig‘ma hisobotlar tayyorlanishi ustidan nazoratni tashkil etadi.
Muassasa faoliyatining asosiy yo‘nalishlari, uning faoliyati samaradorligini oshirish, ish va boshqaruvni tashkil etishni takomillashtirish bo‘yicha reja va dasturlarni ishlab chiqishda muvofiqlashtirishni ta’minlaydi.
Ilmiy-texnikaviy kengash ish rejalari loyihalarini tayyorlaydi, ularning bajarilishini va kengash tomonidan qabul qilingan qarorlarning bajarilishini nazorat qiladi.
Ilmiy maqolalarni nashr etish rejalari bo‘yicha takliflar tayyorlaydi, ularning ko‘rib chiqilishini, shuningdek, ilmiy konferensiyalar, uchrashuvlar, seminarlar, muhokamalar o‘tkazilishini ta’minlaydi.
Xodimlarni attestatsiyadan o’tkazish, ularning faoliyatini baholash mezonlari va usullarini ishlab chiqishni tashkil qiladi.
Muassasaning ilmiy va yordamchi bo‘limlari faoliyatini muvofiqlashtiradi.
Ilmiy kadrlarni tayyorlash va ularning malakasini oshirish, ilmiy xodimlarning stajirovkalarini o‘tash bo‘yicha takliflar tayyorlaydi.`,
  },
];
export const councilRus = [
  {
    id: 2,
    img: img1,
    name: "Левицкая Юлия Владимировна",
    degree: "Кандидат биологических наук",
    number: " +99871 227-43-22 внутренный (45-06)",
    email: "y.levitskaya@cat-science.uz",
    heading: "Обязанности учённого секретаря:",
    btn: "Более",
    title1: "Биография",
    info1: `Левицкая Юлия Владимировна родилась 21 августа 1974 года в городе Ташкенте.В 2019 году участвовала в Саммите инноваторов (Дакар, Сенегал), проводимом Исламским банком развития.
      С 2019 г. Ю. Левицкая является членом Научного семинара Ученого совета Dsc.03/30.12.2019.V.01.13 по присуждению ученых степеней PhD и Dcs.
      Левицкая Ю.В. автор научных статей, опубликованных в престижных научных журналах, таких как Blood Cells, Molecules, and Diseases, Биофизический журнал, Rocznik Swietokrzyski,  Universum. Автор 4 учебников для студентов-биологов.
      `,
    title2: "Образование:",
    info2: [
      "В 1997 окончила Ташкентский государственный университет (в настоящее время Национальный университет Узбекистана им. Мирзо Улугбека) (вечернее отделение)",
    ],
    title3: "Ученая степень, звание:",
    info3: "Биофизик, кандидат биологических наук.",
    title4: "Профессиональная деятельность:",
    info4: [
      "С 5 сентября 2018 года Левицкая Ю. начала работу в должности ученого секретаря Центра передовых технологий при Министерстве инновационного развития Республики Узбекистан.",
      "Втаж работы 29 лет, научный стаж 23 года, стаж работы в органах управления 9 лет, работает в данной должности с 2018 года.",
    ],
    text: `Ученый секретарь возглавляет подготовку предложений по сводным перспективам и годовым планам научных исследований и разработок, а также осуществляет координацию тематических планов научно-исследовательских работ, выполняемых лабораториями Центра, организациями и предприятиями при совместном их проведении.
Организует контроль за своевременным и качественным выполнением установленных тематических планов и подготовку сводных отчетов о деятельности учреждения.
Обеспечивает координацию при разработке основных направлений работы учреждения, планов и программ повышения эффективности его деятельности, улучшения организации труда и управления.
Подготавливает проекты планов работы научно-технического совета, контролирует их выполнение и осуществление принятых советом решений.
Готовит предложения к планам издания научных трудов, обеспечивает их рецензирование, а также проведение научных конференций, совещаний, семинаров, дискуссий.
Организует проведение аттестации работников, разработку критериев и методов оценки их деятельности.
Координирует деятельность научно-вспомогательных  подразделений учреждения.
Готовит предложения по подготовке и повышению  квалификации научных кадров, стажировке научных сотрудников.`,
  },
];
export const councilEng = [
  {
    id: 1,
    img: img1,
    name: "Levitskaya Yuliya Vladimirovna",
    degree: "Scientific Secretary PhD in Biology",
    number: "+99871 227-43-22 internal(45-06)",
    email: "y.levitskaya@cat-science.uz",
    heading: "Scientific secretary duties:",
    btn: "More",
    title1: "Biography",
    info1: `Levitskaya Yulia Vladimirovna was born on August 21, 1974 in the city of Tashkent. In 2019, she participated in the Summit of Innovators (Dakar, Senegal), held by the Islamic Development Bank.
      Since 2019, Yu. Levitskaya has been a member of the Scientific Seminar of the Academic Council Dsc.03/30.12.2019.V.01.13 for the award of PhD and Dcs degrees.
      Levitskaya Yu.V. author of scientific articles published in prestigious scientific journals such as Blood Cells, Molecules, and Diseases, Biophysical Journal, Rocznik Swietokrzyski, Universum. Author of 4 textbooks for biology students.
      `,
    title2: "Education:",
    info2: [
      "In 1997 she graduated from Tashkent State University (currently the National University of Uzbekistan named after Mirzo Ulugbek) (evening department)",
    ],
    title3: "Academic degree, title:",
    info3: "Biophysicist, candidate of biological sciences",
    title4: "Professional activity:",
    info4: [
      "On September 5, 2018, Levitskaya Yu. began working as a scientific secretary of the Center for Advanced Technologies under the Ministry of Innovative Development of the Republic of Uzbekistan.",
      "Work experience 29 years, scientific experience 23 years, work experience in government bodies 9 years, has been working in this position since 2018.",
    ],
    text: `The Scientific Secretary leads the preparation of proposals for consolidated prospects and annual plans for research and development and also coordinates thematic plans for research work carried out by the laboratories of the Center, organizations, and enterprises in their joint implementation.
Seceretar’s duties include the following tasks:
-organizing control over the timely and high-quality implementation of established thematic plans and the preparation of summary reports on the activities of the institution.
-providing coordination in the development of the main directions of the work of the institution, plans and programs to improve the efficiency of its activities, improve the organization of work and management.
-preparing draft plans for the work of the scientific and technical council, controlling their implementation and the implementation of the decisions taken by the council.
-preparing proposals for plans for the publication of scientific papers; ensuring their review, as well as holding scientific conferences, meetings, seminars, and discussions.
-organizing the certification of employees, the development of criteria and methods for evaluating their activities.
-coordinating the activities of the scientific and auxiliary departments of the institution.
-preparing proposals for the training and advanced training of scientific personnel, internships for researchers.`,
  },
];

import React from "react";

export const LanguageContext = React.createContext();

const LanguageContextProvider = ({ children }) => {
  // eslint-disable-next-line
  const [language, setLanguage] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "uzb"
  );
  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 420px;
`;
export const Card = styled.div`
  width: 100%;
  max-width: 420px;
  height: 100%;
  max-height: 220px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    transition: all ease 0.5s;
  }

  .blur {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: rgba(208, 208, 208, 0.1);
  }

  .content {
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
    padding: 10px;
    align-self: flex-end;
    display: flex !important;
    background-color: rgba(49, 75, 132, 0.8);
    flex-direction: column !important;
    font-size: 10px;
    p {
      font-size: 14px;
      margin: 0;
    }
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
  :hover {
    box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -moz-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    cursor: pointer;
    img {
      transform: scale(1.1);
    }
    p {
      color: var(--hoverFontColor);
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .mySwiper {
    width: 100%;
    height: fit-content;
    display: block;
    position: relative;
    padding-top: 80px;
  }
  .box {
    width: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
    max-width: 450px;
    height: 100px;
    gap: 12px;
    background: var(--cardColor);
    img {
      width: 100%;
      max-width: 70px;
      height: 100%;
      max-height: 70px;
    }
    p {
      margin: 0;
    }
    :hover {
      p {
        color: var(--hoverFontColor);
      }
      background-color: rgba(49, 75, 132, 0.6);
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 30px;
    background-color: var(--cardColor);
    width: 48px;
    height: 50px;
    border-radius: 50px;
    z-index: 10111;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px;
    color: white;
  }
  .swiper-button-prev {
    left: 89%;
  }
  .swiper-button-next {
    right: 2%;
  }
  @media (max-width: 800px) {
    margin-bottom: 20px;
    h1 {
      font-size: 16px;
      margin: 0;
    }
    a{
      margin: 0;
    }
    p {
      font-size: 14px;
    }
    .mySwiper {
    padding-top: 50px;
  }
    .swiper-button-next,
    .swiper-button-prev {
      width: 26px;
      height: 26px;
    }
    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 14px;
      color: white;
    }
    .box {
      height: 70px;
      img {
        width: 100%;
        max-width: 50px;
        height: 100%;
        max-height: 50px;
      }
    }
    .swiper-button-prev {
      left: 80%;
    }
    .swiper-button-next {
      right: 2%;
    }
  }
`;

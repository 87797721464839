import React from "react";
import { Wrapper } from "./style";
import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { RiUserReceived2Line } from "react-icons/ri";
import { Button, Modal } from "antd";
import { useState } from "react";

const EmpoloyeeCard = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Wrapper>
      <div className="image">
        <img src={data?.img} alt="" />
      </div>
      <div className="content">
        <h3 className="name">{data?.name}</h3>
        <hr />
        <h3 className="position">{data?.position}</h3>
        <p>{data?.degree}</p>
        <p>
          <AiOutlinePhone /> {data?.number}
        </p>
        <p>
          <AiOutlineMail /> {data?.email}
        </p>
        <p>
          {data?.time ? (
            <>
              <RiUserReceived2Line />
              {data?.time}
            </>
          ) : (
            ""
          )}
        </p>
        <>
          <Button
            type="primary"
            onClick={showModal}
            style={{ display: "flex", alignItems: "center" }}
          >
            {data?.btn}
          </Button>
          <Modal
            title={data?.name}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            style={{ width: "80%", maxWidth: "800px", color: "black" }}
            cancelButtonProps={{
              style: { display: "none" },
            }}
            okButtonProps={{
              style: { display: "flex", alignItems: "center" },
            }}
          >
            <h3 style={{ color: "var(--hoverFontColor)", margin: "0" }}>
              {data?.title1}
            </h3>
            <p style={{ color: "black", margin: "0" }}>{data?.info1}</p>
            <h3 style={{ color: "var(--hoverFontColor)", margin: "0" }}>
              {data?.title2}
            </h3>
            <p style={{ color: "black", margin: "0" }}>
              {data?.info2 ? data?.info2[0] : data?.info2}
            </p>
            {data?.info2?.length > 1 ? <hr /> : ""}
            <p style={{ color: "black", margin: "0" }}>
              {data?.info2 ? data?.info2[1] : data?.info2}
            </p>
            <hr />
            <p style={{ color: "black", margin: "0" }}>
              {data?.info2 ? data?.info2[2] : data?.info2}
            </p>
            <h3 style={{ color: "var(--hoverFontColor)", margin: "0" }}>
              {data?.title3}
            </h3>
            <p style={{ color: "black", margin: "0" }}>{data?.info3}</p>
            <h3 style={{ color: "var(--hoverFontColor)", margin: "0" }}>
              {data?.title4}
            </h3>
            <p style={{ color: "black", margin: "0" }}>
              {data?.info4 ? data?.info4[0] : data?.info4}
            </p>
            {data?.info4?.length > 1 ? <hr /> : ""}
            <p style={{ color: "black", margin: "0" }}>
              {data?.info4 ? data?.info4[1] : data?.info4}
            </p>
            {data?.info4?.length > 2 ? <hr /> : ""}
            <p style={{ color: "black", margin: "0" }}>
              {data?.info4 ? data?.info4[2] : data?.info4}
            </p>
            {data?.info4?.length > 3 ? <hr /> : ""}
            <p style={{ color: "black", margin: "0" }}>
              {data?.info4 ? data?.info4[3] : data?.info4}
            </p>
            {data?.info4?.length > 4 ? <hr /> : ""}
            <p style={{ color: "black", margin: "0" }}>
              {data?.info4 ? data?.info4[4] : data?.info4}
            </p>
            <h3 style={{ color: "var(--hoverFontColor)", margin: "0" }}>
              {data?.title5}
            </h3>
            <p style={{ color: "black", margin: "0" }}>
              {data?.info5 ? data?.info5[0] : data?.info5}
            </p>
            <hr />
            <p style={{ color: "black", margin: "0" }}>
              {data?.info5 ? data?.info5[1] : data?.info5}
            </p>
            {data?.info5?.length > 2 ? <hr /> : ""}
            <p style={{ color: "black", margin: "0" }}>
              {data?.info5 ? data?.info5[2] : data?.info5}
            </p>
          </Modal>
        </>
      </div>
    </Wrapper>
  );
};

export default EmpoloyeeCard;

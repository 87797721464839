import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0;
  background-color: var(--cardColor);
  padding: 20px;

  .image-wrapper {
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }


  @media (max-width: 600px) {
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    margin: 20px 0;
  }
`;

import React from 'react'
import { useContext } from 'react';
import Laboratory from '../components/Laboratory/Laboratory';
import { LanguageContext } from '../context/languageContext';
import { biophysicsEng, biophysicsRus, biophysicsUzb } from '../data/laboratories/biophysics';

const LaboratoryofExperimentalBiophysics = () => {
   const [lang] = useContext(LanguageContext);

   if (lang === "uzb") {
     return (
       <Laboratory
         data={biophysicsUzb}
         title="Eksperimental biofizika laboratoriyasi"
       />
     );
   } else if (lang === "rus") {
     return (
       <Laboratory
         data={biophysicsRus}
         title="Лаборатория экпериментальной биофизики"
       />
     );
   } else if (lang === "eng") {
     return (
       <Laboratory
         data={biophysicsEng}
         title="Laboratory of experimental biophysics"
       />
     );
   }
}

export default LaboratoryofExperimentalBiophysics
import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  p {
    font-size: 14px;
    margin: 0;
    width: fit-content;
    white-space: nowrap;
  }
  @media (max-width: 1200px) {
    display: none;
  }
  @media (max-width: 800px) {
    font-size: 10px;
    display: block;
  }
`;

export const videos = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/InIYFqIMpZo",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/nfnr8xegd2I",
  },
  {
    id: 3,
    src: "https://www.youtube.com/embed/HG1vCMEfPKw",
  },
  {
    id: 4,
    src: "https://www.youtube.com/embed/82mRX_F1Xd4",
  },
  {
    id: 5,
    src: "https://www.youtube.com/embed/vFHxINsK73A",
  },
  {
    id: 6,
    src: "https://www.youtube.com/embed/jtbcaPLel4w?si=XKz1vnUv3QHaEGnM",
  },
  {
    id: 7,
    src: "https://www.youtube.com/embed/K7HvwqKdoSE?si=xu4YvvKF_zfRHtAn",
  },
  {
    id: 8,
    src: "https://www.youtube.com/embed/D7kJp6aLj00?si=CEMzGQG87HlX9p7Y",
  },
];

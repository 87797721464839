import img1 from "../../assets/images/laboratories/psychemic/1.JPG";
import img2 from "../../assets/images/laboratories/psychemic/2.JPG";
import img3 from "../../assets/images/laboratories/psychemic/3.JPG";
import img4 from "../../assets/images/laboratories/psychemic/4.JPG";
import img5 from "../../assets/images/laboratories/psychemic/5.JPG";
import img6 from "../../assets/images/laboratories/psychemic/6.JPG";

export const phsicochemicalUzb = {
  img: [img1, img2, img3, img4, img5, img6],
  title1: "Laboratoriya yo’nalishi ",
  text1:
    "Fizik-kimyoviy tadqiqotlar usullari laboratoriyasining asosiy ilmiy yoʼnalishi nanofizika va materialshunoslik, elektromagnit va korpuskulyar nurlanishning koʼp komponentli materiallar bilan oʼzaro taʼsiriga asoslangan funktsional materiallar va mahsulotlarni olishning murakkab analitik usullari va texnologiyalarini ishlab chiqish, shuningdek, organik moddalarni molekulyar ajratish va ularni identifikatsiya qilishdir.",
  title2:
    "Laboratoriya faoliyati quyidagi vazifalarni hal qilishga qaratilgan:",
  text2: `• fundamental va amaliy ilmiy tadqiqotlarni amalga oshirish;
  • taʼlim, ilmiy muassasalar va ishlab chiqarish korxonalari bilan ilmiy-texnikaviy hamkorlik;
  • olingan ilmiy natijalar va ishlab chiqilgan texnologiyalarni sanoat ishlab chiqarishiga joriy etish boʼyicha takliflar ishlab chiqish;
  • xalqaro dastur va loyihalarda ishtirok etish maqsadida xorijiy davlatlarning taʼlim va ilmiy muassasalari hamda jamgʼarmalari bilan xalqaro hamkorlik qilish;
  • analitik xizmatlar koʼrsatish.`,
  title3: "Yutuqlar va natijalar.",
  text3: `Murakkab noorganik va organik birikmalarni, shu jumladan geologik jinslar va sanoatda texnologik qayta ishlashdan olingan mahsulotlarning element, fazaviy, molekulyar va mikrostrukturaviy tahlil qilishning kompleks usullari ishlab chiqilgan. Markaz faoliyati davomida laboratoriya tomonidan respublika korxonalari va oliy oʼquv yurtlari bilan 300 dan ortiq shartnomalar asosida ilmiy ishlar amalga oshirildi.
  A3040 amaliy loyihasi doirasida raqamli golografik mikroskopning maketi ishlab chiqildi va “Raqamli golografik mikroskop” (№ FAP 01482) foydali modelga patent olindi.
  “Elektromagnit maydonlar bilan oʼzaro taʼsir qiluvchi faol elementlarni (katalizatorlar, sorbentlar, ingibitorlar, shuningdek, nanostrukturali materiallar) oʼrnatish tizimi” ilmiy ishlanmasiga mualliflik huquqini tasdiqlovchi sertifikat 
  (№ 002986) olindi.
  `,
  title4:
    "Laboratoriya tomonidan taqdim etiladigan analitik xizmatlar roʼyxati:",
  text4: `-	sirt morfologiyasi mikroskopiyasi, EVO MA10 SEM Carl Zeiss skanerlash elektron mikroskopida materiallarning sifat, miqdoriy lokal element tarkibi va elementlarning taqsimlanishining rastr koʼrinishi;
  -	metall va qotishmalarning, polikristal kukunli materiallarning, plyonkalarning kristall tuzilishini rentgen difraktsiyasi va rentgen fazalarini tahlil qilish va aniqlash;
  -	oʼta toza va koʼp komponentli namunalarning massa/zaryadi boʼyicha elementlar va izotoplar ionlarini standartlarga nisbatan sifat va miqdoriy tahlili;
  -	IQ mikroskopidan foydalangan holda IQ-Furьe spektrofotometri yordamida moddalar molekulyar tarkibini tahlil qilish va ularning sirtini xaritalash;
  -	spektrning UB va koʼrinish diapazonida materiallarning optik yutilish, oʼtkazish va qaytarish spektrlarini tahlil qilish (eritmalar, plyonkalar, shishalar, kristallar koʼrinishidagi namunalar);
  -	past va yuqori molekulyar ogʼirlikdagi moddalarni xromatografik ajratish va ularni Agilent 5977А mass detektori bilan Agilent 7890B gaz xromatografida identifikatsiya qilish;
  -	Agilent yuqori samarali suyuqlik xromatografida moddalarni ajratish va identifikatsiya qilish.`,
  title5: `Xalqaro aloqalar:`,
  text5: `1. Yadro tadqiqotlari birlashgan instituti, Rossiya, Dubna 
  2. Rossiya Fanlar Аkademiyasi P. N. Lébedev nomidagi Fizika Instituti, Rossiya, Moskva 
  3. Laboratory of Advanced Technology for Materials Synthesis and Processing Wuhan 
   University of Technology (WUT)
  4. School of Materials Science and Engineering, South China University of Technology, 
   Guangzhou, P.R.China`,
  title6: `Oʼzbekiston Respublikasi OTM va ITM bilan hamkorlik:`,
  text6: ` 1. Oʼzbekiston Milliy Universiteti
   2. Toshkent Davlat politexnika Universiteti
   3. Toshkent tuqimachilik va yengil sanoat instituti 
   4. Toshkent kimyo-texnologiyasi instituti
   5. OʼzR FА Yadro fizikasi instituti
   6. OʼzR FА Ion-plazma va lazer texnologiyalari instituti
   7. OʼzR FА Fizika-texnika instituti
   8. OʼzR FА Materialshunoslik instituti
   9. OʼzR FА Biorganik kimyo instituti
  10. OʼzR FА Oʼsimlik moddalar kimyosi instituti`,
  title7: `Sanoat va sanoat korxonalari bilan hamkorlik:`,
  text7: `«Oʼzkimyosanoat» АJ,
   Oʼzbekiston Respublikasi Qurilish vazirligi, 
   Oʼzbekiston Respublikasi Energetika vazirligi,
   “Navoiy kon-metallurgiya kombinati” АJ,
   “Olmaliq kon-metallurgiya kombinati” АJ `,
  title8: `Rivojlanish istiqbollari:`,
  text8: `Qayta tiklanuvchi energetika. Vodorod energetikasi uchun yarim oʼtkazgichli fotokatalitik materiallarni olish texnologiyasini ishlab chiqish.`,
  text9: `Biofizika. Аntibakterial xususiyatlarga ega mikro- va nanostrukturali metalloksidlarni tadqiq etish.
   Mikroorganizmlarning biologik xossalariga tashqi elektromagnit maydoni taʼsirini oʼrganish`,
  text10: `Qurilish. Past va yuqori faollikdagi modifikatsiyalangan sementdan foydalangan holda yuqori quvvatli yengil betonni ishlab chiqish`,
};
export const phsicochemicalRus = {
  img: [img1, img2, img3, img4, img5, img6],
  title1: "Направление лаборатории",
  text1:
    "Основным научным направлением лаборатории физико-химических методов исследования является нанофизика и материаловедение, развитие комплексных аналитических методов исследования и технологий получения функциональных материалов и изделий на основе взаимодействий электромагнитных и корпускулярных излучений с многокомпонентными микро- и наноструктурными материалами, а также молекулярное разделение и идентификация органических веществ.",
  title2: "Деятельность лаборатории направлена на решение следующих задач:",
  text2: `•	проведение фундаментальных и прикладных научных исследований;
  •	научно-техническое сотрудничество с образовательными, научными учреждениями и производственными предприятиями;
  •	разработка предложений по внедрению полученных научных результатов и разработанных технологий в промышленное производство;
  •	международное сотрудничество с образовательными и научными учреждениями и фондами зарубежных стран с целью участия в международных программах и проектах;
  •	оказание аналитических услуг.
  `,
  title3: "Достижения и результаты.",
  text3: `Разработаны комплексные методики элементного, фазового, молекулярного и микроструктурного анализа сложных неорганических и органических соединений, включая геологические  породы и продукты технологической обработки промышленности. С начала деятельности Центра лабораторией выполнены более 300 договоров с предприятиями и ВУЗами республики.
  В рамках прикладного проекта А3040 разработан макет цифрового голографического микроскопа и получен патент на полезную модель – «Цифровой голографический микроскоп» № FAP 01482.
  Получено свидетельство (№ 002986) об авторском праве на научную разработку «Система установки активных элементов (катализаторов, сорбентов, ингибиторов, а также наноструктурных материалов), взаимодействующих с электромагнитными полями».
  `,
  title4: "Перечень оказываемых аналитических услуг лабораторией:",
  text4: `микроскопия морфологии поверхности, качественный и количественный локальный элементный анализ материалов на сканирующем электронном микроскопе EVO MA10 SEM Carl Zeiss и растровая картина распределения элементов;
  рентгеноструктурный и рентгенофазовый анализ и установление кристаллической структуры металлов и сплавов, поликристаллических порошковых материалов, пленок;
  качественный и количественный анализ ионов элементов и изотопов по массе/заряду особо чистых и многокомпонентных проб в сравнении с эталонами;
  исследование молекулярного состава и картографирование поверхности веществ на ИК-Фурье спектрофотометре в комплекте с ИК — микроскопом;
  анализ спектров оптического поглощения, пропускания и отражения материалов в УФ и видимой области спектра (образцы в виде растворов, пленок, стекол, кристаллов);
  хроматографическое разделение низко и высокомолекулярных веществ и их идентификация на газовом хроматографе Agilent 7890B с масс-детектором Agilent 5977А;
  разделение и идентификация веществ на высокоэффективном жидкостном хроматографе Agilent.
  ”`,
  title5: `Международные связи:`,
  text5: `1. НИИ Объединенный институт ядерных исследований, Российская Федерация, г.Дубна
  2. Физи́ческий институ́т имени П. Н. Ле́бедева Российской АН, г.Москва
  3. Laboratory of Advanced Technology for Materials Synthesis and Processing Wuhan  
      University of Technology (WUT)
  4. School of Materials Science and Engineering, South China University of Technology, 
       Guangzhou, P.R.China
  `,
  title6: `Сотрудничество с ВУЗами и НИИ РУз:`,
  text6: `  1.   Национальный Университет Узбекистана
  2.   Ташкентский Государственный Политехнический Университет
  3.   Ташкентский институт текстильной и легкой промышленности 
  4.   Ташкентский химико-технологический институт
  5.   Институт ядерной физики АН РУз
  6.   Институт ионно-плазменных и лазерных технологий АН РУз
  7.   Физико-технический институт АН РУз
  8.   Институт материаловедения АН РУз
  9.   Институт Биоорганической химии АН РУз
10.   Институт Химии растительных веществ АН РУз
`,
  title7: `Сотрудничество с отраслями и промышленными предприятиями:`,
  text7: `АО «Узкимёсаноат»,
  Министерство строительства РУз,
  Министерство Энергетики РУз,
  АО “Навоийский горно-металлургический комбинат,”
  АО “Алмалыкский горно-металлургический комбинат”
  `,
  title8: `Перспективы развития лаборатории:`,
  text8: `Возобновляемая энергетика. Разработка технологии получения полупроводниковых фотокаталитических материалов для водородной энергетики.`,
  text9: `Биотехнология. Исследование металлооксидных микро- и наноструктур с антибактериальными свойствами.
  Влияние воздействия внешних электромагнитных полей на биологические свойства микроорганизмов.
  `,
  text10: `Строительство. Разработка высокопрочного легкого бетона с использованием цемента низкой и модифицированного цемента высокой активности.`,
};
export const phsicochemicalEng = {
  img: [img1, img2, img3, img4, img5, img6],
  title1: "Laboratory direction",
  text1:
    "The laboratory of physical and chemical methods of research have following scientific directions:  nanophysics and material science, development of complex analytical methods of research and technologies for obtaining functional materials and products. These methods based on interactions of electromagnetic and corpuscular irradiation with multi-component micro- and nanostructured materials, as well as molecular separation and identification of organic substances.",
  title2: "The laboratory activities are focused in the following tasks:",
  text2: `- Fundamental and applied scientific research;
  - Scientific and technical cooperation with educational, scientific institutions and R&D enterprises;
  - Development of recommendation for implementation of obtained scientific results and developed technologies in industrial production;
  - International cooperation with foreign educational and worldwide scientific institutions and foundations in order to participate in international programs and projects;
  - providing of analytical services.
  `,
  title3: "Achievements and results.",
  text3: `Complex methods of elemental, phase, molecular and microstructural analysis of complex inorganic and organic compounds, including geological formations and products of technological treatment in industry were developed. Since Centre activity the laboratory were accomplished more than 300 contracts with enterprises and universities of the republic.
  The prototype of digital holographic microscope is developed and a patent for the useful model "Digital holographic microscope" № FAP 01482 was received in the framework of applied project А3040.
  The certificate of copyright (No. 002986) for scientific development "System of installation of active elements (catalysts, sorbents, inhibitors as well as nanostructured materials) interacting with electromagnetic fields" was obtained.
  `,
  title4: "List of the analytical services provided by the laboratory:",
  text4: `Surface morphology microscopy, qualitative and quantitative local elemental composition of inorganic materials on an EVO MA10 SEM Carl Zeiss scanning electron microscope and elements distribution map;
  X-ray structural and X-ray phase analysis and determination of crystal structure  of metals and alloys, polycrystalline powders and films;
  Qualitative and quantitative analysis of element ions and isotopes by mass/charge of extremely pure and multi-component samples compared to standards;
  Investigation of the molecular composition and surface mapping of substances using a FTIR spectrophotometer coupled with an IR microscope;
  Analysis of the spectra of optical absorption, transmittance and reflection of materials in the UV and visible spectrum (samples in the form of solutions, films, glasses, crystals);
  Chromatographic separation and identification of low and high molecular weight substances using an Agilent 7890B gas chromatograph with an Agilent 5977A mass detector
  Separation and identification of substances on an Agilent high-performance liquid chromatograph.  
`,
  title5: `International relations:`,
  text5: `1. The Joint Institute for Nuclear Research, Russian, Dubna
  2. The P.N. Lebedev Physical Institute,  Russian, Moscow 
  3. Laboratory of Advanced Technology for Materials Synthesis and Processing Wuhan  
      University of Technology (WUT)
  4. School of Materials Science and Engineering, South China University of Technology, 
       Guangzhou, P.R.China
  `,
  title6: `Cooperation with Universities and Scientific Institution: `,
  text6: `  1.   National University of Uzbekistan
  2.  Tashkent State Polytechnic University
  3.   Tashkent Textile and Light Industry Insitute
  4.   Tashkent Chemical Technological Institute
  5.   Institute of Nuclear Physics, Academy of Sciences of the Republic of Uzbekistan
  6.   Insitute of Ion-Plasma and Laser Technologies, Academy of Sciences of the Republic of Uzbekistan
  7.   Physical-technical Institute, Academy of Sciences of the Republic of Uzbekistan
  8.   Institute of Materialscience, Academy of Sciences of the Republic of Uzbekistan
  9.   Institute of Bioorganic Chemistry, Academy of Sciences of the Republic of Uzbekistan
10.   Institute of Chemistry of Plant Substances, Academy of Sciences of the Republic of Uzbekistan
`,
  title7: `Cooperation with industrial branches and  companies:`,
  text7: `Joint Stock Company "Uzkimyosanoat" ,
Ministry of Construction  Republic of Uzbekistan,
Ministry of Energy Republic of Uzbekistan,
“Navoi Mining and Metallurgical Company” Joint-Stock Company,
“Almalyk Mining and Metallurgical Combine” Joint-Stock Company
`,
  title8: `Prospects for the development of the laboratory:`,
  text8: `Renewable energy. Technological development of semiconductor photocatalytic materials for hydrogen energy.`,
  text9: `Biotechnology. Investigation of metal-oxide micro- and nanostructures with antibacterial properties.
Influence of external electromagnetic fields on biological properties of microorganisms.
`,
  text10: `Construction materials. Development of Lightweight high strength concrete by using low-activity cement and modified high-activity cement.`,
};

import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #051f59;
`;

export const Container = styled.div`
  width: 100%;
  max-width: var(--container);
  padding: var(--padding);
  @media (max-width: 900px) {
    padding: 0 40px;
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

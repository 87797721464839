import React, { useContext } from "react";
import Employee from "../components/Employee/Employee";
import { LanguageContext } from "../context/languageContext";
import {
  adminstrationEng,
  adminstrationRus,
  adminstrationUzb,
} from "../data/adminstration";

const Adminstration = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb")
    return <Employee data={adminstrationUzb} title="Rahbariyat" />;
  else if (lang === "rus")
    return <Employee data={adminstrationRus} title="Руководство" />;
  else if (lang === "eng")
    return <Employee data={adminstrationEng} title="Administration" />;
};

export default Adminstration;

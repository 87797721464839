import React, { useContext } from "react";
import { useState } from "react";
import { LanguageContext } from "../../context/languageContext";
import { images } from "../../data/photogallery";
import { Icon, Wrapper } from "./style";

const PhotogalleryComp = () => {
  const [data, setData] = useState({ img: "" });
  const viewImage = (image) => {
    setData({ img: image });
  };
  const [lang] = useContext(LanguageContext);
  return (
    <Wrapper>
      <h1>
        {lang === "uzb"
          ? "Fotogalereya"
          : lang === "rus"
          ? "Фотогалерея"
          : "Photogallery"}
      </h1>
      {data.img && (
        <div className="modal-wrapper" onClick={() => setData({ img: "" })}>
          <img src={data?.img} alt="" />
          <div className="icon-wrapper" onClick={() => setData({ img: "" })}>
            <Icon.x />
          </div>
        </div>
      )}
      <div className="wrapper-images">
        {images.map((image, index) => {
          return (
            <img
              key={index}
              src={image}
              alt=""
              onClick={() => viewImage(image)}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};

// <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
//   <Masonry gutter="10px">
//     {images.map((image, i) =>
//       i % 2 === 0 ? (
//         <img
//           key={i}
//           src={image}
//           style={{ width: "100%", display: "block", cursor: "pointer" }}
//           alt=""
//           height={400}
//           onClick={() => viewImage(image)}
//         />
//       ) : (
//         <img
//           key={i}
//           src={image}
//           alt=""
//           height={300}
//           style={{ width: "100%", display: "block", cursor: "pointer" }}
//           onClick={() => viewImage(image)}
//         />
//       )
//     )}
//   </Masonry>
// </ResponsiveMasonry>;

export default PhotogalleryComp;

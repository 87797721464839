import React from "react";
import { LanguageContext } from "../../context/languageContext";
import { Hero, Wrapper } from "./style";
import img from "../../assets/images/unionCommitte/img.jpg";
import img1 from "../../assets/images/unionCommitte/1.png";
import img2 from "../../assets/images/unionCommitte/7.jpg";
import img3 from "../../assets/images/unionCommitte/3.jpg";
import img4 from "../../assets/images/unionCommitte/4.jpg";
import img5 from "../../assets/images/unionCommitte/5.jpg";
const UnionCommitteeComp = () => {
  const [lang] = React.useContext(LanguageContext);
  const {
    img,
    titleUzb,
    textUzb,
    text2Uzb,
    text3Uzb,
    titleRus,
    textRus,
    text2Rus,
    text3Rus,
    titleEng,
    textEng,
    text2Eng,
    text3Eng,
  } = data;
  if (lang === "uzb") {
    return (
      <Wrapper>
        <div className="header">
          <h1>{titleUzb}</h1>
        </div>
        <Hero>
          <div className="description">
            <div className="img-wrapper">
              <img src={img} alt="" />
            </div>
            <p>{textUzb}</p>
          </div>

          <div className="content">
            <div className="content-1">
              <p>{text2Uzb}</p>
              <img src={img1} alt="" />
            </div>
            <div className="content-2">
              <p>{text3Uzb}</p>
              <div className="images">
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
                <img src={img5} alt="" />
              </div>
            </div>
          </div>

          <div className="staff">
            <p>Kasaba uyushmasi qo‘mitasi raisi: Rejapova M.</p>
            <p>Kasaba uyushmasi qo‘mitasi raisi o‘rinbosari: Rafikova F.</p>
            <p>
              Kasaba uyushmasi qo‘mitasi a’zolari: Sariev A., Lutfulleyev I.,
              Tojinazarov F.
            </p>
          </div>
        </Hero>
      </Wrapper>
    );
  } else if (lang === "rus") {
    return (
      <Wrapper>
        <div className="header">
          <h1>{titleRus}</h1>
        </div>
        <Hero>
          <div className="description">
            <div className="img-wrapper">
              <img src={img} alt="" />
            </div>
            <p>{textRus}</p>
          </div>

          <div className="content">
            <div className="content-1">
              <p>{text2Rus}</p>
              <img src={img1} alt="" />
            </div>
            <div className="content-2">
              <p>{text3Rus}</p>
              <div className="images">
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
                <img src={img5} alt="" />
              </div>
            </div>
          </div>

          <div className="staff">
            <p>Председатель профкома: Режапова М.</p>
            <p>Заместитель председателя профкома: Рафикова Ф.</p>
            <p>Члены профкома: Сариев А., Лутфуллаев И. Тожиназаров Ф. </p>
          </div>
        </Hero>
      </Wrapper>
    );
  } else if (lang === "eng") {
    return (
      <Wrapper>
        <div className="header">
          <h1>{titleEng}</h1>
        </div>
        <Hero>
          <div className="description">
            <div className="img-wrapper">
              <img src={img} alt="" />
            </div>
            <p>{textEng}</p>
          </div>

          <div className="content">
            <div className="content-1">
              <p>{text2Eng}</p>
              <img src={img1} alt="" />
            </div>
            <div className="content-2">
              <p>{text3Eng}</p>
              <div className="images">
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
                <img src={img5} alt="" />
              </div>
            </div>
          </div>

          <div className="staff">
            <p>Chairman of the trade union committee: Rejapova M.</p>
            <p>Deputy Chairman of the Trade Union Committee: Rafikova F.</p>
            <p>
              Members of the trade union committee: Sariev A., Lutfulleyev I.,
              Tojinazarov F.
            </p>
          </div>
        </Hero>
      </Wrapper>
    );
  }
};

const data = {
  img,
  titleUzb: "Kasaba uyushmasi",
  textUzb: `
Kasaba uyushmalari faoliyatining maqsadi xodimlarning mehnat munosabatlari va ijtimoiy huquqlari sohasidagi manfaatlarini himoya qilishdan iborat. Albatta, asosan madaniy tadbirlarni tashkil etish, sanatoriy va dam olish uylarida davolanishni tashkil etish va hokazolar qiziqish doirasiga kiradi.
2019-yil 6-dekabrdagi “Kasaba uyushmalari to‘g‘risida”gi O‘RQ-588-son Qonuni 9 bob va 56 moddadan iborat. Qonunning maqsadi fuqarolarning kasaba uyushmalarini tuzish huquqini amalga oshirishi, shuningdek kasaba uyushmalarini tashkil etish va faoliyatini tashkil etish sohasidagi munosabatlarni tartibga solishdan iborat.
Ijtimoiy sheriklik asosida ish beruvchilar va kasaba uyushmalari o‘rtasida mehnatkashlar huquqlarini himoya qilish, barcha sohalarda islohotlar olib borish borasida hamkorlik yo‘lga qo‘yilgan. Kasaba uyushmasi eng nufuzli jamoat tashkilotlaridan biri bo‘lib, ijtimoiy adolatni ta’minlash, haqiqatan ham yuqori ijtimoiy himoyaga muhtoj mehnatkashlarga ijtimoiy nafaqa va imtiyozlar berilishini ta’minlashga salmoqli hissa qo‘shmoqda.
Markaz kasaba uyushma qo‘mitasi O‘zbekiston Respublikasi Fanlar akademiyasi birlashgan kasaba uyushma qo‘mitasi tomonidan o‘tkaziladigan kollegial yig‘ilishlar, qurultoylar va boshqa tadbirlarda ishtirok etadi.
`,
  text2Uzb:
    "Biotexnologiya laboratoriyasi kichik ilmiy hodimi Umarova Shahnoza –Birlashgan kasaba Yushmasi tomonidan  O’zbekiston respublikasi Fanlar akademiyasi 80 yilligiga bag’ishlangan “AKADEMIADA - 2023” sport musobaqalarining “AKADEMKROSS” turi bo’yicha 20 – 45 yoshgacha ayollar o’rtasida faxrli 1 o’rinni egalladi. 14.05.2023",
  text3Uzb:
    "Kasaba yushmasi tomonidan 8-mart xalqaro xotin qizlar kuniga markaz ayol hodimlari o’rtasida shaxmat va shashka sport turlari bo’yicha musobaqa o’tkazildi. G’oliblar esdalik sovg’alar bilan taqdirlandi. Shuningdek kasaba yushmasi tomonidan barcha markaz ayol hodimlari esdalik sovgalar bilan taqdirlandi.",
  titleRus: " Профсоюзный комитет",
  textRus: `
Целью деятельности профсоюзов является защита интересов работников в сфере трудовых отношений и социальных прав. Конечно, главным образом в сфере интересов лежит и организации культурно-массовых мероприятий, организации лечения в санаториях и домах отдыха, и др.
В законе N ЗРУ-588 «О ПРОФЕССИОНАЛЬНЫХ СОЮЗАХ» от 6 декабря 2019 года содержатся 9 глав и 56 статей. Целью Закона является регулирование отношений в области реализации гражданами права на объединение в профессиональные союзы, а также создания и организации деятельности профессиональных союзов.
На основе социального партнерства работодатели и профсоюзы наладили сотрудничество по защите прав работников и проведении реформ во всех сферах. Профсоюз является одной из влиятельнейших общественных организаций и вносит весомый вклад в дело отправления социальной справедливости, обеспечивая предоставление социальных льгот и пособий работникам, действительно нуждающимся в высокой социальной защите.
Профсоюзный комитет Центра участвует в собраниях коллегиях, курултаях и др.мероприятиях проводимые объединённым профсоюзным комитетом Академии наук Республики Узбекистан.
`,
  text2Rus:
    "Младший научный сотрудник лаборатории биотехнологии Умарова Шахноза –заняла почетное 1 место в спортивном соревновании “АКАДЕМКРОСС” среди женщин в возрасте от 20 до 45 лет в “АКАДЕМИАДА - 2023”, организованный Профсоюзом. ",
  text3Rus:
    "В международный женский день, профсоюзом было организовано спортивные соревнования по шахматам и шашкам среди женского состава центра. Победители были награждены памятными подарками. Также в честь праздника Профсоюз поздравил всез женщин центра и вручил памятные подарки.  ",

  titleEng: "Union committee",
  textEng: `
The purpose of the activity of trade unions is to protect the interests of workers in the field of labor relations and social rights. Of course, mainly in the sphere of interest lies the organization of cultural events, the organization of treatment in sanatoriums and rest homes, etc.
Law N ZRU-588 “On Trade Unions” dated December 6, 2019 contains 9 chapters and 56 articles. The purpose of the Law is to regulate relations in the field of realization by citizens of the right to form trade unions, as well as the creation and organization of the activities of trade unions.
On the basis of social partnership, employers and trade unions have established cooperation to protect the rights of workers and carry out reforms in all areas. The trade union is one of the most influential public organizations and makes a significant contribution to the cause of social justice, ensuring the provision of social benefits and benefits to workers who really need high social protection.
The trade union committee of the Center participates in collegiate meetings, kurultais and other events held by the united trade union committee of the Academy of Sciences of the Republic of Uzbekistan.
`,
  text2Eng:
    "Junior researcher of the Laboratory of Biotechnology Umarova Shakhnoza – took the honorable 1st place in the sports competition “ACADEMCROSS” among women aged 20 to 45 years in the “ACADEMIADA - 2023”, organized by the Trade Union.",
  text3Eng:
    "On International Women's Day, the trade union organized sports competitions in chess and checkers among the female staff of the center. The winners were awarded with memorable gifts. Also, in honor of the holiday, the Trade Union congratulated all the women of the center and presented memorable gifts.",
};

export default UnionCommitteeComp;

/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { LanguageContext } from "../../context/languageContext";
import { Container, Icon, Main, Section, Wrapper } from "./style";
import VisitorCounter from "../visitorCounter/VisitorCounter";

const Footer = ({ home }) => {
  const [language] = React.useContext(LanguageContext);
  if (language === "uzb") {
    return (
      <Main home={home}>
        <Container home={home}>
          <Section>
            <h1>Bog'lanish</h1>
          </Section>
          <Wrapper>
            <Section>
              <p>Manzil:</p>
              <p>
                100174, Toshkent sh., Olmazor tumani., Universitet ko‘chasi., 3a
                uy
              </p>
              <p className="busses">
                "Beruniy" metro bekati, 11, 28, 31, 78, 88, 116 yo‘nalishli
                avtobuslarning "Beruniy" bekati.
              </p>
            </Section>
            <Section>
              <p>Telefon:</p>
              <a href="tel:+998712274321">+998 (71) 227-43-21</a>
              <p>Ishonch telefon:</p>
              <a href="tel:+998712274319">+998 (71) 227-43-19</a>
            </Section>
            <Section>
              <p>Faks:</p>
              <p>+998 (71) 203-43-21</p>
            </Section>
            <Section>
              <p>Pochta:</p>
              <a href="mailto:info@cat-science.uz">info@cat-science.uz</a>
              <p>
                Exat: <a href="mailto:catscience@exat.uz">catscience@exat.uz</a>
              </p>
            </Section>
            <Section>
              <p>Ijtiomoiy tarmoqlar:</p>
              <Wrapper>
                <div className="icons-wrapper">
                  <a
                    target={"_blank"}
                    href="https://www.facebook.com/uzadvanced/"
                    rel="noreferrer"
                  >
                    <Icon.Facebook />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://www.instagram.com/ilgor_texnologiyalar_markazi/?igshid=YmMyMTA2M2Y%3D"
                    rel="noreferrer"
                  >
                    <Icon.Instagram size={22} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://t.me/uzadvanced/"
                    rel="noreferrer"
                  >
                    <Icon.Telegram size={22} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://www.youtube.com/channel/UCTChaxFa_1BifF74Sf-4e9Q"
                    rel="noreferrer"
                  >
                    <Icon.Youtube size={26} />
                  </a>
                </div>
              </Wrapper>
            </Section>
          </Wrapper>
          <Wrapper>
            <div className="iframe-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.1276329276257!2d69.19919013977044!3d41.34957946088043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8d52ef6b33ff%3A0xf0554c5f2bc6a265!2sInnovatsion%20G'oylalarni%20rivojlantirish%20yoshlar%20jamoasi!5e0!3m2!1sru!2s!4v1665334489793!5m2!1sru!2s"></iframe>
            </div>
            <div className="working-days-wrapper">
              <h1>Ish kunlari</h1>
              <div className="working-days">
                <p>Dushanba - Juma : 9:00 - 18:00</p>
                <p className="lunch">Tushlik vaqti : 13:00 - 14:00</p>
                <VisitorCounter />
              </div>
            </div>
          </Wrapper>
        </Container>
      </Main>
    );
  } else if (language === "rus") {
    return (
      <Main home={home}>
        <Container home={home}>
          <Section>
            <h1>Связь</h1>
          </Section>
          <Wrapper>
            <Section>
              <p>Адрес:</p>
              <p>
                100174, г. Ташкент, Алмазарский р-н, ул. Университетская, дом 3a
              </p>
              <p className="busses">
                Станция метро — «Беруний», Остановка «Беруний» — автобусы № 11,
                28, 31, 78, 88, 116.
              </p>
            </Section>
            <Section>
              <p>Телефон:</p>
              <a href="tel:+998712274321">+998 (71) 227-43-21</a>
              <p>Доверенный телефон:</p>
              <a href="tel:+998712274319">+998 (71) 227-43-19</a>
            </Section>
            <Section>
              <p>Факс:</p>
              <p>+998 (71) 203-43-21</p>
            </Section>
            <Section>
              <p>Почта:</p>
              <a href="mailto:info@cat-science.uz">info@cat-science.uz</a>
              <p>
                Exat: <a href="mailto:catscience@exat.uz">catscience@exat.uz</a>
              </p>
            </Section>
            <Section>
              <p>Мы в соц.сетях:</p>
              <Wrapper>
                <div className="icons-wrapper">
                  <a
                    target={"_blank"}
                    href="https://www.facebook.com/uzadvanced/"
                    rel="noreferrer"
                  >
                    <Icon.Facebook />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://www.instagram.com/ilgor_texnologiyalar_markazi/?igshid=YmMyMTA2M2Y%3D"
                    rel="noreferrer"
                  >
                    <Icon.Instagram size={22} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://t.me/uzadvanced/"
                    rel="noreferrer"
                  >
                    <Icon.Telegram size={22} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://www.youtube.com/channel/UCTChaxFa_1BifF74Sf-4e9Q"
                    rel="noreferrer"
                  >
                    <Icon.Youtube size={26} />
                  </a>
                </div>
              </Wrapper>
            </Section>
          </Wrapper>
          <Wrapper>
            <div className="iframe-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.1276329276257!2d69.19919013977044!3d41.34957946088043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8d52ef6b33ff%3A0xf0554c5f2bc6a265!2sInnovatsion%20G'oylalarni%20rivojlantirish%20yoshlar%20jamoasi!5e0!3m2!1sru!2s!4v1665334489793!5m2!1sru!2s"></iframe>
            </div>
            <div className="working-days-wrapper">
              <h1>Рабочие дни:</h1>
              <div className="working-days">
                <p>Понедельник - Пятница: 9:00 - 18:00</p>
                <p className="lunch">Время обеда : 13:00 - 14:00</p>
                <a
                  href="http://www.uz/ru/res/visitor/index?id=47120"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="http://cnt0.www.uz/counter/collect?id=47120&pg=http%3A//uzinfocom.uz&&col=340F6E&amp;t=ffffff&amp;p=BD6F6F"
                    width="88"
                    height="31"
                    border="0"
                    alt="Топ рейтинг www.uz"
                  />
                </a>
              </div>
            </div>
          </Wrapper>
        </Container>
      </Main>
    );
  } else if (language === "eng") {
    return (
      <Main home={home}>
        <Container home={home}>
          <Section>
            <h1>Contacts</h1>
          </Section>
          <Wrapper>
            <Section>
              <p>Address:</p>
              <p>
                100174, Tashkent city, Almazor district, University street, 3a
                house
              </p>
              <p className="busses">
                Metro Station - "Beruniy", Bus stop "Beruniy", Bus number 11,
                28, 31, 78, 88, 116.
              </p>
            </Section>
            <Section>
              <p>Phone number:</p>
              <a href="tel:+998712274321">+998 (71) 227-43-21</a>
              <p>Trusted phone number :</p>
              <a href="tel:+998712274319">+998 (71) 227-43-19</a>
            </Section>
            <Section>
              <p>Fax:</p>
              <p>+998 (71) 203-43-21</p>
            </Section>
            <Section>
              <p>E-mail:</p>
              <a href="mailto:info@cat-science.uz">info@cat-science.uz</a>
              <p>
                Exat: <a href="mailto:catscience@exat.uz">catscience@exat.uz</a>
              </p>
            </Section>
            <Section>
              <p>Social networks:</p>
              <Wrapper>
                <div className="icons-wrapper">
                  <a
                    target={"_blank"}
                    href="https://www.facebook.com/uzadvanced/"
                    rel="noreferrer"
                  >
                    <Icon.Facebook />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://www.instagram.com/ilgor_texnologiyalar_markazi/?igshid=YmMyMTA2M2Y%3D"
                    rel="noreferrer"
                  >
                    <Icon.Instagram size={22} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://t.me/uzadvanced/"
                    rel="noreferrer"
                  >
                    <Icon.Telegram size={22} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://www.youtube.com/channel/UCTChaxFa_1BifF74Sf-4e9Q"
                    rel="noreferrer"
                  >
                    <Icon.Youtube size={26} />
                  </a>
                </div>
              </Wrapper>
            </Section>
          </Wrapper>
          <Wrapper>
            <div className="iframe-wrapper">
              <iframe
                title="locaation of cpt"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.1276329276257!2d69.19919013977044!3d41.34957946088043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8d52ef6b33ff%3A0xf0554c5f2bc6a265!2sInnovatsion%20G'oylalarni%20rivojlantirish%20yoshlar%20jamoasi!5e0!3m2!1sru!2s!4v1665334489793!5m2!1sru!2s"
              ></iframe>
            </div>
            <div className="working-days-wrapper">
              <h1>Working days:</h1>
              <div className="working-days">
                <p>Monday - Friday: 9:00 - 18:00</p>
                <p className="lunch">Lunch time : 13:00 - 14:00</p>
                <a
                  href="http://www.uz/ru/res/visitor/index?id=47120"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="http://cnt0.www.uz/counter/collect?id=47120&pg=http%3A//uzinfocom.uz&&col=340F6E&amp;t=ffffff&amp;p=BD6F6F"
                    width="88"
                    height="31"
                    border="0"
                    alt="Топ рейтинг www.uz"
                  />
                </a>
              </div>
            </div>
          </Wrapper>
        </Container>
      </Main>
    );
  }
};

export default Footer;

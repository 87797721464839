import img from "../../assets/images/laboratories/phsicochemical.jpg";
export const biophysicsUzb = {
  img: [img, img, img, img, img, img],
  title1: "Faoliyat yo‘nalishlari",
  text1: `Moddalarning biologik faolligini skrining qilish va ular ta’sirining molekular mexanizmlarini ochish;
Yangi bioregulyatorlarni izlash;
Patologiyalar rivojlanishining molekular mexanizmlarini o‘rganish maqsadida kasalliklar modellarini yaratish (in vitro, in vivo, in silico);
Molekulalararo ta’sir mexanizmlarini ochish uchun bioinformatika usullarini qo‘llash;
Hujayraviy biotibbiyot texnologiyalarini ishlab chiqish;
Atrof muhit omillariga o‘simliklarning adaptiv reaksiyasini shakllantirish mexanizmlarini o‘rganish;               `,
  title2: "Ilmiy tadqiqot loyihalari",
  text2: `Fundamental loyiha proekt FZ-20200929214 «Sutemizuvchilar Hujayralarida signal transduksiyasida noorganik polifosfatlarning roli» 2021 –2026 yillar, loyiha rahbari PhD, Baev A.Yu. (eng: Fundamental project: FZ-20200929214 «The role of inorganic polyphosphates in signaltransduction in mammalian cells», 2021 – 2026., PI, Artyom Y. Baev, PhD)
Amaliy loyiha I-IRV-2021-463 «Biotexnologik maxsulotlarning ilmiy ishlanmalarini yaratish jarayonlarini monitoring qilish uchun raqamli platforma ishlab chiqish » 2021 – 2023 yillar, loyiha rahbari f.n., Leviskaya Yu.V.
Amaliy loyiha PZ-2021030217 «Inson va hayvonlarning patologik holatlari va kasalliklarining in vitro va in vivo imitatsion modellarini yaratish » 2021-2024 yillar, loyiha rahbari PhD, Baev A.Yu. (eng: Applied project: PZ-2021030217 «Development of in vitro and in vivo simulation models of pathological conditions and diseases in humans and animals – model of ischemic stroke», 2021-2024, PI, Artyom Y. Baev, PhD;
Amaliy loyiha AL-36-2105821c “Inson va xayvonlar patologik holatlari va kasalliklarining in silico imitasion modellarini ishlab chiqish” 2022-2025 yillar, loyiha rahbari Dsc, Razzokov J.I. (eng: Applied project: № AL-36-2105821c «Development of in silico simulation models of pathological conditions and diseases in humans and animals» 2022-2025, PI, Jamoliddin I. Razzokov, DSc )
Amaliy loyiha PZ-20170930211 «Mahalliy o‘simlik homashyosidan ajratib olingan proantotsianidinlarni metabolizmning istiqbolli regulyatorlari sifatida antigipoksant xususiyatlarini skrining qilish», 2018-2020 yillar, loyiha rahbari f.n. Radjabova G.G.
Fundamental loyiha BV-M-F5-001 «Semiarid zonalar sharoitida o‘simliklar stressga chidamliligining molekular mexanizmlari» 2017-2020 yillar, loyiha rahbari f.n. Leviskaya Yu.V.
Yoshlar loyihasi MU-F3-20171025118 “Noorganik polifosfatlar miqdorini boshqarish –  ishemiya-reperfuziyaning oldini olish vositasi sifatida”, 2018 – 2019 yillar, loyiha rahbari PhD, Baev A.Yu
Yoshlar loyihasi MU-FZ-2017102543 «O‘zbekiston dorivor o‘simliklarining birlamchi metabolitlarini tahlil qilish va ularning atrof-muhitning noqulay omillariga moslashish jarayonlaridagi roli.», 2018 – 2019 yillar, loyiha rahbari Raxmatullina N.Sh.`,
  title3: "  2021-2025 yillarda faoliyatining ustuvor yo‘nalishlari:",
  text3: `Farmakologik moddalar va dori vositalarini preklinik in vitro  sinovdan o‘tkazish tizimini ishlab chiqish. Yo‘nalish rahbari Leviskaya Yu.V.
Biologik faol birikmalarning organizmdagi farmakologik nishonlari  bilan o‘zaro ta’sirini modellashtirish, in silico skrining va dori vositalari dizayni uchun molekular doking usullarini qo‘llash. Baev. A.Yu.
Patologiyalar rivojlanishining molekular va membrana mexanizmlarini o‘rganish uchun modellarni yaratish (in vitro, in vivo, in silico). Yo‘nalish rahbarlari Baev A.Yu., Sariev A.U., Razzokov J.I.
Sutemizuvchilar hujayralarida bioregulyatorlarning yangi sinfi sifatida noorganik polifosfatlarni o‘rganish. Yo‘nalish rahbari Baev. A.Yu.
Organizmda dori vositalarini nishonga maqsadli etkazib berish muammosini hal qilish yo‘lllarini topish. Yo‘nalish rahbari Charыshnikova O.S.
Qishloq xo‘jaligi o‘simliklarining abiotik muhit omillariga chidamliligini tahliliy prognozlashni ishlab chiqish. Yo‘nalish rahbari Raxmatullina N.Sh.`,
  title4: "  Xalqaro Hamkorlik",
  text4: `1. Laboratoriya prof. Andrey Y. Abramov, Klinik va harakat nevrologiyasi bo’limi, UCL Queen Square Nevrologiya instituti, Queen Square, London WC1N 3BG, Buyuk Britaniya
2. Laboratoriya prof. Evgeniy Pavlov, stomatologiya kolleji, Nyu-York universiteti, Nyu-York, Nyu-York, AQSh.
3. Laboratoriya kletochnoy fiziki patologii, Orlovskiy Gosudarstvennyy Universitet, Oryol, Rossiya
4. Laboratoriya vnutrikletochnoy signalizatsii, Institut Biofiziki Kletki RAN, g. Pushchino, Rossiya
5. GNU «Institut biofiziki i kletochnoy injeneriii NAN Belarusiya»
6. FGAOU VO «Kazanskiy(Privoljskiy) Federal universitet» Rossiya Federatsiyasi»`,
  title5: `Reytingli ilmiy maqolalar`,
  text5: `(Influence of RNA interference of phytochrome A1 gene on activity of antioxidant system in cotton. V. S. Kamburova, K. A. Ubaydullaeva, Sh. E. Shermatov, Z.T. Buriev, O. S. Charishnikova, K.S. Nebesnaya, O. A. Sukocheva. Physiological and Molecular Plant Pathology. Elsevier Ltd. // Volume 117, January 2022, 101751. https://doi.org/10.1016/j.pmpp.2021.101751
Ecdysterone prevents negative effect of acute immobilization stress on energy metabolism of rat liver mitochondria. A.Y. Baev, O.S. Charyshnikova, F.A. Khasanov, K.S. Nebesnaya, A.R. Makhmudov, M.T. Rahmedova, N.H.Yuldasheva, Z.A. Khushbaktova, V.N. Syrov, Y.V. Levitskaya. Molecular and Cellular Biochemistry. Journal of Steroid Biochemistry and Molecular Biology// Volume 219, May 2022, 106066. https://doi.org/10.1016/j.jsbmb.2022.106066
Abdullaev,A., Abdurakhimov,A., Muminov,M., Nuriddinov,S., Dalimova,D., Tsoy,V., Tsay,E., Bozorov,S., Charishnikova,O., Dalimova,D. and Turdikulova,S. Severe acute respiratory syndrome coronavirus 2 isolate SARS-CoV-2/human/UZB/UZB-6/2020 nucleocapsid phosphoprotein (N) gene, partial cds. https://www.ncbi.nlm.nih.gov/nuccore/MT447189.1
Abdullaev,A., Abdurakhimov,A., Muminov,M., Nuriddinov,S., Dalimova,D., Tsoy,V., Tsay,E., Bozorov,S., Charishnikova,O., Dalimova,D. and Turdikulova,S.Severe acute respiratory syndrome coronavirus 2 isolate SARS-CoV-2/human/UZB/UZB-10692-N/2020 nucleocapsid phosphoprotein (N) gene, partial cds. https://www.ncbi.nlm.nih.gov/nuccore/MT447188.1
Baev A., Angelova P., Abramov A. Inorganic polyphosphate is produced and hydrolyzed in F0F1-ATP synthase of mammalian mitochondria//Biochem J (2020) 477 1515–1524.
Ludtmann M. H. R., Angelova P. R., Horrocks M. H., Choi M. L., Rodrigues M., Baev A. Y., Berezhnov A. V., Yao Z., Little D., Banushi B., Al-Menhali A. S., Ranasinghe R. T., Whiten D. R., Yapom R., Dolt K. S., Devine M. J., Gissen P., Kunath T., Jaganjac M., Pavlov E. V., Klenerman D., Abramov A. Y., Gandhi S. alpha-synuclein oligomers interact with ATP synthase and open the permeability transition pore in Parkinson’s disease // Nature Communications.‒ 2018. ‒ V. 9, № 1. ‒ pp. 2293.Scopus CiteScore – 12.11
Baev A. Y., Elustondo P. A., Negoda A., Pavlov E. V. Osmotic regulation of the mitochondrial permeability transition pore investigated by light scattering, fluorescence and electron microscopy techniques // Anal. Biochem. ‒ 2018. ‒ V. 552. ‒ pp. 38-44. Scopus, CiteScore – 2.32
Ilyich T., Charishnikova O., Sekowski S., Zamaraeva M., Cheshchevik V., Dremza I., Cheshchevik N., Kiryukhina L., Lapshina E., Zavodnik I. Ferutinin Induces Membrane Depolarization, Permeability Transition Pore Formation, and Respiration Uncoupling in Isolated Rat Liver Mitochondria by Stimulation of Ca(2+)-Permeability // J Membr Biol.‒ 2018. ‒ V. 251, № 4. ‒ pp. 563-572. ResearchGate, IF – 2,03
G. Akinshinaa, A. A. Azizov, N. I. Shtonda, A. I. Khalmurzayeva, and N. Sh. Rakhmatullina, Ecological Plasticity of the Photosynthetic Apparatus of Hibiscus syriacus L. under Pressure of High Temperature, Insolation, and Air Pollution. // Moscow University Biological Sciences Bulletin, 2018, Vol. 73, No. 3, pp. 162–171. Scopus, CiteScore – 0.61
Liu B., Abdullaev O., Mirzaakhmedov Sh.Ya.,Yili A., Aisa H.A., Charishnikova O.S.. Isolation and Purification of Active Proteases from Sheep Abomasum and Their Biological Activity. // Chemistry of Natural Compounds. -2018.-V.54.- №3.-p.523-526. scijournal.org, IF 0.45
Baev A. Y., Negoda A., Abramov A. Y. Modulation of mitochondrial ion transport by inorganic polyphosphate – essential role in mitochondrial permeability transition pore // J BioenergBiomembr.‒ 2017. ‒ V. 49, № 1. ‒ pp. 49-55. ScopusCiteScore – 2.26
Plamena R. Angelova, Artyom Y. Baev, Alexey V. Berezhnov and Andrey Y. Abramov. Role of inorganic polyphosphate in mammalian cells: from signal transduction and mitochondrial metabolism to cell death.// Biochemical Society Transactions (2016) Volume 44, part 1, DOI:10.1042/BST20150223. Scopus CiteScore – 2.99
Artyom Y. Baev, Plamena R. Angelova and Andrey Y. Abramov. Role of Inorganic Polyphosphate in the Cells of the Mammalian Brain// Chapter 8 in ‘Inorganic Polyphosphates in Eukaryotic Cells’ book, Editors Tatiana Kulakovskaya, Evgeny Pavlov, Elena N. Dedkova, pages 115-122, Springer, 2016
DubisА., Zamaraeva M., Siergiejczyk L., Charishnikova O., Shlyonsky V. Ferutinin as a Ca(2+)complexone: lipid bilayers, conductometry, FT-IR, NMR studies and DFT-B3LYP calculation.// DaltonTransaction – 2015. V. 44. P.16506 – 16514. ResearchGate, IF – 3.84.
Holmström K. M., Marina N., Baev A. Y., Wood N. W., Gourine A. V., Abramov A. Y. Signalling properties of inorganic polyphosphate in the mammalian brain // Nature Communications‒ 2013. ‒ V. 4, ‒ pp. 1362.ResearchGate, IF – 12.03
Zamaraeva M., Charishnikova O., Saidkhodjaev A., Isidorov V., Granosik M., Rojalski M., Watala C. Calcium mobilization by the plant estrogen ferutinin does not induce blood platelet aggregation.// PharmacologicalReports – 2010. V.62. P.1117-1126. ResearchGate, IF – 1.99
`,
};
export const biophysicsRus = {
  img: [img, img, img, img, img, img],
  title1: " Направления деятельности",
  text1: `Скрининг биологической активности веществ и раскрытие молекулярных механизмов их действия;
Поиск новых биорегуляторов;
Создание моделей заболеваний (in vitro, in vivo, in silico), с целью изучении молекулярных механизмов развития патологий;
Применение методов биоинформатики для раскрытия механизмов молекулярного взаимодействия;
Разработка клеточных биомедицинских технологий;
Исследование механизмов формирования адаптационного ответа растений к факторам
окружающей среды.`,
  title2: " Научно-исследовательские проекты",
  text2: `Фундаментальный проект ФЗ-20200929214 «Роль неорганических полифосфатов в трансдукции сигнала в клетках млекопитающих» 2021 – 2026 гг., рук. PhD, Баев А.Ю. (eng: Fundamental project: ФЗ-20200929214 «The role of inorganic polyphosphates in signal transduction in mammalian cells», 2021 – 2026., PI, Artyom Y. Baev, PhD)
Прикладной проект И-ИРВ-2021-463 «Разработка цифровой платформы для мониторинга процессов создания научных разработок биотехнологической продукции» 2021 – 2023 гг., рук. к.б.н, Левицкая Ю.В.
Прикладной проект ПЗ-2021030217 «Разработка имитационных моделей in vitro и in vivo патологических состояний и заболеваний человека и животных» 2021-2024 гг., рук. PhD, Баев А.Ю. (eng: Applied project: ПЗ-2021030217 «Development of in vitro and in vivo simulation models of pathological conditions and diseases in humans and animals — model of ischemic stroke», 2021-2024, PI, Artyom Y. Baev, PhD;
Прикладной проект AL-36-2105821c “Inson va xayvonlar patologik holatlari va kasalliklarining in silico imitasion modellarini ishlab chiqish” 2022-2025 гг., рук. Dsc, Раззоков Ж.И. (eng: Applied project: № AL-36-2105821c «Development of in silico simulation models of pathological conditions and diseases in humans and animals» 2022-2025, PI, Jamoliddin I. Razzokov, DSc )
Прикладной проект ПЗ-20170930211 «Скрининг антигипоксантных свойств проантоцианидинов, выделенных из местного растительного сырья, как перспективных регуляторов метаболизма», руководитель к.б.н. Раджабова Г.Г. Годы выполнения 2018-2020.
Фундаментальный проект БВ-М-Ф5-001 «Молекулярные механизмы стрессоустойчивости растений в условиях семиаридной зоны» руководитель к.б.н. Левицкая Ю.В.Годы выполнения 2017-2020.
Молодежный проект МУ-Ф3-20171025118 “Регуляция уровня неорганических полифосфатов как способ защиты от ишемии-реперфузии”, руководитель PhD Баев А.Ю. Годы выполнения 2018-2019.
Молодежный проект МУ-ФЗ-2017102543 «Анализ первичных метаболитов лекарственных растений Узбекистана и их роль в адаптационных процессах к неблагоприятным факторам среды», руководитель Рахматуллина Н.Ш. Годы выполнения 2018-2019.`,
  title3: "Приоритетные направления деятельности 2021-2025 годы",
  text3: `Laboratory of prof. Andrey Y. Abramov, Department of Clinical and Movement Neurosciences, UCL Queen Square Institute of Neurology, Queen Square, London WC1N 3BG, UK

Laboratory of prof. Evgeny Pavlov, College of Dentistry, New York University, New York, NY, USA.
Лаборатория клеточной физиологии и патологии, Орловский Государственный Университет, Орёл, Россия
Лаборатория внутриклеточной сигнализации, Институт Биофизики Клетки РАН, г. Пущино, Россия
ГНУ «Институт биофизики и клеточной инженерии НАН Беларуси»
ФГАОУ ВО «Казанский(Приволжский) Федеральный университет» Российской Федерации»`,
  title4: "Рейтинговые научные публикации",
  text4: `Influence of RNA interference of phytochrome A1 gene on activity of antioxidant system in cotton. V. S. Kamburova, K. A. Ubaydullaeva, Sh. E. Shermatov, Z.T. Buriev, O. S. Charishnikova, K.S. Nebesnaya, O. A. Sukocheva. Physiological and Molecular Plant Pathology. Elsevier Ltd. // Volume 117, January 2022, 101751. https://doi.org/10.1016/j.pmpp.2021.101751
Ecdysterone prevents negative effect of acute immobilization stress on energy metabolism of rat liver mitochondria. A.Y. Baev, O.S. Charyshnikova, F.A. Khasanov, K.S. Nebesnaya, A.R. Makhmudov, M.T. Rahmedova, N.H.Yuldasheva, Z.A. Khushbaktova, V.N. Syrov, Y.V. Levitskaya. Molecular and Cellular Biochemistry. Journal of Steroid Biochemistry and Molecular Biology// Volume 219, May 2022, 106066. https://doi.org/10.1016/j.jsbmb.2022.106066
Abdullaev,A., Abdurakhimov,A., Muminov,M., Nuriddinov,S., Dalimova,D., Tsoy,V., Tsay,E., Bozorov,S., Charishnikova,O., Dalimova,D. and Turdikulova,S. Severe acute respiratory syndrome coronavirus 2 isolate SARS-CoV-2/human/UZB/UZB-6/2020 nucleocapsid phosphoprotein (N) gene, partial cds. https://www.ncbi.nlm.nih.gov/nuccore/MT447189.1
Abdullaev,A., Abdurakhimov,A., Muminov,M., Nuriddinov,S., Dalimova,D., Tsoy,V., Tsay,E., Bozorov,S., Charishnikova,O., Dalimova,D. and Turdikulova,S.Severe acute respiratory syndrome coronavirus 2 isolate SARS-CoV-2/human/UZB/UZB-10692-N/2020 nucleocapsid phosphoprotein (N) gene, partial cds. https://www.ncbi.nlm.nih.gov/nuccore/MT447188.1
Baev A., Angelova P., Abramov A. Inorganic polyphosphate is produced and hydrolyzed in F0F1-ATP synthase of mammalian mitochondria//Biochem J (2020) 477 1515–1524.
Ludtmann M. H. R., Angelova P. R., Horrocks M. H., Choi M. L., Rodrigues M., Baev A. Y., Berezhnov A. V., Yao Z., Little D., Banushi B., Al-Menhali A. S., Ranasinghe R. T., Whiten D. R., Yapom R., Dolt K. S., Devine M. J., Gissen P., Kunath T., Jaganjac M., Pavlov E. V., Klenerman D., Abramov A. Y., Gandhi S. alpha-synuclein oligomers interact with ATP synthase and open the permeability transition pore in Parkinson’s disease // Nature Communications.‒ ‒ V. 9, № 1. ‒ pp. 2293.Scopus CiteScore — 12.11
Baev A. Y., Elustondo P. A., Negoda A., Pavlov E. V. Osmotic regulation of the mitochondrial permeability transition pore investigated by light scattering, fluorescence and electron microscopy techniques // Anal. ‒ 2018. ‒ V. 552. ‒ pp. 38-44. Scopus, CiteScore – 2.32
Ilyich T., Charishnikova O., Sekowski S., Zamaraeva M., Cheshchevik V., Dremza I., Cheshchevik N., Kiryukhina L., Lapshina E., Zavodnik I. Ferutinin Induces Membrane Depolarization, Permeability Transition Pore Formation, and Respiration Uncoupling in Isolated Rat Liver Mitochondria by Stimulation of Ca(2+)-Permeability // J Membr Biol.‒ 2018. ‒ V. 251, № 4. ‒ pp. 563-572. ResearchGate, IF – 2,03
G. Akinshinaa, A. A. Azizov, N. I. Shtonda, A. I. Khalmurzayeva, and N. Sh. Rakhmatullina, Ecological Plasticity of the Photosynthetic Apparatus of Hibiscus syriacus L. under Pressure of High Temperature, Insolation, and Air Pollution. // Moscow University Biological Sciences Bulletin, 2018, Vol. 73, No. 3, pp. 162–171. Scopus, CiteScore – 0.61
Liu B., Abdullaev O., Mirzaakhmedov Sh.Ya.,Yili A., Aisa H.А., Charishnikova O.S.. Isolation and Purification of Active Proteases from Sheep Abomasum and Their Biological Activity. // Chemistry of Natural Compounds. -2018.-V.54.- №3.-p.523-526. scijournal.org, IF 0.45
Baev A. Y., Negoda A., Abramov A. Y. Modulation of mitochondrial ion transport by inorganic polyphosphate — essential role in mitochondrial permeability transition pore // J BioenergBiomembr.‒ ‒ V. 49, № 1. ‒ pp. 49-55. ScopusCiteScore – 2.26
Plamena R. Angelova, Artyom Y. Baev, Alexey V. Berezhnov and Andrey Y. Abramov. Role of inorganic polyphosphate in mammalian cells: from signal transduction and mitochondrial metabolism to cell death.// Biochemical Society Transactions (2016) Volume 44, part 1, DOI:10.1042/BST20150223. Scopus CiteScore – 2.99
Artyom Y. Baev, Plamena R. Angelova and Andrey Y. Abramov. Role of Inorganic Polyphosphate in the Cells of the Mammalian Brain// Chapter 8 in ‘Inorganic Polyphosphates in Eukaryotic Cells’ book, Editors Tatiana Kulakovskaya, Evgeny Pavlov, Elena N. Dedkova, pages 115-122, Springer, 2016
DubisА., Zamaraeva M., Siergiejczyk L., Charishnikova O., Shlyonsky V. Ferutinin as a Ca(2+)complexone: lipid bilayers, conductometry, FT-IR, NMR studies and DFT-B3LYP calculation.// DaltonTransaction – 2015. V. 44. P.16506 – 16514. ResearchGate, IF – 3.84.
Holmström K. M., Marina N., Baev A. Y., Wood N. W., Gourine A. V., Abramov A. Y. Signalling properties of inorganic polyphosphate in the mammalian brain // Nature Communications‒ 2013. ‒ V. 4, ‒ pp. 1362.ResearchGate, IF – 12.03
Zamaraeva M., Charishnikova O., Saidkhodjaev A., Isidorov V., Granosik M., Rojalski M., Watala C. Calcium mobilization by the plant estrogen ferutinin does not induce blood platelet aggregation.// PharmacologicalReports – 2010. V.62. P.1117-1126. ResearchGate, IF – 1.99`,
};
export const biophysicsEng = {
  img: [img, img, img, img, img, img],
  title1: "Areas Of Activity",
  text1: `Screening of biological activity of substances and disclosure of molecular mechanisms of their action;
Search for new bioregulators;
Creation of disease models (in vitro, in vivo, in silico), in order to study the molecular mechanisms of the development of pathologies;
Application of bioinformatics methods to reveal the mechanisms of molecular interaction;
Development of cellular biomedical technologies;
Study of the mechanisms of formation of the adaptive response of plants to environmental factors.`,
  title2: "Scientific research projects",
  text2: `Fundamental project: ФЗ-20200929214 «The role of inorganic polyphosphates in signal transduction in mammalian cells», 2021 – 2026., PI, Artyom Y. Baev, PhD
Applied project И–ИРВ-2021-463 “Development of a digital platform for monitoring the processes of creating scientific developments of biotechnological products” 2021 – 2023, PI, PhD, Levitskaya Yu.V, PhD
Applied project: ПЗ-2021030217 «Development of in vitro and in vivo simulation models of pathological conditions and diseases in humans and animals – model of ischemic stroke», 2021-2024, PI, Artyom Y. Baev, PhD
Applied project: № AL-36-2105821c «Development of in silico simulation models of pathological conditions and diseases in humans and animals» 2022-2025, PI, Jamoliddin I. Razzokov, DSc
Applied project ПЗ-20170930211 “Screening of the antihypoxant properties of proanthocyanidins isolated from local plant materials as promising metabolism regulators”, 2018-2020, PI, Radjabova G.G. PhD.
Fundamental project BV-M-F5-001 “Molecular mechanisms of stress resistance of plants in the semiarid zone”. 2017-2020, PI, Levitskaya Y.V, PhD.
Youth project MU-F3-20171025118 “Regulation of the level of inorganic polyphosphates as a way of protection against ischemia-reperfusion”, 2018-2019, PI, A.Y. Baev, PhD.
Youth project MU-FZ-2017102543 “Analysis of primary metabolites of medical plants of Uzbekistan and their role in adaptation processes to adverse environmental factors”, 2018-2019, PI, Rakhmatullina N.Sh.`,
  title3: "International Cooperation",
  text3: `Laboratory of prof. Andrey Y. Abramov, Department of Clinical and Movement Neurosciences, UCL Queen Square Institute of Neurology, Queen Square, London WC1N 3BG, UK
Laboratory of prof. Evgeny Pavlov, College of Dentistry, New York University, New York, NY, USA.
Laboratory of Cellular Physiology and Pathology, Orel State University, Orel, Russia
Laboratory of Intracellular Signaling, Institute of Cell Biophysics of the Russian Academy of Sciences, Pushchino, Russia
“Institute of Biophysics and Cell Engineering of the National Academy of Sciences of Belarus”
Kazan Federal University of the Russian Federation`,
  title4: "Rated scientific publications",
  text4: `Baev, A.Y., Abramov, A.Y. (2022). Inorganic Polyphosphate and F0F1-ATP Synthase of Mammalian Mitochondria. In: Müller, W.E.G., Schröder, H.C., Suess, P., Wang, X. (eds) Inorganic Polyphosphates. Progress in Molecular and Subcellular Biology, vol 61. Springer, Cham. https://doi.org/10.1007/978-3-031-01237-2_1
Baev A.Y., Vinokurov A. Y., Novikova I. N., Dremin V. V., Potapova E. V., Abramov A. Y., Interaction of Mitochondrial Calcium and ROS in Neurodegeneration, Cells. ‒ 2022. ‒ V. 11, № 4; https://doi.org/10.3390/cells11040706; Impact Factor (Web of Sciense) – 6
Baev A.Y., Charishnikova O. S., Khasanov F. A., Nebesnaya K. S., Makhmudov A. R., Rakhmedova M. T., Khushbaktova Z. A., Syrov V. N., Levitskaya Y. V., Ecdysterone prevents negative effect of acute immobilization stress on energy metabolism of rat liver mitochondria, J Steroid Biochem Mol Biol. ‒ 2022. ‒ V. 219. ‒ p. 106066; https://doi.org/10.1016/j.jsbmb.2022.106066; Scopus cite score 2021 – 8
Garib V, Katsamaki S, Turdikulova S, Levitskaya Y, Zahidova N, Bus G, Karamova K, Rakhmedova M, Magbulova N, Bruhov A, Garib FY and Abdurakhmonov IY., Milk of Cow and Goat, Immunized by Recombinant Protein Vaccine ZF-UZ-VAC2001(Zifivax), Contains Neutralizing Antibodies Against SARS-CoV-2 and Remains Active After Standard Milk Pasteurization. (2022) Front. Nutr. 9:901871. doi: 10.3389/fnut.2022.901871 Impact Factor (Web of Science) –6.576
Kamburova V. S., Ubaydullaeva K. A., Shermatov Sh. E., Buriev Z.T., Charishnikova O. S., Nebesnaya K.S., Sukocheva O. A. Influence of RNA interference of phytochrome A1 gene on activity of antioxidant system in cotton. // Physiological and Molecular Plant Pathology. Elsevier Ltd. 117 (2022) 101751. р.7. Available online 13 November 2021. https://doi.org/10.1016/j.pmpp.2021.101751. Scopus cite score 2021 – 4
Ravshan Z. Sabirov, Md. Rafiqul Islam, Toshiaki Okada, Petr G. Merzlyak, Ranokhon S. Kurbannazarova, Nargiza A. Tsiferova and Yasunobu Okada. The ATP-Releasing Maxi-Cl Channel: Its Identity, Molecular Partners and Physiological/Pathophysiological Implications, Y. Life, 2021, 11(6), 509 https://doi.org/10.3390/life11060509 Impact Factor (Web of Sciense) – 817
Chorieva, NM, Fayziev, DD, Tsiferova, NA, et al. Lytic and sublytic effects of gossypol on red blood cells and thymocytes. Clin Exp Pharmacol Physiol. 2021; 48: 227– 237. https://doi.org/10.1111/1440-1681.13429 Scopus cite score 2021 – 6
Abdullaev,A., Abdurakhimov,A., Muminov,M., Nuriddinov,S., Dalimova,D., Tsoy,V., Tsay,E., Bozorov,S., Charishnikova,O., Dalimova,D. and Turdikulova,S. Severe acute respiratory syndrome coronavirus 2 isolate SARS-CoV-2/human/UZB/UZB-6/2020 nucleocapsid phosphoprotein (N) gene, partial cds. https://www.ncbi.nlm.nih.gov/nuccore/MT447189.1
Baek, A. Sariev*, S. Lee, S. -Y. Dong, S. Royer and H. Kim, “Deep Cerebellar Low-Intensity Focused Ultrasound Stimulation Restores Interhemispheric Balance after Ischemic Stroke in Mice,” in IEEE Transactions on Neural Systems and Rehabilitation Engineering, vol. 28, no. 9, pp. 2073-2079, Sept. 2020, doi:10.1109/TNSRE.2020.3002207. Impact Factor (Web of Science) –3.8
Artyom Y. Baev, Plamena R. Angelova, Andrey Y. Abramov; osmoticganic polycphosphate is produced and hydrolyzed in F0F1-ATP synthase of mammalian mitochondria // Biochem J 30 April 2020; 477 (8): 1515–1524. doi: https://doi.org/10.1042/BCJ20200042. Scopus cite score (2019 год) – 7.6
Tsiferova, N.A., Khamidova, O. J., Amonov, A. U., Rakhimova, M. B., Rustamova, S. I., Kurbannazaova, R. Sh., Merzlyak, P. G., Abdulladjanova, N. G. and Sabirov, R. Z. “Tannins, novel inhibitors of the volume regulation and the volume-sensitive anion channel” European Pharmaceutical Journal, vol.66, no.2, 2019, pp.37-44. https://doi.org/10.2478/afpuc-2019-0016 Scopus cite score 2021 – 6
Rustamova S. I., Tsiferova N. A., Khamidova O. J., Kurbannazarova R. S., Merzlyak P. G., Khushbaktova Z. A., Syrov V. N., Botirov E. K., Eshbakova K. A., Sabirov R. Z. Effect of plant flavonoids on the volume regulation of rat thymocytes under hypoosmotic stress // Pharmacol Rep. ‒ 2019. ‒ V. 71, № 6. ‒ p. 1079-1087. https://doi.org/10.1016/j.pharep.2019.05.023 Impact Factor (Web of Science) –3.027
Jung, S. Kim, A. Sariev, F. Sharif, D. Kim, S. Royer, “Dentate granule and mossy cells exhibit distinct spatiotemporal responses to local change in a one-dimensional landscape of visual-tactile cues” Scientific Reports (2019) 9:9545; doi.org/10.1038/s41598-019-45983-6. Impact Factor (Web of Science) –4.38
Akinshina N.G., Azizov A.A., Shtonda N.I., Khalmurzayeva A.I., Rakhmatullina N.Sh. Ecological Plasticity of the Photosynthetic Apparatus of Hibiscus syriacus under Pressure of High Temperature, Insolation, and Air Pollution, , Moscow University Biological Sciences Bulletin, 2018, Vol. 73, No. 3, pp. 162–171. Scopus cite score 2019 – 1
Ludtmann M. H. R., Angelova P. R., Horrocks M. H., Choi M. L., Rodrigues M., Baev A. Y., Berezhnov A. V., Yao Z., Little D., Banushi B., Al-Menhali A. S., Ranasinghe R. T., Whiten D. R., Yapom R., Dolt K. S., Devine M. J., Gissen P., Kunath T., Jaganjac M., Pavlov E. V., Klenerman D., Abramov A. Y., Gandhi S. alpha-synuclein oligomers interact with ATP synthase and open the permeability transition pore in Parkinson’s disease // Nature Communications. ‒ 2018. ‒ V. 9, № 1. ‒ pp. 2293. DOI: https://doi.org/10.1038/s41467-018-04422-2. Scopus cite score – 1
Baev A. Y., Elustondo P. A., Negoda A., Pavlov E. V. Osmotic regulation of the mitochondrial permeability transition pore investigated by light scattering, fluorescence and electron microscopy techniques // Anal. Biochem. ‒ 2018. ‒ V. 552. ‒ pp. 38-44. DOI: https://doi.org/10.1016/j.ab.2017.07.006. Scopus cite score – 2
Liu B., Abdullaev O., Mirzaakhmedov Sh.Ya.,Yili A., Aisa H.А., Charishnikova O.S.. Isolation and Purification of Active Proteases from Sheep Abomasum and Their Biological Activity Chemistry of Natural Compounds.-2018.-V.54.-№3.-p.523-526. Scopus cite score (2018) – 0.8
lyich, T., Charishnikova, O., Sekowski, S. et al. Ferutinin Induces Membrane Depolarization, Permeability Transition Pore Formation, and Respiration Uncoupling in Isolated Rat Liver Mitochondria by Stimulation of Ca2+-Permeability. J Membrane Biol 251, 563–572 (2018). https://doi.org/10.1007/s00232-018-0032-0  Scopus cite score (2018) – 8
Baek, A. Sariev, M. Kim, H. Lee, J. Kim and H. Kim, “A neuroprotective brain stimulation for vulnerable cerebellar Purkinje cell after ischemic stroke: a study with low-intensity focused ultrasound,”2018 40th Annual International Conference of the IEEE Engineering in Medicine and Biology Society (EMBC), Honolulu, HI, 2018, pp. 4744-4747.doi: 10.1109/EMBC.2018.8513138. Impact Factor (Web of Science) –1.12
Baev A. Y., Negoda A., Abramov A. Y. Modulation of mitochondrial ion transport by inorganic polyphosphate – essential role in mitochondrial permeability transition pore // J BioenergBiomembr.‒ 2017. ‒ V. 49, № 1. ‒ pp. 49-55. ScopusCiteScore – 2.26
Artyom Y. Baev, Plamena R. Angelova and Andrey Y. Abramov. Role of Inorganic Polyphosphate in the Cells of the Mammalian Brain// Chapter 8 in ‘Inorganic Polyphosphates in Eukaryotic Cells’ book, Editors Tatiana Kulakovskaya, Evgeny Pavlov, Elena N. Dedkova, pages 115-122, Springer, 2016
Dubis А., Zamaraeva M., Siergiejczyk L., Charishnikova O., Shlyonsky V. Ferutinin as a Ca(2+)complexone: lipid bilayers, conductometry, FT-IR, NMR studies and DFT-B3LYP calculation.// DaltonTransaction – 2015. V. 44. P.16506 – 16514. ResearchGate, IF – 3.84.
Holmström K. M., Marina N., Baev A. Y., Wood N. W., Gourine A. V., Abramov A. Y. Signaling properties of inorganic polyphosphate in the mammalian brain // Nature Communications‒ 2013. ‒ V. 4, ‒ pp. 1362.ResearchGate, IF – 12.03
Zamaraeva M., Charishnikova O., Saidkhodjaev A., Isidorov V., Granosik M., Rojalski M., Watala C. Calcium mobilization by the plant estrogen ferutinin does not induce blood platelet aggregation.// PharmacologicalReports – 2010. V.62. P.1117-1126. ResearchGate, IF – 1.99
`,
};

import React, { useContext } from "react";
import { useEffect } from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { LanguageContext } from "../../context/languageContext";
import { usefullinksUzb } from "../../data/usefullinks";
import { Wrapper } from "./style";

const UsefulLinks = () => {
  const [num, setNum] = React.useState(3);
  const [width, setWidth] = React.useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
  useEffect(() => {
    if (width < 750) setNum(1);
    else if (width <= 1000) setNum(2);
    else if (width > 1000) setNum(3);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);
  const [lang] = useContext(LanguageContext);

  return (
    <Wrapper>
      <>
        <h1>
          {lang === "uzb"
            ? "Foydali havolalar"
            : lang === "rus"
            ? "Полезные ссылки"
            : "Useful links"}{" "}
        </h1>
        <Swiper
          modules={[Navigation, Autoplay]}
          navigation={true}
          slidesPerView={num}
          className="mySwiper"
          spaceBetween={20}
          autoplay={true}
          loop={true}
        >
          {usefullinksUzb.map((value) => {
            return (
              <SwiperSlide key={value.id}>
                <a
                  className="box"
                  href={value.path}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={value.img} alt="x" />
                  <p>{value.title}</p>
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>
    </Wrapper>
  );
};

export default UsefulLinks;

import React from "react";
import { useContext } from "react";
import Laboratory from "../components/Laboratory/Laboratory";
import { LanguageContext } from "../context/languageContext";
import { biotechnologyEng, biotechnologyRus, biotechnologyUzb } from "../data/laboratories/biotechnology";

const LaboratoryofBiotechnology = () => {
  const [lang] = useContext(LanguageContext);

  if (lang === "uzb") {
    return (
      <Laboratory
        data={biotechnologyUzb}
        title="Biotexnologiya laboratoriyasi"
      />
    );
  } else if (lang === "rus") {
    return (
      <Laboratory data={biotechnologyRus} title="Лаборатория биотехнологии" />
    );
  } else if (lang === "eng") {
    return (
      <Laboratory data={biotechnologyEng} title="Laboratory of biotechnology" />
    );
  }
};

export default LaboratoryofBiotechnology;

import React, { useContext } from "react";
import { Section } from "./style";
import { Button } from "antd";
import { LanguageContext } from "../../context/languageContext";
import img1 from "../../assets/images/scientificActivies/OLIY TA'LIM,FAN VA INNOVATSIYALAR VAZIRLIGI HUZURIDAGI ILG'OR TEXNOLOGIYALAR MARKAZI.png";
import img2 from "../../assets/images/scientificActivies/Kirilcha OLIY TA'LIM,FAN VA INNOVATSIYALAR VAZIRLIGI HUZURIDAGI ILG'OR TEXNOLOGIYALAR MARKAZI.png";
import img3 from "../../assets/images/scientificActivies/ENGLISH OLIY TA'LIM,FAN VA INNOVATSIYALAR VAZIRLIGI HUZURIDAGI ILG'OR TEXNOLOGIYALAR MARKAZI.png";

const ScientificActiviesComp = () => {
  const [lang] = useContext(LanguageContext);
  if (lang === "uzb") {
    return (
      <Section>
        <h1>Ilmiy faoliyat</h1>
        <div className="card">
          <div className="left">
            <img src={img1} alt="" />
          </div>
          <div className="right">
            <p className="text">
              Ilg'or Texnologiyalar Markazining ilmiy faoliyati haqidagi
              elektron kitobni quyidagi havolaga o'tish orqali o'qishingiz
              mumkin.
            </p>
            <a
              href={"https://online.pubhtml5.com/zdsxx/ulcw/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary">Elektron kitobni o'qish</Button>
            </a>
          </div>
        </div>
      </Section>
    );
  } else if (lang === "rus") {
    return (
      <Section>
        <h1>Научная деятельность</h1>
        <div className="card">
          <div className="left">
            <img src={img2} alt="" />
          </div>
          <div className="right">
            <p className="text">
              Прочитать электронную книгу о научной деятельности Центра
              Передовых Технологий можно, перейдя по ссылке ниже.
            </p>
            <a
              href={"https://online.pubhtml5.com/zdsxx/cwcb/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary">Прочесть электронную книгу</Button>
            </a>
          </div>
        </div>
      </Section>
    );
  } else {
    return (
      <Section>
        <h1>Scientific activities</h1>
        <div className="card">
          <div className="left">
            <img src={img3} alt="" />
          </div>
          <div className="right">
            <p className="text">
              Ebook about the Center's for Advanced Technologies scientific
              activities you can read by following the link below.
            </p>
            <a
              href={"https://online.pubhtml5.com/pnbmb/dgkj/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary">Read the ebook</Button>
            </a>
          </div>
        </div>
      </Section>
    );
  }
};

export default ScientificActiviesComp;
